<template>
  <div class="">
    <div class="">
      <div class="transx_footer_container transx_block_have_bg_image">
        <div class="transx_prefooter_container">
          <div class="container">
            <div class="transx_prefooter_wrapper transx_prefooter_type_1">
              <div
                id="transx_socials_widget-2"
                class="widget footer_widget widget_transx_socials_widget"
              >
                <div class="footer_widget_wrapper">
                  <div class="transx_socials_widget_wrapper content_align_left">
                    <div
                      class="transx_socials_widget_logo"
                      style="display: flex; align-items: flex-start"
                    >
                      <a href="#">
                        <img
                          src="../../../libhome/img/footer1.svg"
                          alt="Footer Logo"
                          style="
                            height: 60px;
                            width: auto;
                            -webkit-transition: all 0.3s ease-out;
                            transition: all 0.3s ease-out;
                            padding-top: -50px;
                          "
                        />
                      </a>
                    </div>
                    <div class="transx-footer-social">
                      <a
                        class="btn"
                        href="mailto:vimclines@vimc-lines.com"
                        ><img
                          src="../../../libhome/img/gmail.svg"
                          alt=""
                          width="20px"
                      /></a>
                      <a
                        class="btn"
                        href="https://wa.me/84335299516"
                        target="_blank"
                        ><img
                          src="../../../libhome/img/whatsapp.svg"
                          alt=""
                          width="20px"
                      /></a>
                      <!-- <a
                        class="btn"
                        href="weixin://"
                        ><img
                          src="../../../libhome/img/wechat.svg"
                          alt=""
                          width="26px"
                      /></a> -->
                      <a
                        class="btn"
                        href="skype:suoinho_mk?chat"
                        target="_blank"
                        ><img
                          src="../../../libhome/img/skype.svg"
                          alt=""
                          width="20px"
                      /></a>
                      <a
                        class="btn"
                        href="https://zalo.me/0974040006"
                        target="_blank"
                        ><img
                          src="../../../libhome/img/zalo.svg"
                          alt=""
                          width="20px"
                      /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="custom_html-3"
                class="widget_text widget footer_widget widget_custom_html"
              >
                <div class="widget_text footer_widget_wrapper">
                  <h6 class="transx_footer_widget_title">
                    {{
                      this.$i18n.locale === 'en'
                        ? 'Headquarters'
                        : 'Trụ sở chính'
                    }}
                  </h6>
                  <div class="textwidget custom-html-widget">
                    <div class="transx_offices_list">
                      <!-- <p> 7th Floor, </p> -->
                      <p style="max-width: 200px">
                        {{ $t('location') }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="custom_html-4"
                class="widget_text widget footer_widget widget_custom_html"
              >
                <div class="widget_text footer_widget_wrapper">
                  <h6 class="transx_footer_widget_title">
                    {{
                      this.$i18n.locale === 'en'
                        ? 'Work Inquiries'
                        : 'Hợp tác công việc'
                    }}
                  </h6>
                  <div class="textwidget custom-html-widget">
                    <div class="transx_offices_list">
                      <p>
                        <a
                          class="transx_services_link"
                          href="#"
                          v-html="
                            this.$i18n.locale === 'en'
                              ? 'Interested in working with us?'
                              : 'Bạn quan tâm đến việc hợp tác <br/> với chúng tôi không?'
                          "
                        >
                        </a>
                      </p>
                      <p>
                        <a href="mailto:vimclines@vimc-lines.com"
                          >vimclines@vimc-lines.com</a
                        >
                      </p>
                      <h6 class="transx_footer_widget_title">
                        {{ $t('phone') }}
                      </h6>
                      <p><a href="">02435770894</a></p>
                      <p><a href="">(+84) 2435770894</a></p>

                      <!-- <p> <a href="">+842435770894</a> </p> -->
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="custom_html-5"
                class="widget_text widget footer_widget widget_custom_html"
              >
                <div class="widget_text footer_widget_wrapper">
                  <h6 class="transx_footer_widget_title">
                    {{
                      this.$i18n.locale === 'en'
                        ? 'Stay In Touch'
                        : 'Giữ liên lạc'
                    }}
                  </h6>
                  <div class="textwidget custom-html-widget">
                    <form
                      id="mc4wp-form-1"
                      class="mc4wp-form mc4wp-form-28"
                      method="post"
                      data-id="28"
                      data-name="Subscribe"
                    >
                      <div class="mc4wp-form-fields">
                        <input
                          type="email"
                          name="EMAIL"
                          placeholder="Email address"
                          required=""
                        /><span class="transx_footer_submit_container"
                          ><input
                            type="submit"
                            value="" /><span class="icon"
                            ><img
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABEUlEQVR4nO2UPUoEQRSEBzFbEWP/8Aoq3sFFM3NDr+Ap/AEDD7GCIt5C9ArKrJobGEx/BSVoNw6zMzvrrorBFkzSPV1fvfeazrKp/o2AHeBJkif5gD7QrQP0JzXXFyQfAKRN2/PjdsF2J/nUVfAa6c/A3nfNgV3gMXq81dEXJfVKpV7bXh0h9ZKki9K5G9trbcN+SEkkHdqerTGekXRQqvxF0v4w43PbC/FwBzgCiKnuQgib6d+iKDYk3UZjAWdpdrbngOMBQEpRXiuKYr1idAqcfHI/wPchhK1K0Lx2yE3Td6UVba1rvEWNG1G2lyVdSrqyvZI1aGzAqJoCWjXsqfjdxw7o/gQEyIHt9lqn+iu9AzTRtcTbJEAhAAAAAElFTkSuQmCC" /></span
                        ></span>
                        <p
                          class="transx_mailchimp_info_container"
                          v-html="
                            this.$i18n.locale === 'en'
                              ? 'Stay tuned for our latest news'
                              : 'Hãy theo dõi tin tức mới nhất<br/> của chúng tôi'
                          "
                        ></p>
                      </div>
                      <label style="display: none !important"
                        >Leave this field empty if you're human:
                        <input
                          type="text"
                          name="_mc4wp_honeypot"
                          value=""
                          tabindex="-1"
                          autocomplete="off" /></label
                      ><input
                        type="hidden"
                        name="_mc4wp_timestamp"
                        value="1719187575"
                      /><input
                        type="hidden"
                        name="_mc4wp_form_id"
                        value="28"
                      /><input
                        type="hidden"
                        name="_mc4wp_form_element_id"
                        value="mc4wp-form-1"
                      />
                      <div class="mc4wp-response"></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer class="transx_footer footer_type_3">
          <div class="container">
            <div class="transx_footer_3_wrapper">
              <div class="row flex-sm-row">
                <div
                  class="col-sm-12 col-lg-9 col-xl-8 top-20 top-sm-0 text-center text-sm-left"
                >
                  <div class="transx_footer_menu_2_cont">
                    <ul
                      id="menu-footer-menu-2"
                      class="transx_footer_menu_2"
                    >
                      <li
                        id="menu-item-103"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-103"
                      >
                        <a href="/">{{ $t('home') }}</a>
                      </li>
                      <!-- <li
                        id="menu-item-104"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-104"
                      >
                        <a href="/about">{{ $t('aboutUs') }}</a>
                      </li> -->
                      <li
                        id="menu-item-106"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-106"
                      >
                        <a href="/blogs">{{ $t('blogs') }}</a>
                      </li>
                      <li
                        id="menu-item-105"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-105"
                      >
                        <a href="/vesselSchedule">{{ $t('services') }}</a>
                      </li>
                      <li
                        id="menu-item-108"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-108"
                      >
                        <a href="/contact">{{ $t('contact') }}</a>
                      </li>
                      <li
                        id="menu-item-107"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-107"
                      >
                        <a href="/recruitment">{{ $t('recruitment') }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="col-sm-12 col-lg-3 col-xl-4 top-20 top-sm-0 text-center text-sm-right"
                >
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
(function () {
  window.mc4wp = window.mc4wp || {
    listeners: [],
    forms: {
      on: function (evt, cb) {
        window.mc4wp.listeners.push({
          event: evt,
          callback: cb,
        });
      },
    },
  };
})();
</script>
<style>
.transx_block_have_bg_image {
  /* background-image: url(http://demo.artureanec.com/themes/transx-new/wp-content/uploads/2021/06/background__layout.png); */
  background-image: url('../../../libhome/img/Footer.svg');
}
.transx_footer_container {
  background-color: none;
}
.transx_block_have_bg_image {
  background-position: center right !important;
  background-size: cover !important;
}
footer.transx_footer,
footer.transx_footer a,
.footer_widget.widget_custom_html .transx_footer_menu,
.footer_widget.widget_custom_html a {
  color: #fff;
}
footer.transx_footer {
  font-size: 14px;
}
.transx_prefooter_wrapper {
  margin-left: -15px;
  margin-right: -15px;
  font-size: 0;
  line-height: 0;
  padding-top: 10px;
}
.footer_widget:first-of-type,
.footer_widget:nth-of-type(2),
.footer_widget:nth-of-type(3),
.footer_widget:nth-of-type(4) {
  margin-top: 0;
}
.footer_widget,
.footer_widget a {
  color: #ededed;
}
.footer_widget {
  font-family: Inter, sans-serif;
}
.footer_widget {
  width: 25%;
  display: inline-block;
  padding: 0 15px;
  vertical-align: top;
  margin-top: 30px;
  font-size: 14px;
  line-height: 1.6;
  font-weight: 400;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active,
.transx_testimonials_wrapper .slick-dots li.slick-active,
.transx_slider_arrows .slick-dots li.slick-active,
body
  form[id*='give-form']
  #give-donation-level-radio-list
  li
  input[type='radio']:checked
  + label:before,
body
  form[id*='give-form']
  #give-gateway-radio-list
  li
  input[type='radio']:checked
  + label:before,
body
  form[id*='give-form']
  .give-donation-levels-wrap
  li
  input[type='radio']:checked
  + label:before,
body
  form[id*='give-form']
  .give-donation-levels-wrap
  + fieldset
  ul
  li
  input[type='radio']:checked
  + label:before,
body .transx_causes_slider_widget .transx_button,
.footer_widget .mc4wp-form input[type='submit'],
.footer_widget .mc4wp-form input[type='submit']:hover,
body
  .transx_video_widget
  .view_type_2
  .transx_video_trigger_button
  .transx_button_icon,
.transx_sidebar .widget_calendar table td#today:after,
.wp-block-calendar table td#today:after,
.footer_widget.widget_calendar table td#today:after,
.transx_sidebar .widget_calendar table td a:after,
.wp-block-calendar table td a:after,
.footer_widget.widget_calendar table td a:after,
body .transx_content_wrapper .elementor-widget-text-editor ul li:before,
body .transx_content_wrapper .transx_location_item ul li:before,
body .transx_refrigerate_option_container span:after,
body
  .transx_price_item
  .transx_custom_fields_container
  .transx_custom_field:before,
body .transx_content_wrapper .elementor-tab-content ul li:before,
.transx_checkbox_label .transx_checkbox_mask:after,
.wpcf7-checkbox label span:after,
body .transx_content_paging_wrapper .page-link span,
body .transx_content_paging_wrapper .page-link a,
body .transx_pagination nav.pagination span,
body .transx_pagination nav.pagination a,
.woocommerce nav.woocommerce-pagination ul li a,
.woocommerce nav.woocommerce-pagination ul li span,
.woocommerce nav.woocommerce-pagination ul li span.current,
body.woocommerce
  div.product
  .woocommerce-tabs
  .panel.woocommerce-Tabs-panel--description
  ul
  li:before,
body .transx_back_to_top_button,
.transx_links_list li a:hover:before,
body.woocommerce ul.products li.product .button,
body.woocommerce ul.products li.product .added_to_cart {
  border-color: #e8734a;
}

.footer_widget .mc4wp-form input[type='submit'] {
  width: 70px;
  min-width: 70px;
}
.transx_footer_3_wrapper {
  border-top: solid 1px #4c4f54;
  padding: 12px 0 13px;
}
.transx_footer_menu_2 {
  font-size: 16px;
}
.transx_footer_menu {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-wrap: wrap;
}
.transx_footer_menu ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-wrap: wrap;
}
.transx_footer_menu_2 {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-wrap: wrap;
}
.transx_footer_menu_2 li:not(:last-of-type) {
  margin-right: 53px;
}

.footer_widget .mc4wp-form input[type='email'] {
  width: calc(100% - 80px);
  vertical-align: top;
}
.footer_widget {
  color: #ededed;
}
.footer_widget a {
  color: #ededed;
}

.transx_prefooter_container .transx-footer-social {
  position: relative;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.transx_prefooter_container .transx-footer-social a {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  /* background: #202C45; */
  border-radius: 40px;
  border: solid 1px #e8734e;
  margin-right: 5px;
  transition: 0.5s;
}

.transx_prefooter_container .transx-footer-social a:last-child {
  margin: 0;
}

.transx_footer_widget_title {
  color: #fff;
  margin: 10px 0 12px;
  font-weight: 700;
}

.footer_widget.widget_custom_html .transx_offices_list p a {
  color: #ededed;
  text-decoration: none;
}
.transx_offices_list p {
  margin-bottom: 0.1rem;
}

.transx_socials_widget_logo {
  object-fit: contain;
  display: flex;
  align-items: start;
  justify-content: start;
}

input[type='submit'] {
  display: inline-flex;
  align-items: center;
  height: 50px;
  font-size: 13px;
  line-height: 46px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  background: transparent;
  padding: 0 32px;
  letter-spacing: 0;
  text-decoration: none;
  border: 2px solid;
  border-radius: 0 0 15px 0;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.footer_widget .mc4wp-form .transx_footer_submit_container {
  display: inline-block;
  margin-left: 10px;
  position: relative;
}

.svg-icon path {
  fill: #fff; /* Initial fill color */
  transition: fill 0.3s ease; /* Smooth transition for fill color change */
}

.svg-icon:hover path {
  fill: #e8734e; /* Color change on hover */
}

.footer_widget .mc4wp-form .transx_footer_submit_container .icon {
  font-size: 18px;
  margin-top: -3px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

input[type='email'] {
  width: 100%;
  height: 35px;
  font-size: 12px;
  line-height: 50px;
  border: 1px solid;
  padding: 0 20px;
  margin-bottom: 20px;
  box-shadow: none;
  border-radius: 0;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  outline: 0;
  color: #000;
  background: #fff;
  border-color: #e2e2e2;
}


.footer .transx_footer {
  font-size: 14px;
}
</style>