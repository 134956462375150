<template>
  <div
    style="
      display: flex;
      justify-content: center;
    "
  >
    <template>
      <div style="width: 100%" >
        <p
          class="display-1 ma-0 text-uppercase"
          :style="
            $vuetify.breakpoint.mobile
              ? `text-align: center; font-size: 1.5rem!important; width: 100%`
              : ''
          "
        >
          {{ $t('request') }}
        </p>
          <div style="display: flex; justify-content: end">
              <v-pagination
                      v-model="page"
                      :value="1"
                      :length="2"
              />
          </div>
        <template v-if="!isLoading">
          <router-link
            v-for="item in requestBookingHistoryList"
            :key="item.id"
            :to="{
              path: '/app/shipping/account/request-booking-history/' + item.id,
            }"
          >
            <RequestBookingHistoryListItem :item="item" />
          </router-link>
        </template>
        <div style="display: flex; justify-content: end; margin-top: 40px">
          <v-pagination
            v-model="page"
            :value="1"
            :length="2"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import RequestBookingHistoryListItem from './RequestBookingHistoryListItem.vue';
import RequestBookingHistoryDetail from './RequestBookingHistoryDetail/index.vue';
export default {
  name: 'RequestBookingHistoryList',
  components: {
    RequestBookingHistoryListItem,
    RequestBookingHistoryDetail,
  },
  data: () => ({
    isLoading: false,
    requestBookingHistoryList: [
      { id: 'OD00051' },
      { id: 'OD00051' },
      { id: 'OD00051' },
      { id: 'OD00051' },
      { id: 'OD00051' },
      { id: 'OD00051' },
    ],
  }),
  computed: {},
};
</script>

<style>
/* CSS styles */
</style>
