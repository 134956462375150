<template>
  <div>
    <!-- <v-card
      class="mx-auto main-card"
      max-width="862"
      outlined
      elevation="2"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="text-h4 mb-1 font-bold">
            {{ $t('title-tracking-search') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-card-actions class="custom-card-actions">
        <v-card
          outlined
          class="sub-card"
        >
          <v-btn text>
            <span class="mdi mdi-sail-boat"></span>{{ $t('title-booking') }}
          </v-btn>
          <v-btn
            text
            class="btn-active"
          >
            <span class="mdi mdi-magnify"></span>{{ $t('title-tracking') }}
          </v-btn>
        </v-card>
      </v-card-actions>
      <v-card
        outlined
        class="custom-card"
      >
        <v-card-actions style="width: 80%">
          <v-card
            outlined
            class="mr-3 my-5 flex-1"
            style="flex: 1; height: 34px"
          >
            <input
              :class="{ 'error-border': errorMessage }"
              type="text"
              v-model="textInput"
              :placeholder="$t('placeholder-tracking-search')"
            />
            <p
              v-if="errorMessage"
              style="color: red; font-size: 13px"
            >
              {{ errorMessage }}
            </p>
          </v-card>
        </v-card-actions>
        <v-btn
          depressed
          color="primary"
          class="btn-submit"
          @click="onTrackingContainer"
          >{{ $t('title-tracking') }}</v-btn
        >
      </v-card>
    </v-card> -->
    <v-card
      class="mx-auto trip"
      max-width="1102"
      outlined
      elevation="2"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="text-h4 mb-1">
            {{ $t('title-trip') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <div
        v-if="portNames.length > 0"
        class="container-step"
      >
        <div
          style="display: flex"
          class="container-step-item"
          v-for="(portName, index) in portNames"
          :key="index"
        >
          <div class="item">
            <div class="item flex-col">
              <img
                :alt="
                  index === 0 || index === portNames.length - 1
                    ? index === portNames.length - 1
                      ? 'location-end'
                      : 'location-start'
                    : 'port'
                "
                :src="
                  index === 0 || index === portNames.length - 1
                    ? index === portNames.length - 1
                      ? '/assets/img/location-end.png'
                      : '/assets/img/location-start.png'
                    : '/assets/img/port.png'
                "
                height="20px"
                :class="{ 'ml-2': !$vuetify.breakpoint.mobile }"
              />
              <h2>{{ portName }}</h2>
            </div>
            <div
              v-if="index !== portNames.length - 1"
              class="item"
            >
              <img
                alt="land-transportation"
                src="/assets/img/sea-transportation.png"
                height="36px"
                :class="{ 'ml-2': !$vuetify.breakpoint.mobile }"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="container-trip">
        <div>
          <p class="">{{ $t('shipment-status') }}</p>
          <template>
            <a-steps
              direction="vertical"
              size="small"
              :current="currentStep"
            >
              <a-step
                v-for="(status, index) in shipmentStatus"
                :key="index"
                :title="status.replace('_', ' ')"
              />
            </a-steps>
          </template>
        </div>
        <div class="map">
          <GoogleMap :places="places" />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import GoogleMap from './GoogleMap.vue';
export default {
  props: {
    onTrackingContainer: {
      type: Function,
    },
    trackingNo: {
      type: String,
    },
  },
  components: { GoogleMap },
  data() {
    return {
      textInput: '',
      errorMessage: '',
      currentStep: 0,
      places: [
        { latitude: 21.0277644, longitude: 105.8341598, title: 'Hà Nội' },
        { latitude: 19.807304, longitude: 105.776164, title: 'Thanh Hóa' },
        { latitude: 16.0544068, longitude: 108.2021667, title: 'Đà Nẵng' },
        {
          latitude: 10.7768894,
          longitude: 106.7008664,
          title: 'Thành phố Hồ Chí Minh',
        },
        { latitude: 10.045118, longitude: 105.746853, title: 'Cần Thơ' },
      ],
    };
  },
  computed: {
    ...mapState('vsl/containerTracking', ['containerMovements']),
    portNames() {
      if (
        this.containerMovements &&
        this.containerMovements.voyages &&
        this.containerMovements.voyages[0] &&
        this.containerMovements.voyages[0].pocs
      ) {
        return this.containerMovements.voyages[0].pocs.map(
          (poc) => poc.portName
        );
      } else {
        return [];
      }
    },
    shipmentStatus() {
      if (this.containerMovements && this.containerMovements.logs) {
        return this.containerMovements.logs.map((log) => log.status);
      } else {
        return [];
      }
    },
  },
  methods: {
    onSearch() {
      if (!this.textInput) {
        this.errorMessage = 'Vui lòng nhập';
      } else {
        this.errorMessage = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-card {
  padding: 48px;
  margin-bottom: 5vw;
}
.title {
  font-size: 40px;
  font-weight: bold;
}
.custom-card-actions {
  padding: 0;
}
.sub-card {
  border-bottom: 0;
  padding: 8px;
}
.btn-active {
  background-color: #dff0ff;
  color: #2e90fa;
}
.custom-card {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}
.custom-input-search {
  padding: 0 20px 0;
  margin: 0;
}
input {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  outline: none;
}

.btn-submit {
  height: 33px !important;
}
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 40px auto;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item h2 {
  font-size: 14px;
  font-weight: normal;
}

.flex-col {
  flex-direction: column;
}

.item.flex-col div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container-trip {
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 10px;
}
.container-trip .ant-steps.ant-steps-vertical.ant-steps-small {
  width: 320px;
  max-width: 320px;
}
.container-trip > div > p {
  margin-bottom: 32px;
  font-size: 18px;
  font-weight: bold;
}
.trip {
  padding: 48px;
}

.stepper.v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none;
}

.stepper.stepper-status {
  width: 270px !important;
}

.step-title {
  font-size: 14px;
}

.error-border {
  border: 1px solid red;
}
.vue-map-container {
  height: 682px !important;
}

.container-step {
  display: flex;
  margin: 40px auto;
  justify-content: center;
  align-items: center;
}

.container-step-item {
}

.map {
  width: 500px;
  height: 682px;
  border-radius: 5px;
}

.map > .vue-map-container > .vue-map {
  border-radius: 10px;
}
</style>
