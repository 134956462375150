<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <!-- <Login /> -->
    <keep-alive>
      <component
        v-bind:is="currentTabComponent"
        @change-tab="onChangeTab"
      ></component>
    </keep-alive>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Login from './Login.vue';
import Signup from './Signup.vue';

export default {
  components: { Login, Signup },
  data: () => ({
    currentTabComponent: 'Login',
  }),

  computed: {
    ...mapState('ui', ['dialogAuth']),

    dialog: {
      get() {
        return this.dialogAuth;
      },
      set(value) {
        this.TOGGLE_DIALOG_AUTH(value);
      },
    },
  },
  mounted() {},
  watch: {
    dialog(value) {
      if (value === true) {
        this.currentTabComponent = 'Login';
      }
    },
  },
  methods: {
    ...mapMutations('ui', ['TOGGLE_DIALOG_AUTH']),
    onChangeTab(value) {
      this.currentTabComponent = value;
    },
  },
};
</script>
