<template>
  <main>
    <!-- Nav Bar Start -->
    <NavBarHome active="services" />
    <!-- Banner -->
    <div
      id="carousel-2"
      class="carousel slide carousel-fade"
      data-ride="carousel"
      data-interval="6000"
    >
      <div
        class="carousel-inner"
        role="listbox"
      >
        <div class="carousel-item active">
          <img
            src="../../../libhome/img/cang hai phong-chi nhanh tan vu.jpg"
            alt="responsive image"
            class="d-block img-fluid"
          />

          <div class="carousel-caption">
            <div>
              <div class="transx_content_slide_wrapper">
                <div class="transx_content_container">
                  <div class="transx_content_wrapper_1">
                    <div
                      class="transx_up_heading_overlay transx_overlay_align_center"
                      id="carousel-item-caption"
                    >
                      VIMC&nbsp;LINES
                    </div>
                    <h2 class="transx_content_slider_title">
                      {{ $t('Booking') }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="booking-page"
      id="booking-page"
    >
      <div class="container container-booking">
        <v-row>
          <v-col
            cols="12"
            :class="{
              'px-1': $vuetify.breakpoint.mobile,
            }"
          >
            <div>
              <div
                :class="{
                  'mt-12': $vuetify.breakpoint.mobile,
                }"
              >
                <v-row
                  no-gutters
                  class="my-2"
                  align="center"
                  justify="center"
                >
                  <v-col
                    cols="5"
                    :class="{
                      'px-1': $vuetify.breakpoint.mobile,
                    }"
                  >
                    <v-row
                      no-gutters
                      align="center"
                    >
                      <v-card
                        rounded="lg"
                        width="100%"
                        style="overflow: hidden"
                      >
                        <!-- <v-tabs
                          v-model="tab"
                          :active-class="searchMenuTabs[tab].color"
                          height="40px"
                          grow
                        >
                          <v-tabs-slider
                            :color="`${searchMenuTabs[tab].color}`"
                          ></v-tabs-slider>
                          <v-tab
                            v-for="item in searchMenuTabs"
                            :key="item.key"
                            :active-class="`${item.color} ${item.colorModifier} ${item.color}--text`"
                            :ripple="false"
                          >
                            <v-icon
                              left
                              v-text="item.icon"
                              :color="item.color"
                            >
                            </v-icon>
                            {{ item.title }}
                          </v-tab>
                        </v-tabs> -->
                      </v-card>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="7"
                    :class="{
                      'px-1': $vuetify.breakpoint.mobile,
                    }"
                  >
                  </v-col>
                </v-row>
              </div>

              <v-tabs-items v-model="tab">
                <v-tab-item key="0">
                  <ContainerTrackingSearch />
                </v-tab-item>
                <v-tab-item key="1">
                  <VesselScheduleSearch request-open-in-new-tab />
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-col>
          <v-col
            cols="7"
            :class="{
              'px-1': $vuetify.breakpoint.mobile,
            }"
          >
          </v-col>
        </v-row>
      </div>
    </div>
    <div
      class="booking-page-table"
      id="booking-page-table"
    >
      <div class="container container-booking-table">
        <!-- Container cho bảng và vòng tròn loading -->
        <div class="table-container">
          <!-- Vòng tròn loading -->
          <div
            v-if="isLoading"
            class="loading-container"
          >
            <v-progress-circular
              indeterminate
              color="primary"
              size="50"
              class="loading-spinner"
            ></v-progress-circular>
            <div class="loading-text">
              <span>Loading</span>
            </div>
          </div>

          <!-- Bảng dữ liệu -->
          <a-table
            v-else
            sticky
            :columns="columns"
            :data-source="tableData"
            :pagination="{ pageSize: 10 }"
            :scroll="scrollSettings"
          >
            <template #bodyCell="{ column }">
              <template v-if="column.key === 'operation'"
                ><a>action</a></template
              >
            </template>
            <template #summary>
              <a-table-summary :fixed="fixedTop ? 'top' : 'bottom'">
                <a-table-summary-row>
                  <a-table-summary-cell
                    :index="0"
                    :col-span="2"
                  >
                    <a-switch
                      v-model="fixedTop"
                      checked-children="Fixed Top"
                      un-checked-children="Fixed Top"
                    ></a-switch>
                  </a-table-summary-cell>
                  <a-table-summary-cell
                    :index="2"
                    :col-span="8"
                  >
                    Scroll Context
                  </a-table-summary-cell>
                  <a-table-summary-cell :index="10"
                    >Fix Right</a-table-summary-cell
                  >
                </a-table-summary-row>
              </a-table-summary>
            </template>
          </a-table>
        </div>
      </div>
    </div>

    <FooterHome />

    <!-- Back to top button -->
    <a
      href="/home"
      class="back-to-top"
      ><i class="fa fa-chevron-up"></i
    ></a>

    <NavBarLeftHome />
  </main>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapState, mapMutations } from 'vuex';
import VesselScheduleSearch from '@/components/shared/local/vsl/VesselScheduleSearch';
import ContainerTrackingSearch from '@/components/shared/local/vsl/ContainerTrackingSearch';
import NavBarHome from '@/views/Home/PageChild/NavBarHome.vue';
import FooterHome from '@/views/Home/PageChild/FooterHome.vue';
import NavBarLeftHome from '@/views/Home/PageChild/NavBarLeftHome.vue';
import { nextTick, ref } from 'vue';
import SmoothScroll from 'smooth-scroll';
import VesselScheduleApi from '@/api/vsl/vessel-schedule.api';

export default {
  name: 'Booking',
  components: {
    VesselScheduleSearch,
    ContainerTrackingSearch,
    NavBarHome,
    NavBarLeftHome,
    FooterHome,
  },
  data() {
    return {
      tab: 1,
      searchMenuTabs: [
        {
          key: 0,
          title: 'Tracking',
          icon: 'mdi-cube-scan',
          color: 'brown',
          colorModifier: 'lighten-5',
          colorModifierActive: 'lighten-3',
        },
        {
          key: 1,
          title: 'Booking',
          icon: 'mdi-focus-field',
          color: 'light-blue',
          colorModifier: 'lighten-5',
          colorModifierActive: 'lighten-3',
        },
        // {
        //   key: 2,
        //   title: 'Debit',
        //   icon: 'mdi-file-document-multiple-outline',
        //   color: 'green',
        //   colorModifier: 'lighten-5',
        //   colorModifierActive: 'lighten-3',
        // },
      ],
      columns: [
        {
          title: 'Mã chuyến',
          width: 92,
          dataIndex: 'voyage.code',
          key: 'voyage.code',
          className: 'align-center',
          fixed: 'left',
        },
        { title: 'Tàu', dataIndex: 'vessel.name', key: '1', width: 200 },
        { title: 'ETD', dataIndex: 'etd', key: '2', width: 100 },
        { title: 'ETA', dataIndex: 'eta', key: '3', width: 100 },
        {
          title: 'POL',
          dataIndex: 'pol_terminal.port.name',
          key: '4',
          width: 250,
        },
        {
          title: 'POL Terminal',
          dataIndex: 'pol_terminal.name',
          key: '5',
          width: 250,
        },
        {
          title: 'POD',
          dataIndex: 'pod_terminal.port.name',
          key: '6',
          width: 250,
        },
        {
          title: 'POD Terminal',
          dataIndex: 'pod_terminal.name',
          key: '7',
          width: 250,
        },
      ],
      tableData: [],
      isLoading: true,
      fixedTop: false,
      scrollSettings: {}, // Mặc định không có thanh cuộn
    };
  },
  mounted() {
    this.$nextTick(() => {
      const scrollToElement = (element, offset = 100) => {
        const start = window.pageYOffset;
        const end =
          element.getBoundingClientRect().top + window.pageYOffset - offset;
        const distance = end - start;
        const duration = 3000; // Thay đổi thời gian cuộn (ms)
        let startTime = null;

        const scroll = (currentTime) => {
          if (startTime === null) startTime = currentTime;
          const timeElapsed = currentTime - startTime;
          const progress = Math.min(timeElapsed / duration, 1);
          const easing =
            progress < 0.5
              ? 4 * progress * progress * progress
              : 1 - Math.pow(-2 * progress + 2, 3) / 2;
          window.scrollTo(0, start + distance * easing);
          if (timeElapsed < duration) {
            requestAnimationFrame(scroll);
          }
        };

        requestAnimationFrame(scroll);
      };

      const bookingPageElement = document.getElementById(
        'carousel-item-caption'
      );
      if (bookingPageElement) {
        const headerHeight = document.querySelector('.navbar').offsetHeight;
        scrollToElement(bookingPageElement);
      }
    });
    this.fetchAndUpdateBookingData();
    window.addEventListener('resize', this.handleResize);
    this.handleResize(); // Kiểm tra kích thước ban đầu khi trang được tải
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {},
  methods: {
    ...mapMutations('vsl/vesselSchedule', ['RESET']),
    ...mapActions('vsl/vesselSchedule', ['fetchVesselScheduleList']),
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Tháng bắt đầu từ 0
      const year = date.getUTCFullYear();
      return `${day}/${month}/${year}`;
    },
    async fetchAndUpdateBookingData() {
      this.isLoading = true;

      try {
        const queryParams = { page: 0, size: 10000 };
        const res = await VesselScheduleApi.fetchVesselScheduleList(
          queryParams
        );
        this.tableData = res.data.data || [];

        this.tableData = this.tableData.map((item) => {
          return {
            ...item,
            etd: this.formatDate(item.etd),
            eta: this.formatDate(item.eta),
          };
        });

        // Sắp xếp theo `etd` giảm dần (dựa trên đối tượng Date)
        this.tableData.sort((a, b) => {
          const dateA = new Date(a.etd.split('/').reverse().join('/'));
          const dateB = new Date(b.etd.split('/').reverse().join('/'));
          return dateB - dateA;
        });
      } catch (error) {
        console.error('Error fetching booking data:', error);
      } finally {
        this.isLoading = false;
      }
    },
    handleResize() {
      const screenWidth = window.innerWidth;
      // Khi màn hình nhỏ hơn 1367px, bật thanh cuộn
      if (screenWidth < 1367) {
        this.scrollSettings = { x: 1000 }; // Đặt chiều ngang cuộn
      } else {
        this.scrollSettings = {}; // Không cuộn nếu màn hình lớn hơn
      }
    },
  },
  created() {
    const { tracking_no, debit_search_no, pol_code, pod_code } =
      this.$route.query;
    if (tracking_no) {
      this.tab = 0;
    }
    if (pol_code || pod_code) {
      this.tab = 1;
    }
    // if (debit_search_no){
    //   this.tab = 2;
    // }
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .v-btn::before {
    background-color: transparent;
  }
  .v-tabs-items {
    background-color: transparent !important;
  }
  .ant-table-thead > tr > th {
    background-color: #e8724a !important; /* Màu nền bạn muốn */
    color: #ffffff !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    font-family: 'Montserrat', sans-serif !important;
    border-left: 1px solid #ffffff !important;
    padding: 16px 0 16px 10px !important;
  }
  .ant-table-tbody > tr:nth-child(even) {
    background-color: #ffffff !important;
  }
  .ant-table-tbody > tr:nth-child(odd) {
    background-color: #f2f2f2 !important;
  }
  .ant-table-tbody > tr > td {
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family: 'Montserrat', sans-serif !important;
    color: #000000 !important;
    padding: 16px 0 16px 10px !important;
  }
  .ant-table-tbody > tr:hover {
    background: #f2f2f2 !important;
  }
  .align-center {
    text-align: center !important;
    padding: 0 !important;
  }
  .v-input__slot {
    width: 530px !important;
  }
}

html,
body {
  height: 100%;
  margin: 0;
  scroll-behavior: smooth;
}

main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f2f2f2;
}

#booking-page {
  display: block; /* Đảm bảo phần tử không bị ẩn */
  visibility: visible; /* Đảm bảo phần tử có thể nhìn thấy */
}

#booking-page-table {
  display: block; /* Đảm bảo phần tử không bị ẩn */
  visibility: visible; /* Đảm bảo phần tử có thể nhìn thấy */
  margin-bottom: 80px;
}

.container-booking {
  max-width: 1734px !important;
  margin-bottom: -35px;
  margin-left: 94px;
}

.container-booking-table {
  max-width: 1704px !important;
  margin-bottom: -35px;
  margin-top: -60px;
}

/* Thay đổi màu nền và màu chữ cho các mục trang */
:deep(.ant-pagination-item a) {
  color: #000000 !important;
  font-family: 'Montserrat', sans-serif !important;
}

/* Thay đổi màu nền và màu chữ cho các mục trang */
:deep(.ant-pagination-item:hover) {
  border-color: #e8724a !important; /* Đặt màu đường viền khi di chuột qua */
}

:deep(.ant-pagination-item a:hover) {
  color: #e8724a !important; /* Đặt màu đường viền khi di chuột qua */
}

:deep(.ant-pagination-item-active) {
  border-color: #e8724a !important;
  font-weight: 500 !important;
}

:deep(.ant-pagination-item-active a) {
  color: #e8724a !important;
}

:deep(.ant-pagination-item-link:hover) {
  color: #e8724a !important; /* Đặt màu đường viền khi di chuột qua */
  border-color: #e8724a !important;
}

:deep(.ant-pagination-item-link) {
  color: #e8724a !important; /* Đặt màu đường viền khi di chuột qua */
  border: none !important;
}

:deep(.ant-pagination-prev a) {
  color: #000000 !important;
}

:deep(.ant-pagination-next a) {
  color: #000000 !important;
}

:deep(.ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon) {
  color: red !important;
}

:deep(.ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon) {
  color: red !important;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading-spinner {
  margin-bottom: 10px;
}

.loading-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.table-container .ant-table-body {
  overflow-x: hidden; /* Mặc định không cuộn */
}

@media screen and (max-width: 1367px) {
  .table-container .ant-table-body {
    overflow-x: auto; /* Hiển thị thanh cuộn trên tablet */
  }
  .container-booking {
    max-width: 1734px !important;
    margin-bottom: -20px;
    margin-left: 12px;
  }
  :deep(.v-input__slot) {
    width: 375px !important;
  }
}

@media screen and (max-width: 1181px) {
  .table-container .ant-table-body {
    overflow-x: auto; /* Hiển thị thanh cuộn trên tablet */
  }
  :deep(.px-2) {
    padding: 0 !important;
  }
  :deep(.pl-3) {
    padding: 0 !important;
  }
  :deep(.mb-4) {
    margin: 0 !important;
  }
  :deep(.mt-12) {
    margin: 0 !important;
  }
  :deep(.v-window-item) {
    padding: 0 10px !important;
  }
  :deep(.v-input__slot) {
    width: 915px !important;
  }
}

@media screen and (max-width: 1025px) {
  .table-container .ant-table-body {
    overflow-x: auto; /* Hiển thị thanh cuộn trên tablet */
  }
  :deep(.px-2) {
    padding: 0 !important;
  }
  :deep(.pl-3) {
    padding: 0 !important;
  }
  :deep(.mb-4) {
    margin: 0 !important;
  }
  :deep(.mt-12) {
    margin: 0 !important;
  }
  :deep(.v-window-item) {
    padding: 0 10px !important;
  }
  :deep(.v-input__slot) {
    width: 680px !important;
  }
}

footer {
  padding: 20px 0; /* Điều chỉnh padding nếu cần */
}
</style>
