<template>
  <div
    class="text-center"
    style="font-weight: 600"
  >
    <div
      class="text--secondary overline"
      style="font-size: 12px; line-height: 1rem"
    >
      {{ $filters.formatDate(date) }}
    </div>
    <div
      style="line-height: 1"
      :style="
        $vuetify.breakpoint.mobile ? `font-size:18px;` : `font-size:30px;`
      "
    >
      {{ code }}
    </div>
    <div
      :style="
        $vuetify.breakpoint.mobile
          ? `font-size:8px; line-height:1.5;`
          : `font-size:10px; line-height:1.5;`
      "
    >
      {{ terminalName }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    date: Date,
    code: String,
    terminalName: String,
  },
};
</script>

<style></style>
