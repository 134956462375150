import { api } from '@/api';

export default {
  fetchProvinceList() {
    return api.get('/v1/provinces');
  },
  fetchProvinceDistrictList({ code }) {
    return api.get(`/v1/provinces/${code}/districts`);
  },
};
