var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c("a-button", { on: { click: _vm.handleClickBack } }, [
          _c("span", { staticClass: "mdi mdi-arrow-left" }),
          _c("span", [_vm._v("Back")]),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticStyle: { "margin-bottom": "40px" } },
      [
        _c(
          "a-card",
          {
            staticStyle: {
              margin: "20px 0",
              "box-shadow": "0 25px 25px -12px rgb(0 0 0 / 0.25)",
              "border-radius": "20px",
            },
            attrs: { width: "100%" },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("span", [
                              _c("img", {
                                staticStyle: { height: "20px" },
                                attrs: {
                                  src: "/assets/img/location-start.png",
                                },
                              }),
                              _vm._v(" Hà Nội "),
                            ]),
                            _c("hr", {
                              staticStyle: { width: "20px", margin: "0 5px" },
                            }),
                            _c("span", [
                              _c("img", {
                                staticStyle: { height: "20px" },
                                attrs: { src: "/assets/img/location-end.png" },
                              }),
                              _vm._v(" Hồ Chí Minh "),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "span",
                              { staticStyle: { "margin-right": "10px" } },
                              [_vm._v("20/3/2024")]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticStyle: {
                                  color: "#aec76d",
                                  "background-color": "#f0f9eb",
                                  "text-transform": "none",
                                },
                              },
                              [_vm._v(" Done ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            [
              _c("div", { staticStyle: { display: "flex" } }, [
                _c("div", { staticStyle: { width: "20%" } }, [
                  _c("div", [_vm._v("#OD00051")]),
                  _c("div", { staticStyle: { "font-weight": "900" } }, [
                    _vm._v("40HC"),
                  ]),
                ]),
                _c("div", { staticStyle: { width: "70%" } }, [
                  _c("div", [
                    _c("span", [_vm._v(" Commodity ")]),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-left": "5px",
                          "font-weight": "900",
                        },
                      },
                      [_vm._v("Thực phẩm")]
                    ),
                  ]),
                  _c("div", [
                    _c("span", [_vm._v("Delivery date")]),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-left": "5px",
                          "font-weight": "900",
                        },
                      },
                      [_vm._v("18/03/2024")]
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "10%",
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "end",
                      color: "#2e90fa",
                      "font-size": "23px",
                      "font-weight": "600",
                    },
                  },
                  [_vm._v(" $100 ")]
                ),
              ]),
            ],
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticStyle: {
          "background-color": "#fff",
          padding: "20px",
          "border-radius": "20px",
          "box-shadow": "0 25px 25px -12px rgb(0 0 0 / 0.25)",
        },
      },
      [
        _c(
          "a-tabs",
          {
            attrs: { "default-active-key": "1" },
            on: { change: _vm.callback },
          },
          [
            _c(
              "a-tab-pane",
              { key: "1", attrs: { tab: "Shipment information" } },
              [
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "center",
                      },
                    },
                    [
                      _c("span", [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "center",
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: { height: "20px" },
                              attrs: { src: "/assets/img/location-start.png" },
                            }),
                          ]
                        ),
                        _c("h3", [_vm._v("Hà Nội")]),
                      ]),
                      _c("img", {
                        staticStyle: { height: "40px", margin: "0 20px" },
                        attrs: { src: "/assets/img/land-transportation.png" },
                      }),
                      _c("span", [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "center",
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: { height: "20px" },
                              attrs: { src: "/assets/img/location-end.png" },
                            }),
                          ]
                        ),
                        _c("h3", [_vm._v("Hồ Chí Minh")]),
                      ]),
                    ]
                  ),
                  _c("div", [
                    _c("div", [
                      _c(
                        "h3",
                        {
                          staticStyle: {
                            "background-color": "#eaecf0",
                            padding: "10px",
                            "font-size": "18px",
                            "font-weight": "900",
                          },
                        },
                        [_vm._v(" Vận chuyển từ kho hàng tới Cảng ")]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                            padding: "0 20px",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "text-transform": "uppercase" } },
                            [_vm._v("cma cgm")]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                              },
                            },
                            [
                              _c("a-button", [
                                _c("img", {
                                  staticStyle: { height: "20px" },
                                  attrs: { src: "/assets/img/message-box.png" },
                                }),
                              ]),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    margin: "0 20px",
                                    "font-weight": "900",
                                    color: "#000",
                                  },
                                },
                                [_vm._v(" $100 ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ],
              ],
              2
            ),
            _c(
              "a-tab-pane",
              {
                key: "2",
                attrs: { tab: "Shipment details", "force-render": "" },
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "border-bottom": "1px solid #ccc",
                      padding: "20px 0",
                    },
                  },
                  [
                    _c(
                      "a-steps",
                      [
                        _c(
                          "a-step",
                          {
                            staticClass: "custom-step",
                            attrs: { status: "finish", title: "Đã xác nhận" },
                          },
                          [
                            _c("a-icon", {
                              attrs: { slot: "icon", type: "file-text" },
                              slot: "icon",
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a-step",
                          {
                            attrs: {
                              status: "wait",
                              title: "Đã lấy hàng tại kho",
                            },
                          },
                          [
                            _c("a-icon", {
                              attrs: { slot: "icon", type: "file-text" },
                              slot: "icon",
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a-step",
                          {
                            attrs: {
                              status: "wait",
                              title: "Đã vận chuyển tới Cảng đi",
                            },
                          },
                          [
                            _c("a-icon", {
                              attrs: { slot: "icon", type: "file-text" },
                              slot: "icon",
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a-step",
                          {
                            attrs: {
                              status: "wait",
                              title: "Đã vận chuyển tới Cảng đến",
                            },
                          },
                          [
                            _c("a-icon", {
                              attrs: { slot: "icon", type: "file-text" },
                              slot: "icon",
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a-step",
                          {
                            attrs: {
                              status: "wait",
                              title: "Đã vận chuyển tới Khách hàng",
                            },
                          },
                          [
                            _c("a-icon", {
                              attrs: { slot: "icon", type: "file-text" },
                              slot: "icon",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticStyle: { padding: "20px" } }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-weight": "600",
                        "font-size": "18px",
                      },
                    },
                    [_vm._v("Processing")]
                  ),
                  _c(
                    "div",
                    { staticStyle: { margin: "20px" } },
                    [
                      _c(
                        "a-steps",
                        {
                          staticClass: "custom-step-vertical",
                          attrs: {
                            "progress-dot": "",
                            current: 1,
                            direction: "vertical",
                          },
                        },
                        [
                          _c("a-step", {
                            attrs: {
                              title: "Khách hàng đã đặt hàng",
                              description: "20/03/2024 12:00:00",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }