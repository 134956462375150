import ContainerApi from '@/api/vsl/container.api';

const initialState = () => ({
  container: {
    code: 'null',
    name: '',
  },
  containerList: [],
});

const state = initialState();
const getters = {};
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  SET_CONTAINER_LIST(state, payload) {
    state.containerList = payload;
  },
};
const actions = {
  async fetchContainerList({ commit }, payload) {
    const res = await ContainerApi.getContainerList();
    const mapped = res.data.data.map((item) => {
      const mappedItem = {
        code: item.code,
        name: item.name,
      };
      return mappedItem;
    });
    commit('SET_CONTAINER_LIST', mapped);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
