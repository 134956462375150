<template>
  <div>
    <p class="text-h6 font-weight-medium text--secondary text-center mb-8">
      Partner
    </p>
    <swiper
      class="swiper"
      :options="swiperOption"
    >
      <swiper-slide>
        <img
          src="/assets/img/partner/evergreen.png"
          width="150px"
          height="100%"
        />
      </swiper-slide>
      <swiper-slide>
        <img
          src="/assets/img/partner/evn.png"
          width="250px"
          height="100%"
        />
      </swiper-slide>
      <swiper-slide>
        <img
          src="/assets/img/partner/hoaphat.png"
          width="250px"
          height="100%"
        />
      </swiper-slide>
      <swiper-slide>
        <img
          src="/assets/img/partner/honda.png"
          width="250px"
          height="100%"
        />
      </swiper-slide>
      <swiper-slide>
        <img
          src="/assets/img/partner/idemitsu.png"
          width="250px"
          height="100%"
        />
      </swiper-slide>
      <swiper-slide>
        <img
          src="/assets/img/partner/petrovietnam.png"
          width="150px"
          height="100%"
        />
      </swiper-slide>
      <swiper-slide>
        <img
          src="/assets/img/partner/scg.png"
          width="150px"
          height="100%"
        />
      </swiper-slide>
      <swiper-slide>
        <img
          src="/assets/img/partner/toyota.png"
          width="150px"
          height="100%"
        />
      </swiper-slide>
      <div
        class="swiper-pagination"
        slot="pagination"
      ></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        centeredSlides: true,
        slidesPerView: this.$vuetify.breakpoint.mobile ? 2 : 4,
        spaceBetween: 100,
        loop: true,
        speed: 800,
        grabCursor: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.swiper {
  height: 160px; /* Can be anything */
  position: relative;
}
img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
