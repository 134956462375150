var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          staticClass: "mx-auto trip",
          attrs: { "max-width": "1102", outlined: "", elevation: "2" },
        },
        [
          _c(
            "v-list-item",
            { attrs: { "three-line": "" } },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", { staticClass: "text-h4 mb-1" }, [
                    _vm._v(" " + _vm._s(_vm.$t("title-trip")) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.portNames.length > 0
            ? _c(
                "div",
                { staticClass: "container-step" },
                _vm._l(_vm.portNames, function (portName, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "container-step-item",
                      staticStyle: { display: "flex" },
                    },
                    [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "item flex-col" }, [
                          _c("img", {
                            class: { "ml-2": !_vm.$vuetify.breakpoint.mobile },
                            attrs: {
                              alt:
                                index === 0 ||
                                index === _vm.portNames.length - 1
                                  ? index === _vm.portNames.length - 1
                                    ? "location-end"
                                    : "location-start"
                                  : "port",
                              src:
                                index === 0 ||
                                index === _vm.portNames.length - 1
                                  ? index === _vm.portNames.length - 1
                                    ? "/assets/img/location-end.png"
                                    : "/assets/img/location-start.png"
                                  : "/assets/img/port.png",
                              height: "20px",
                            },
                          }),
                          _c("h2", [_vm._v(_vm._s(portName))]),
                        ]),
                        index !== _vm.portNames.length - 1
                          ? _c("div", { staticClass: "item" }, [
                              _c("img", {
                                class: {
                                  "ml-2": !_vm.$vuetify.breakpoint.mobile,
                                },
                                attrs: {
                                  alt: "land-transportation",
                                  src: "/assets/img/sea-transportation.png",
                                  height: "36px",
                                },
                              }),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _c("div", { staticClass: "container-trip" }, [
            _c(
              "div",
              [
                _c("p", {}, [_vm._v(_vm._s(_vm.$t("shipment-status")))]),
                [
                  _c(
                    "a-steps",
                    {
                      attrs: {
                        direction: "vertical",
                        size: "small",
                        current: _vm.currentStep,
                      },
                    },
                    _vm._l(_vm.shipmentStatus, function (status, index) {
                      return _c("a-step", {
                        key: index,
                        attrs: { title: status.replace("_", " ") },
                      })
                    }),
                    1
                  ),
                ],
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "map" },
              [_c("GoogleMap", { attrs: { places: _vm.places } })],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }