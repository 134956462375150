  <template>
  <main>
    <!-- Nav Bar Start -->
    <!-- <NavBarHome active="services" /> -->
    <!-- Banner -->
    <!-- <div
      id="carousel-2"
      class="carousel slide carousel-fade"
      data-ride="carousel"
      data-interval="6000"
    >
      <div
        class="carousel-inner"
        role="listbox"
      >
        <div class="carousel-item active">
          <img
            src="../../../libhome/img/cang hai phong-chi nhanh tan vu.jpg"
            alt="responsive image"
            class="d-block img-fluid"
          />

          <div class="carousel-caption">
            <div>
              <div class="transx_content_slide_wrapper">
                <div class="transx_content_container">
                  <div class="transx_content_wrapper_1">
                    <div
                      class="transx_up_heading_overlay transx_overlay_align_center"
                    >
                      VIMC&nbsp;LINES
                    </div>
                    <h2 class="transx_content_slider_title">
                      {{ $t('services') }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- Banner End -->

    <!-- <div class="container">
      <div class="">
        <div
          class=""
          data-id="19c4fdd2"
          data-element_type="column"
        >
          <div
            class=""
            data-id="4e86b3b8"
            data-element_type="widget"
            data-widget_type="transx_heading.default"
          >
            <div class="elementor-widget-container">
              <div
                class="transx_heading_widget"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
                id="service-1"
              >
                <div
                  class=""
                  style="
                    font-size: 12px;
                    line-height: 1.6;
                    letter-spacing: 0.15em;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: #a9aeb3;
                    margin-bottom: 13px;
                    position: relative;
                    z-index: 5;
                  "
                >
                  {{ $t('services') }}
                </div>
                <h2
                  class="transx_heading"
                  style="font-size: 45px; line-height: 50px; font-weight: 600"
                >
                  {{ $t('transportationServices') }}
                </h2>
              </div> -->
              <!-- <h2
                class="transx_heading"
                style="font-size: 50px; line-height: 50px; font-weight: 600"
              >
                
              </h2> -->
            <!-- </div>
          </div>
        </div>
      </div>
      <div style="height: 50px"></div>
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-4dad42f8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="4dad42f8"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-extended">
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="service-item">
                <div
                  class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-79384b5b"
                  data-id="79384b5b"
                  data-element_type="column"
                >
                  <div
                    class="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      class="elementor-element elementor-element-4eaaa3c elementor-widget elementor-widget-transx_promo_box"
                      data-id="4eaaa3c"
                      data-element_type="widget"
                      data-widget_type="transx_promo_box.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="transx_promo_box_widget">
                          <div class="transx_promo_box_item transx_view_type_1">
                            <div class="transx_promo_box_image">
                              <img
                                src="../../../libhome/img/icon_new_2.png"
                                alt="Icon"
                                class="orange-icon"
                                style="color: #e8734e; width: 145px"
                              />
                            </div>
                            <div class="transx_promo_box_content">
                              <h6 class="transx_promo_box_title">
                                <span class="transx_promo_box_count">01</span>
                                <p class="title-service-count">
                                  {{ $t('lines') }}
                                </p>
                              </h6>
                              <p class="transx_promo_box_description">
                                {{ $t('logisticsService') }}&nbsp;&nbsp;&nbsp;<a
                                  href="/"
                                  target="_blank"
                                >
                                  <svg class="icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      id="next-arrow"
                                    >
                                      <path
                                        d="M22.707,12.707a1,1,0,0,0,0-1.414l-5-5a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-3.293,3.293a1,1,0,0,0,1.414,1.414Z"
                                      ></path>
                                    </svg>
                                  </svg>
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service-item">
                <div
                  class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-121bc60c"
                  data-id="121bc60c"
                  data-element_type="column"
                >
                  <div
                    class="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      class="elementor-element elementor-element-671c5cbb elementor-widget elementor-widget-transx_promo_box"
                      data-id="671c5cbb"
                      data-element_type="widget"
                      data-widget_type="transx_promo_box.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="transx_promo_box_widget">
                          <div class="transx_promo_box_item transx_view_type_1">
                            <div class="transx_promo_box_image">
                              <img
                                src="../../../libhome/img/serviceimg3.svg"
                                alt="Icon"
                                class="orange-icon"
                                style="color: #e8734e; width: 120px"
                              />
                            </div>
                            <div class="transx_promo_box_content">
                              <h6 class="transx_promo_box_title">
                                <span class="transx_promo_box_count">02</span>
                                <p class="title-service-count">
                                  {{
                                    this.$i18n.locale == 'en'
                                      ? 'Terminal'
                                      : 'Terminal'
                                  }}
                                </p>
                              </h6>
                              <p class="transx_promo_box_description">
                                {{
                                  $t('cargoHandlingTerminal')
                                }}&nbsp;&nbsp;&nbsp;<a
                                  href="https://www.vimcdinhvu.com.vn"
                                  target="_blank"
                                >
                                  <svg class="icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      id="next-arrow"
                                    >
                                      <path
                                        d="M22.707,12.707a1,1,0,0,0,0-1.414l-5-5a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-3.293,3.293a1,1,0,0,0,1.414,1.414Z"
                                      ></path>
                                    </svg>
                                  </svg>
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service-item">
                <div
                  class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-4f3022a"
                  data-id="4f3022a"
                  data-element_type="column"
                >
                  <div
                    class="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      class="elementor-element elementor-element-26fea086 elementor-widget elementor-widget-transx_promo_box"
                      data-id="26fea086"
                      data-element_type="widget"
                      data-widget_type="transx_promo_box.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="transx_promo_box_widget">
                          <div class="transx_promo_box_item transx_view_type_1">
                            <div class="transx_promo_box_image">
                              <img
                                src="../../../libhome/img/terminal1.png"
                                alt="Icon"
                                class="orange-icon"
                                style="width: 120px"
                              />
                            </div>
                            <div class="transx_promo_box_content">
                              <h6 class="transx_promo_box_title">
                                <span class="transx_promo_box_count">03</span>
                                <p class="title-service-count">
                                  {{ $t('depot') }}
                                </p>
                              </h6>
                              <p class="transx_promo_box_description">
                                {{ $t('warehouse') }}&nbsp;&nbsp;&nbsp;<a
                                  href="https://vimadeco.com.vn/"
                                  target="_blank"
                                >
                                  <svg class="icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      id="next-arrow"
                                    >
                                      <path
                                        d="M22.707,12.707a1,1,0,0,0,0-1.414l-5-5a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-3.293,3.293a1,1,0,0,0,1.414,1.414Z"
                                      ></path>
                                    </svg>
                                  </svg>
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div> -->

    <!-- Service Start -->
    <!-- <div class="service">
      <div class="container">
        <div class="transx_heading_widget">
          <div class="transx_up_heading">{{ $t('services') }}</div>
          <h2
            class="transx_heading"
            style="margin-top: -50px"
          >
            {{ $t('serviceTitle1') }}
          </h2>
        </div>
        <div style="height: 50px"></div>
        <div class="elementor-container elementor-column-gap-narrow">
          <div
            class="elementor-column-service elementor-col-25 elementor-top-column"
          >
            <div class="elementor-widget-wrap">
              <div class="elementor-widget transx_linked_item">
                <div class="elementor-widget-container">
                  <div class="transx_linked_item_widget">
                    <div class="transx_linked_item">
                      <div class="transx_linked_item_wrapper">
                        <img
                          class="transx_img--bg-service"
                          src="https://demo.artureanec.com/themes/transx-new/wp-content/uploads/2021/07/services_new_1.jpg"
                          alt="Background Image"
                        />
                        <div class="transx_linked_item_up_title">
                          TRANSPORTERIUM SERVICES
                        </div>
                        <div class="transx_action_block_inner">
                          <h5>
                            <a
                              class="transx_linked_title"
                              href="https://demo.artureanec.com/themes/transx/service-details/"
                            >
                              <span class="transx_linked_item_title">{{
                                $t('lines')
                              }}</span>
                            </a>
                          </h5>
                          <span class="transx_linked_item_counter">01</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="elementor-column-service elementor-col-25 elementor-top-column"
          >
            <div class="elementor-widget-wrap">
              <div class="elementor-widget transx_linked_item">
                <div class="elementor-widget-container">
                  <div class="transx_linked_item_widget">
                    <div class="transx_linked_item">
                      <div class="transx_linked_item_wrapper">
                        <img
                          class="transx_img--bg-service"
                          src="https://demo.artureanec.com/themes/transx-new/wp-content/uploads/2021/07/services_new_1.jpg"
                          alt="Background Image"
                        />
                        <div class="transx_linked_item_up_title">
                          TRANSPORTERIUM SERVICES
                        </div>
                        <div class="transx_action_block_inner">
                          <h5>
                            <a
                              class="transx_linked_title"
                              href="https://demo.artureanec.com/themes/transx/service-details/"
                            >
                              <span class="transx_linked_item_title">
                                {{
                                  this.$i18n.locale == 'en'
                                    ? 'Terminal'
                                    : 'Terminal'
                                }}</span
                              >
                            </a>
                          </h5>
                          <span class="transx_linked_item_counter">02</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="elementor-column-service elementor-col-25 elementor-top-column"
          >
            <div class="elementor-widget-wrap">
              <div class="elementor-widget transx_linked_item">
                <div class="elementor-widget-container">
                  <div class="transx_linked_item_widget">
                    <div class="transx_linked_item">
                      <div class="transx_linked_item_wrapper">
                        <img
                          class="transx_img--bg-service"
                          src="https://demo.artureanec.com/themes/transx-new/wp-content/uploads/2021/07/services_new_1.jpg"
                          alt="Background Image"
                        />
                        <div class="transx_linked_item_up_title">
                          TRANSPORTERIUM SERVICES
                        </div>
                        <div class="transx_action_block_inner">
                          <h5>
                            <a
                              class="transx_linked_title"
                              href="https://demo.artureanec.com/themes/transx/service-details/"
                            >
                              <span class="transx_linked_item_title">{{
                                $t('depot')
                              }}</span>
                            </a>
                          </h5>
                          <span class="transx_linked_item_counter">03</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-work">
        <section class="section">
          <div
            class="heading"
            id="service-2"
          >
            {{ $t('servStepforwork') }}
          </div>
          <h2>{{ $t('servHowweWork') }}</h2>
          <div class="steps-container">
            <div class="step">
              <div class="number">01</div>
              <h5>{{ $t('credibility') }}</h5>
              <ul>
                <li>{{ $t('howCommit') }}</li>
                <li>{{ $t('howCommit1') }}</li>
              </ul>
            </div>
            <div class="step">
              <div class="number">02</div>
              <h5>{{ $t('speed') }}</h5>
              <ul>
                <li>{{ $t('howPrompt') }}</li>
                <li>{{ $t('howPrompt1') }}</li>
              </ul>
            </div>
            <div class="step">
              <div class="number">03</div>
              <h5>{{ $t('Professionalism') }}</h5>
              <ul>
                <li>{{ $t('howProfess') }}</li>
                <li style="width: 280px">
                  {{ $t('howProfess1') }}
                </li>
              </ul>
            </div>
            <div class="step">
              <div class="number">04</div>
              <h5>{{ $t('Readiness') }}</h5>
              <ul>
                <li>{{ $t('howReadi') }}</li>
                <li>{{ $t('howReadi1') }}</li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div> -->
    <!-- Service End -->

    <!-- Facts Start -->
    <!-- <div
      class="facts-service"
      data-parallax="scroll"
      data-image-src="img/facts.jpg"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="facts-item">
              <i class="fa fa-map-marker-alt"></i>
              <div class="facts-text">
                <h3 data-toggle="counter-up">5</h3>
                <p>{{ $t('servPoint') }}</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="facts-item">
              <i class="fa fa-user"></i>
              <div class="facts-text">
                <h3 data-toggle="counter-up">350</h3>
                <p>{{ $t('enAndWork') }}</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="facts-item">
              <i class="fa fa-users"></i>
              <div class="facts-text">
                <h3 data-toggle="counter-up">500</h3>
                <p>{{ $t('happyClients') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- Facts End -->

    <!-- <FooterHome /> -->

    <!-- Back to top button -->
    <!-- <a
      href="/home"
      class="back-to-top"
      ><i class="fa fa-chevron-up"></i
    ></a> -->

    <!-- <NavBarLeftHome /> -->
  </main>
</template>
  <script>
import NavBarHome from '@/views/Home/PageChild/NavBarHome.vue';
import FooterHome from '@/views/Home/PageChild/FooterHome.vue';
import NavBarLeftHome from '@/views/Home/PageChild/NavBarLeftHome.vue';

export default {
  components: { NavBarLeftHome, FooterHome, NavBarHome },
};
('use strict');

window.addEventListener('scroll', function () {
  const navbar = document.getElementById('navbarCr');
  if (navbar) {
    if (window.scrollY > 0) {
      navbar.style.marginTop = '0px';
    } else {
      navbar.style.marginTop = '10px';
    }
  }
});

document.addEventListener('DOMContentLoaded', function () {
  const openBtn = document.querySelector('.nav-column-open-btn');
  const closeBtn = document.querySelector('.nav-column-close-btn');
  const overlay = document.querySelector('.overlay');
  const navbarColumn = document.querySelector('.navbar-column');
  if (openBtn) {
    openBtn.addEventListener('click', function () {
      navbarColumn.classList.add('active');
      overlay.classList.add('active');
    });

    closeBtn.addEventListener('click', function () {
      navbarColumn?.classList.remove('active');
      overlay?.classList.remove('active');
    });

    overlay.addEventListener('click', function () {
      navbarColumn.classList.remove('active');
      overlay.classList.remove('active');
    });
  }
});
</script>
  <style>
/*
  Removes white gap between slides
  */
.carousel {
  position: relative;
  width: 100%;
  min-height: 400px;
  background: #ffffff;
  margin-bottom: 45px;
}

.carousel-item .img-fluid {
  width: 100%;
  height: 850px;
}

/*
  anchors are inline so you need ot make them block to go full width
  */
.carousel-item a {
  display: block;
  width: 100%;
}
/* // */
.transx_content_slide_wrapper {
  /*text-align: left;*/

  letter-spacing: 0;
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 1.7;
  font-weight: 400;
  color: #696e76;
  hyphens: manual;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;
  justify-content: center;
}
.transx_up_heading_overlay {
  hyphens: manual;
  -webkit-tap-highlight-color: transparent;
  text-align: center;
  box-sizing: border-box;
  line-height: 0.85;
  font-weight: 700;
  color: transparent;
  user-select: none;
  /* position: absolute; */
  z-index: 1;
  display: initial;
  font-family: Inter, 'sans-serif';
  font-size: 200px;
  letter-spacing: -0.06em;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.35);
  top: -54px;
  left: 50%;
  transform: translateX(-50%);
}
.transx_content_slider_title {
  text-align: center;
  letter-spacing: -0.03em;
  word-wrap: break-word;
  margin: 0 0 0.5rem;
  font-family: Inter, sans-serif;
  font-weight: 700;
  text-transform: none;
  font-style: normal;
  box-sizing: border-box;
  color: #fff;
  margin-bottom: 20px;
  position: relative;
  z-index: 5;
  font-size: 60px;
  line-height: 60px;
}
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

.transx_heading_widget {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0;
}

.transx_heading_widget .transx_up_heading {
  text-align: center;
}

.transx_promo_box_image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.transx_heading_widget h2 {
  text-align: center;
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 45px;
  text-transform: none;
  font-style: normal;
  color: #414a53;
}
.elementor-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.elementor-column-service {
  flex: 1;
  position: relative;
  padding: 0 8px 0 0;
}

.transx_linked_item_wrapper {
  position: relative;
  overflow: hidden;
}

.transx_img--bg-service {
  width: 367px;
  height: auto;
  object-fit: cover;
}

.transx_overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
}

.transx_linked_item_up_title {
  position: absolute;
  top: 54px;
  left: 26px;
  color: white;
  font-weight: bold;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-size: 12px;
}

.transx_action_block_inner {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  /* width: 300px; */
}

.transx_linked_title {
  text-decoration: none;
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.transx_linked_item_title {
  color: #fff;
  margin-left: 20px;
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100px;
}

.transx_linked_item_counter {
  position: absolute;
  bottom: 2px;
  right: -200px;
  font-size: 40px;
  font-weight: bold;
  color: #005bac;
}

.container-work {
  background-image: url(http://demo.artureanec.com/themes/transx-new/wp-content/uploads/2021/06/background__layout.png);
  background-position: center right !important;
  background-size: cover !important;
  background-color: #e8734e;
  height: 650px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -80px;
}

/* .transx_promo_box_content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
} */

.section {
  padding: 50px;
  text-align: center;
}
.section .heading {
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  text-align: left;
  margin-top: 50px;
  font-weight: 700;
}
.section h2 {
  font-size: 45px;
  text-align: left;
  font-weight: 700;
  color: #fff;
}
.steps-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1500px;
  margin: 0 auto;
  gap: 80px;
}

.step {
  color: #fff;
  margin: 10px;
  max-width: 22%;
  border-radius: 8px;
  text-align: left;
  font-family: 'Inter', sans-serif;
}
.step .number {
  font-size: 100px;
  color: #005bac;
  font-weight: 700;
  font-family: 'Arial', 'sans-serif';
}
.step h5 {
  font-size: 18px;
  color: #fff;
  font-weight: 700;
}
.step ul li {
  font-size: 16px;
}
@media (max-width: 768px) {
  .step {
    flex: 1 1 45%;
    max-width: 45%;
  }
}
@media (max-width: 480px) {
  .step {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
</style>
