var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "v-card",
        { staticClass: "py-1 px-2", attrs: { rounded: "lg" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  attrs: { onSubmit: "return false;" },
                  on: { submit: _vm.onTrackingContainer },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          clearable: "",
                          label: _vm.$t("label-tracking-search"),
                          type: "text",
                          "prepend-inner-icon": "mdi-google-nearby",
                          solo: "",
                          "hide-details": "",
                          "item-text": "name",
                          "item-value": "code",
                          loading: _vm.isLoading,
                          readonly: _vm.isLoading,
                        },
                        on: { "click:clear": _vm.onClearSearch },
                        model: {
                          value: _vm.trackingNo,
                          callback: function ($$v) {
                            _vm.trackingNo = $$v
                          },
                          expression: "trackingNo",
                        },
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "px-10",
                          class: {
                            "mt-2": _vm.$vuetify.breakpoint.mobile,
                          },
                          staticStyle: { "text-transform": "none" },
                          attrs: {
                            large: "",
                            width: _vm.$vuetify.breakpoint.mobile
                              ? "100%"
                              : undefined,
                            disabled: _vm.isLoading,
                            color: "info",
                          },
                          on: { click: _vm.onTrackingContainer },
                        },
                        [
                          _c("v-icon", [_vm._v(" mdi-magnify ")]),
                          _vm._v(" " + _vm._s(_vm.$t("search")) + " "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.$vuetify.breakpoint.mobile
        ? _c(
            "v-scroll-y-transition",
            [
              _vm.render && _vm.containerMovements.logs.length > 0
                ? _c("ContainerTrackingSearchResult", {
                    staticClass: "mt-4 mb-16",
                    attrs: { loading: _vm.isLoading },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.render && _vm.containerMovements.logs.length > 0
        ? _c("ContainerTrackingSearchResultV2", {
            attrs: {
              onTrackingContainer: _vm.onTrackingContainer,
              trackingNo: _vm.trackingNo,
            },
          })
        : _vm._e(),
      _vm.$vuetify.breakpoint.mobile
        ? _c(
            "v-dialog",
            {
              attrs: {
                fullscreen: "",
                transition: "dialog-bottom-transition",
                scrollable: "",
              },
              model: {
                value: _vm.dialog.result,
                callback: function ($$v) {
                  _vm.$set(_vm.dialog, "result", $$v)
                },
                expression: "dialog.result",
              },
            },
            [
              _vm.dialog.result && _vm.containerMovements.logs.length > 0
                ? _c("MobileContainerTrackingSearchResult", {
                    on: {
                      "close-dialog": function ($event) {
                        _vm.dialog.result = false
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }