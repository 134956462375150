<template>
  <div class="">
    <v-card
      class="py-1 px-2"
      rounded="lg"
    >
      <v-card-text>
        <v-form
          @submit="onTrackingContainer"
          onSubmit="return false;"
        >
          <v-row align="center">
            <v-text-field
              v-model="trackingNo"
              clearable
              :label="$t('label-tracking-search')"
              type="text"
              prepend-inner-icon="mdi-google-nearby"
              solo
              hide-details
              item-text="name"
              item-value="code"
              :loading="isLoading"
              :readonly="isLoading"
              @click:clear="onClearSearch"
            />
            <v-btn
              class="px-10"
              large
              :class="{
                'mt-2': $vuetify.breakpoint.mobile,
              }"
              :width="$vuetify.breakpoint.mobile ? '100%' : undefined"
              :disabled="isLoading"
              style="text-transform: none;"
              color="info"
              @click="onTrackingContainer"
            >
              <v-icon> mdi-magnify </v-icon>
              {{ $t('search') }}
            </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-scroll-y-transition v-if="!$vuetify.breakpoint.mobile">
      <ContainerTrackingSearchResult
        v-if="render && containerMovements.logs.length > 0"
        class="mt-4 mb-16"
        :loading="isLoading"
      />
    </v-scroll-y-transition>
    <ContainerTrackingSearchResultV2
      v-if="render && containerMovements.logs.length > 0"
      :onTrackingContainer="onTrackingContainer"
      :trackingNo="trackingNo"
    />
    <v-dialog
      v-if="$vuetify.breakpoint.mobile"
      v-model="dialog.result"
      fullscreen
      transition="dialog-bottom-transition"
      scrollable
    >
      <MobileContainerTrackingSearchResult
        v-if="dialog.result && containerMovements.logs.length > 0"
        @close-dialog="dialog.result = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ContainerTrackingSearchResult from './ContainerTrackingSearchResult.vue';
import MobileContainerTrackingSearchResult from './MobileContainerTrackingSearchResult.vue';
import ContainerTrackingSearchResultV2 from './ContainerTrackingSearchResultV2.vue';

export default {
  components: {
    ContainerTrackingSearchResultV2,
    ContainerTrackingSearchResult,
    MobileContainerTrackingSearchResult,
  },
  data: () => ({
    isLoading: false,
    trackingNo: '',
    dialog: {
      result: false,
    },
    render: false,
  }),
  computed: {
    ...mapState('vsl/containerTracking', ['containerMovements']),
  },
  created() {
    const { tracking_no } = this.$route.query;
    this.trackingNo = tracking_no || '';
    // this.onTrackingContainer();
  },
  destroyed() {
    this.RESET();
  },
  methods: {
    ...mapMutations('vsl/containerTracking', ['RESET']),
    ...mapActions('vsl/containerTracking', ['fetchContainerTrackingInfo']),
    async onTrackingContainer() {
      if (!this.trackingNo || this.trackingNo.length === 0) {
        await this.$router.push('').catch((e) => e);
        return;
      }
      try {
        this.isLoading = true;
        await this.$router
          .push(`?tracking_no=${this.trackingNo}`)
          .catch((e) => e);
        await this.fetchContainerTrackingInfo({
          containerNo: this.trackingNo,
        });
        this.render = false;
        await new Promise((resolve) => setTimeout(resolve, 100));
        this.render = true;
        if (this.$vuetify.breakpoint.mobile) {
          this.dialog.result = true;
        }
      } finally {
        this.isLoading = false;
      }
    },
    async onClearSearch() {
      await this.$router.push('').catch((e) => e);
    },
  },
};
</script>

<style></style>
