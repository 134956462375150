import { api } from '@/api';

export default {
  fetchContactList() {
    return api.get('/v1/user-contacts', {
      headers: { common: { delayed: true } },
    });
  },
  fetchContactDetail({ id }) {
    return api.get(`/v1/user-contacts/${id}`, {
      headers: { common: { delayed: true } },
    });
  },
  createContact(data) {
    return api.post('/v1/user-contacts', data);
  },
  updateContact({ id }, data) {
    return api.patch(`/v1/user-contacts/${id}`, data);
  },
  deleteContact({ id }) {
    return api.delete(`/v1/user-contacts/${id}`);
  },
  setDefaultContact({ id }) {
    return api.patch(`/v1/user-contacts/${id}/set-default`);
  },
};
