var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { display: "flex", "justify-content": "center" } },
    [
      [
        _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "p",
              {
                staticClass: "display-1 ma-0 text-uppercase",
                style: _vm.$vuetify.breakpoint.mobile
                  ? `text-align: center; font-size: 1.5rem!important; width: 100%`
                  : "",
              },
              [_vm._v(" " + _vm._s(_vm.$t("request")) + " ")]
            ),
            _c(
              "div",
              { staticStyle: { display: "flex", "justify-content": "end" } },
              [
                _c("v-pagination", {
                  attrs: { value: 1, length: 2 },
                  model: {
                    value: _vm.page,
                    callback: function ($$v) {
                      _vm.page = $$v
                    },
                    expression: "page",
                  },
                }),
              ],
              1
            ),
            !_vm.isLoading
              ? _vm._l(_vm.requestBookingHistoryList, function (item) {
                  return _c(
                    "router-link",
                    {
                      key: item.id,
                      attrs: {
                        to: {
                          path:
                            "/app/shipping/account/request-booking-history/" +
                            item.id,
                        },
                      },
                    },
                    [
                      _c("RequestBookingHistoryListItem", {
                        attrs: { item: item },
                      }),
                    ],
                    1
                  )
                })
              : _vm._e(),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "end",
                  "margin-top": "40px",
                },
              },
              [
                _c("v-pagination", {
                  attrs: { value: 1, length: 2 },
                  model: {
                    value: _vm.page,
                    callback: function ($$v) {
                      _vm.page = $$v
                    },
                    expression: "page",
                  },
                }),
              ],
              1
            ),
          ],
          2
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }