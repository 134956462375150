<template>
  <v-card
    rounded="lg"
    elevation="0"
  >
    <v-timeline
      align-top
      dense
    >
      <v-timeline-item
        v-for="(log, index) in logs"
        :key="log.step"
        small
        color="none"
      >
        <template v-slot:icon>
          <StatusIndicator
            :status="index === 0 ? 'active' : ''"
            :pulse="index === 0"
          />
        </template>

        <template v-slot:opposite>
          <span>{{ $filters.formatDatetime(log.movementDate) }}</span>
        </template>
        <div class="">
          <div
            v-if="log.status"
            class="text--primary text-uppercase"
            style="font-weight: 600"
          >
            {{ log.status.replace('_', ' ') }}
          </div>
          <div
            class="text--secondary text-uppercase"
            style="font-size: 12px"
          >
            {{ log.depotName || log.terminalName }}
            <span v-if="log.depotPortName || log.terminalPortName"
              >({{ log.depotPortName || log.terminalPortName }})</span
            >
          </div>
          <div
            class="text--secondary caption"
            style="font-size: 10px; line-height: 1.5rem"
          >
            At {{ $filters.formatDatetime(log.movementDate) }}
          </div>
        </div>
      </v-timeline-item>
    </v-timeline>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
  components: {},
  props: ['logs'],
  data: () => ({}),
  computed: {
    ...mapState('vsl/containerTracking', ['containerMovements']),
  },

  created() {},
  methods: {},
};
</script>

<style scoped lang="scss">
:deep {
  .v-timeline--dense:not(.v-timeline--reverse):before {
    left: calc(36px - 1px);
  }

  .v-timeline--dense .v-timeline-item__body {
    max-width: calc(100% - 60px);
  }
  .v-timeline-item__divider {
    min-width: 48px;
  }
}
</style>
