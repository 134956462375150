import { vslApi } from '@/api';

export default {
  fetchVesselScheduleList({
    page = undefined,
    size = undefined,
    search = undefined,
    vessel_code = undefined,
    pol_code = undefined,
    pod_code = undefined,
    from_etd = undefined,
    to_eta = undefined,
  }) {
    return vslApi.get('/v1/vessel-schedules', {
      params: {
        page,
        size,
        search,
        vessel_code,
        pol_code,
        pod_code,
        from_etd,
        to_eta,
      },
      headers: { common: { delayed: true } },
    });
  },
  getVesselSchedule({ code }) {
    return vslApi.get(`/v1/vessel-schedules/${code}`);
  },
  searchVesselSchedule({
    pol_code = undefined,
    pod_code = undefined,
    from_etd = undefined,
    to_eta = undefined,
    vessel_code = undefined,
    voyage_code = undefined,
  }) {
    return vslApi.get('/v1/vessel-schedules/find-path', {
      params: {
        pol_code,
        pod_code,
        from_etd,
        to_eta,
        vessel_code,
        voyage_code,
      },
      headers: { common: { delayed: true } },
    });
  },
};
