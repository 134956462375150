var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pa-0" }, [
    _c(
      "div",
      [
        _c(
          "v-row",
          { attrs: { width: "100%" } },
          [
            _c(
              "v-col",
              {
                class: {
                  "pl-3 pt-11": !_vm.$vuetify.breakpoint.mobile,
                },
                attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 3 },
              },
              [
                _c(
                  "v-card",
                  {
                    staticClass: "py-2 px-1",
                    attrs: { flat: "", tile: "", width: "100%" },
                  },
                  [
                    _c(
                      "v-col",
                      [
                        _c(
                          "h3",
                          {
                            staticClass: "text-center justify-center",
                            staticStyle: { "font-weight": "bold" },
                          },
                          [_vm._v(_vm._s(_vm.$t("included-services")))]
                        ),
                        _c("div", {
                          staticStyle: {
                            gap: "5px",
                            "border-bottom": "1px solid rgb(220, 223, 230)",
                          },
                          attrs: { width: "100%", align: "center" },
                        }),
                        _vm._l(_vm.includedServiceList, function (item) {
                          return _c("v-checkbox", {
                            key: item.code,
                            attrs: {
                              label: _vm.$t(item.label),
                              color: "info",
                              "hide-details": "",
                              disabled: !item.isDefault,
                            },
                            model: {
                              value: _vm.selectedServices[item.code],
                              callback: function ($$v) {
                                _vm.$set(_vm.selectedServices, item.code, $$v)
                              },
                              expression: "selectedServices[item.code]",
                            },
                          })
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-col",
              {
                class: {
                  "pl-3": !_vm.$vuetify.breakpoint.mobile,
                },
                attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 9 },
              },
              [
                _vm._l(_vm.listTransport, function (item) {
                  return _c(
                    "div",
                    { key: item.code, staticClass: "pt-8" },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "py-2 pt-3",
                          attrs: { flat: "", tile: "", width: "100%" },
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "align-center" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "justify-center",
                                  attrs: { cols: 10 },
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "align-center justify-center",
                                    },
                                    [
                                      _c("v-col", {
                                        staticClass: "text-center",
                                        attrs: { cols: 1 },
                                      }),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-center",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass:
                                                "align-center justify-center",
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "text-center",
                                                  attrs: { cols: 7 },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "caption bubble-item",
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mb-2",
                                                          attrs: {
                                                            color: "primary",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " mdi-map-marker-radius "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "caption bubble-item",
                                                      staticStyle: {
                                                        "font-weight": "bold",
                                                      },
                                                    },
                                                    [_vm._v("Hà Nội")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "caption bubble-item",
                                                    },
                                                    [_vm._v("16/09/2024")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "text-center",
                                                  attrs: { cols: 5 },
                                                },
                                                [
                                                  _c("div", {}, [
                                                    _c("div", [
                                                      _c("img", {
                                                        attrs: {
                                                          height: "24px",
                                                          src: "https://vhub.irtech.com.vn/assets/transport_1-f24020ab.png",
                                                        },
                                                      }),
                                                    ]),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-center",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass:
                                                "align-center justify-center",
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "text-center",
                                                  attrs: { cols: 7 },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "caption bubble-item",
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { staticClass: "mb-2" },
                                                        [_vm._v(" mdi-anchor ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "caption bubble-item",
                                                      staticStyle: {
                                                        "font-weight": "bold",
                                                      },
                                                    },
                                                    [_vm._v("Tân Vũ")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "caption bubble-item",
                                                    },
                                                    [_vm._v("16/09/2024")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "text-center",
                                                  attrs: { cols: 5 },
                                                },
                                                [
                                                  _c("div", {}, [
                                                    _c("div", [
                                                      _c("img", {
                                                        attrs: {
                                                          height: "24px",
                                                          src: "https://vhub.irtech.com.vn/assets/transport_2-ed1df925.png",
                                                        },
                                                      }),
                                                    ]),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-center",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass:
                                                "align-center justify-center",
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "text-center",
                                                  attrs: { cols: 7 },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "caption bubble-item",
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { staticClass: "mb-2" },
                                                        [_vm._v(" mdi-anchor ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "caption bubble-item",
                                                      staticStyle: {
                                                        "font-weight": "bold",
                                                      },
                                                    },
                                                    [_vm._v("Sơn Trà")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "caption bubble-item",
                                                    },
                                                    [_vm._v("16/09/2024")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "text-center",
                                                  attrs: { cols: 5 },
                                                },
                                                [
                                                  _c("div", {}, [
                                                    _c("div", [
                                                      _c("img", {
                                                        attrs: {
                                                          height: "24px",
                                                          src: "https://vhub.irtech.com.vn/assets/transport_1-f24020ab.png",
                                                        },
                                                      }),
                                                    ]),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-center",
                                          attrs: { cols: 2 },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass:
                                                "align-center justify-center",
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "text-center",
                                                  attrs: { cols: 11 },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "caption bubble-item",
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mb-2",
                                                          attrs: {
                                                            color: "red",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " mdi-map-marker-radius "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "caption bubble-item",
                                                      staticStyle: {
                                                        "font-weight": "bold",
                                                      },
                                                    },
                                                    [_vm._v("Đà Nẵng")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "caption bubble-item",
                                                    },
                                                    [_vm._v("16/09/2024")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { cols: 2 },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "border-left":
                                          "1px solid rgb(220, 223, 230)",
                                      },
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "px-20 mt-2 mr-3 multiline-button",
                                          staticStyle: {
                                            "line-height": "0.8",
                                            "border-radius": "3px",
                                            width: "115px",
                                            "background-color": "#409eff",
                                            color: "white",
                                            "margin-left": "4px",
                                          },
                                          on: { click: _vm.onClick },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "mt-4 mb-4" },
                                            [
                                              _c(
                                                "h3",
                                                {
                                                  staticClass:
                                                    "mb-2 text--lighten-1",
                                                  staticStyle: {
                                                    color: "white",
                                                    "font-weight": "bold",
                                                  },
                                                },
                                                [_vm._v("$100")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("book-now")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "px-20 mt-2 mr-3 multiline-button gray-hover",
                                          staticStyle: {
                                            "line-height": "0.8",
                                            "border-radius": "3px",
                                            width: "115px",
                                            color: "#606266",
                                            "margin-left": "4px",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleDetail(item.code)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "mt-4 mb-4" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("view-details")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.showDetail === item.code
                            ? _c("ViewDetailBooking")
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                _c(
                  "div",
                  { staticClass: "mt-2 mb-2", attrs: { align: "center" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mb-2",
                        staticStyle: { "text-transform": "none" },
                        attrs: {
                          width: "26%",
                          large: "",
                          color: "info",
                          depressed: "",
                        },
                        on: { click: _vm.onSend },
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.$t("request-quote")) + " "),
                        _c("v-icon", { attrs: { right: "" } }, [
                          _vm._v(" mdi-arrow-top-right"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }