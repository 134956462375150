var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("a-modal", {
        attrs: {
          title: "Popup Chat",
          width: "750px",
          footer: null,
          "mask-closable": false,
          mask: false,
        },
        on: { cancel: _vm.closeChat },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c(
                    "div",
                    { staticClass: "list" },
                    [
                      _c(
                        "a-menu",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { mode: "inline", "open-keys": _vm.openKeys },
                        },
                        [
                          _c(
                            "a-sub-menu",
                            { key: "sub1" },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "title" }, slot: "title" },
                                [_c("span", [_vm._v("Navigation One")])]
                              ),
                              _c("a-menu-item", { key: "1" }, [
                                _vm._v(" Option 1 "),
                              ]),
                              _c("a-menu-item", { key: "2" }, [
                                _vm._v(" Option 2 "),
                              ]),
                              _c("a-menu-item", { key: "3" }, [
                                _vm._v(" Option 3 "),
                              ]),
                              _c("a-menu-item", { key: "4" }, [
                                _vm._v(" Option 4 "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "a-sub-menu",
                            { key: "sub1" },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "title" }, slot: "title" },
                                [_c("span", [_vm._v("Navigation Two")])]
                              ),
                              _c("a-menu-item", { key: "5" }, [
                                _vm._v(" Option 5 "),
                              ]),
                              _c("a-menu-item", { key: "6" }, [
                                _vm._v(" Option 6 "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "a-sub-menu",
                            { key: "sub1" },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "title" }, slot: "title" },
                                [_c("span", [_vm._v("Navigation Two")])]
                              ),
                              _c("a-menu-item", { key: "5" }, [
                                _vm._v(" Option 5 "),
                              ]),
                              _c("a-menu-item", { key: "6" }, [
                                _vm._v(" Option 6 "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticStyle: { width: "100%" } }, [
                    _c("div", { staticClass: "chat-content" }, [
                      _c(
                        "div",
                        _vm._l(_vm.messages, function (message) {
                          return _c(
                            "div",
                            {
                              key: message.createdAt,
                              staticStyle: {
                                display: "inline-block",
                                width: "100%",
                                margin: "5px 0",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  class: {
                                    "align-left":
                                      message.userCreate === "user2",
                                    "align-right":
                                      message.userCreate === "user1",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "rgb(255, 252, 252)",
                                        "background-color": "rgb(62, 165, 224)",
                                        margin: "0px 4px",
                                        padding: "5px",
                                        "border-radius": "10px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(message.content) + " "
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                          },
                                        },
                                        [_vm._v(_vm._s(message.createdAt))]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "input-container",
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "margin-top": "3px",
                        },
                      },
                      [
                        _c("a-input", {
                          staticStyle: { "margin-right": "10px" },
                          attrs: { placeholder: "Nhập tin nhắn" },
                          model: {
                            value: _vm.message,
                            callback: function ($$v) {
                              _vm.message = $$v
                            },
                            expression: "message",
                          },
                        }),
                        _c(
                          "a-button",
                          {
                            staticStyle: {
                              border: "none",
                              display: "flex",
                              "align-items": "center",
                              "text-align": "center",
                            },
                            attrs: { ghost: "" },
                            on: { click: _vm.sendMessage },
                          },
                          [
                            _c("span", {
                              staticClass: "mdi mdi-send",
                              staticStyle: {
                                "font-size": "30px",
                                color: "dodgerblue",
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.modalOpen,
          callback: function ($$v) {
            _vm.modalOpen = $$v
          },
          expression: "modalOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }