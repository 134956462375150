import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import containerReleaseOrderV2Api from '@/api/vsl/container-release-order-v2.api';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Ho_Chi_Minh');

const initialState = () => ({
  myContainerReleaseOrderList: [],
  myContainerReleaseOrderListQueryParams: {
    search: null,
    page: null,
    size: null,
  },
  myContainerReleaseOrderListTotal: 0,
  myContainerReleaseOrderListTotalPage: 0,
  myContainerReleaseOrderDetail: {
    id: null,
    no: null,
    searchingType: null,
    availableFiles: [],
  },
});

const state = initialState();
const getters = {};
const mutations = {
  RESET(state, payload) {
    const initStates = initialState();
    if (!payload) {
      Object.keys(initStates).map((key) => {
        state[key] = initStates[key];
      });
    } else {
      const statesToReset = payload;
      statesToReset.map((key) => {
        state[key] = initStates[key];
      });
    }
  },
  SET_MY_CONTAINER_RELEASE_ORDER_LIST(state, payload) {
    state.myContainerReleaseOrderList = payload;
  },
  SET_MY_CONTAINER_RELEASE_ORDER_LIST_QUERY_PARAMS(state, payload) {
    state.myContainerReleaseOrderListQueryParams = {
      ...state.myContainerReleaseOrderListQueryParams,
      ...payload,
    };
  },
  SET_MY_CONTAINER_RELEASE_ORDER_LIST_TOTAL(state, payload) {
    state.myContainerReleaseOrderListTotal = payload;
  },
  SET_MY_CONTAINER_RELEASE_ORDER_LIST_TOTAL_PAGE(state, payload) {
    state.myContainerReleaseOrderListTotalPage = payload;
  },
};
const actions = {
  async fetchMyContainerReleaseOrderList({ state, commit }, payload) {
    console.log('[LOG] : state', state);
    const res =
      await containerReleaseOrderV2Api.fetchMyContainerReleaseOrderList({
        search: state.myContainerReleaseOrderListQueryParams.search,
        page: state.myContainerReleaseOrderListQueryParams.page,
        size: state.myContainerReleaseOrderListQueryParams.size,
      });
    const mapped = res.data.data.map((item) => {
      const mappedItem = {
        id: item.id,
        shipperName: item.shipper?.name,
        expiryDate: item.expiry_date && dayjs.tz(item.expiry_date).toDate(),
        releaseNo: item.release_no,
        releaseDate: item.release_date && dayjs.tz(item.release_date).toDate(),
        pickupDate: item.pickup_date && dayjs.tz(item.pickup_date).toDate(),
        depotTerminalCode: item.depot_terminal_code,
        depotTerminalName: item.depot_terminal_name,
        bookingNo: item.booking_no,
        remark: item.remark,
        status: item.status,
      };
      return mappedItem;
    });
    const size = state.myContainerReleaseOrderListQueryParams.size || 10;
    const page = res.data.total === 0 ? 1 : Math.ceil(res.data.total / size);
    commit('SET_MY_CONTAINER_RELEASE_ORDER_LIST', mapped);
    commit('SET_MY_CONTAINER_RELEASE_ORDER_LIST_TOTAL', res.data.total);
    commit('SET_MY_CONTAINER_RELEASE_ORDER_LIST_TOTAL_PAGE', page);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
