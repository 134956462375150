const initialState = () => ({
  sw: {
    install: null,
    update: null,
  },
});

const state = initialState();
const getters = {};
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  SET_SW(state, payload) {
    state.sw = payload;
  },
};
const actions = {
  init: ({ commit, state, dispatch }) => {
    document.addEventListener('swupdatefound', (e) => {
      console.log('[sw:vuetify] A new update found...', e);
      commit('SET_SW', { ...state.sw, update: e.detail });
      dispatch('ui/toggleUpdatePrompt', true, { root: true });
    });
  },
  update({ commit, state, dispatch }) {
    console.log(`[sw:vuetify] Updating documentation content...`);
    state.sw.update.waiting.postMessage('sw:update');
    // dispatch('ui/toggleUpdatePrompt', false, { root: true });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
