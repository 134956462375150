<template>
  <main>
    <!-- Nav Bar Start -->
    <NavBarHome active="services" />
    <!-- Banner -->
    <div
      id="carousel-2"
      class="carousel slide carousel-fade carousel-slide"
      data-ride="carousel"
      data-interval="6000"
    >
      <div
        class="carousel-inner"
        role="listbox"
      >
        <div class="carousel-item active">
          <img
            src="../../../libhome/img/vimc accelerating-digital-transformation.png"
            alt="responsive image"
            class="d-block img-fluid"
          />

          <div class="carousel-caption">
            <div>
              <div class="transx_content_slide_wrapper">
                <div class="transx_content_container">
                  <div class="transx_content_wrapper_1">
                    <div
                      class="transx_up_heading_overlay transx_overlay_align_center"
                      id="carousel-item-caption"
                    >
                      VIMC&nbsp;LINES
                    </div>
                    <h2 class="transx_content_slider_title">
                      {{ $t('Quote') }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="quote-page"
      id="quote-page"
    >
      <div class="container container-quote">
        <v-row>
          <!-- Hàng 1: Base Date -->
          <v-col cols="6">
            <label class="required-label margin-label">Base date</label>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="baseDateFormatted"
                  placeholder="Select date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  class="date-picker-input specific-date-picker"
                />
              </template>
              <v-date-picker
                v-model="baseDate"
                @change="saveAndClose"
                no-title
              />
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <!-- Hàng 2: POL, POD, Container Type -->
          <v-col cols="6">
            <label class="label">POL</label>
            <v-select
              v-model="pol"
              :items="polOptions"
              placeholder="Select POL"
              outlined
            />
          </v-col>
          <v-col cols="6">
            <label class="label">POD</label>
            <v-select
              v-model="pod"
              :items="podOptions"
              placeholder="Select POD"
              outlined
            />
          </v-col>
        </v-row>

        <v-row>
          <!-- Hàng 3: Charge Type, Container Type  -->
          <v-col cols="6">
            <label class="required-label">Charge type</label>
            <v-text-field
              v-model="chargeType"
              placeholder="Search charge type"
              outlined
            />
          </v-col>
          <v-col cols="6">
            <label class="label">Container type</label>
            <v-select
              v-model="containerType"
              placeholder="Select container type"
              outlined
              :items="containerTypeOptions"
            />
          </v-col>
        </v-row>

        <v-row>
          <!-- Hàng 4: Tariff, Commodity -->
          <v-col cols="6">
            <label class="label">Tariff</label>
            <v-text-field
              v-model="tariff"
              placeholder="Search tariff"
              outlined
            />
          </v-col>
          <v-col cols="6">
            <label class="label">Commodity</label>
            <v-text-field
              v-model="commodity"
              placeholder="Search commodity"
              outlined
            />
          </v-col>
        </v-row>

        <!-- Hàng 5: Find và Clear -->
        <v-row>
          <v-col
            cols="12"
            class="text-right mb-2"
          >
            <v-btn
              @click="searchData"
              class="search-button"
            >
              Search
            </v-btn>
            <v-btn
              @click="clearData"
              class="clear-button"
            >
              Clear
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>

    <FooterHome />

    <!-- Back to top button -->
    <a
      href="/home"
      class="back-to-top"
      ><i class="fa fa-chevron-up"></i
    ></a>

    <NavBarLeftHome />
  </main>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapState } from 'vuex';
import VesselScheduleSearch from '@/components/shared/local/vsl/VesselScheduleSearch';
import ContainerTrackingSearch from '@/components/shared/local/vsl/ContainerTrackingSearch';
import NavBarHome from '@/views/Home/PageChild/NavBarHome.vue';
import FooterHome from '@/views/Home/PageChild/FooterHome.vue';
import NavBarLeftHome from '@/views/Home/PageChild/NavBarLeftHome.vue';
import { nextTick } from 'vue';
export default {
  components: {
    VesselScheduleSearch,
    ContainerTrackingSearch,
    NavBarHome,
    NavBarLeftHome,
    FooterHome,
  },
  data() {
    return {
      menu: false,
      baseDateFormatted: '',
      baseDate: null,
      pol: null,
      pod: null,
      containerType: null,
      chargeType: '',
      tariff: '',
      commodity: '',
      polOptions: ['No data'], // Thay thế bằng dữ liệu thực tế
      podOptions: ['No data'],
      containerTypeOptions: ['No data'],
      allowedDates: (val) => true, // Hoặc custom logic cho allowed dates
    };
  },
  watch: {
    baseDate(val) {
      this.baseDateFormatted = this.formatDate(val); // Cập nhật định dạng khi baseDate thay đổi
    },
  },
  methods: {
    saveAndClose(val) {
      this.baseDate = val; // Cập nhật giá trị baseDate
      this.menu = false; // Đóng menu
    },
    formatDate(date) {
      if (!date) return '';
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    findData() {
      // Xử lý tìm kiếm dữ liệu tại đây
      console.log('Find data:', {
        baseDate: this.baseDate,
        pol: this.pol,
        pod: this.pod,
        containerType: this.containerType,
        chargeType: this.chargeType,
        tariff: this.tariff,
        commodity: this.commodity,
      });
    },
    searchData() {
      this.$message.error('No data found');
    },
    clearData() {
      // Xóa tất cả các dữ liệu
      this.baseDate = null;
      this.baseDateFormatted = '';
      this.pol = null;
      this.pod = null;
      this.containerType = null;
      this.chargeType = '';
      this.tariff = '';
      this.commodity = '';
    },
  },
  computed: {},
  created() {},
  mounted() {
    this.$nextTick(() => {
      const scrollToElement = (element, offset = 0) => {
        const start = window.pageYOffset;
        const end =
          element.getBoundingClientRect().top + window.pageYOffset - offset;
        const distance = end - start;
        const duration = 3000; // Thời gian cuộn (ms)
        let startTime = null;

        const scroll = (currentTime) => {
          if (startTime === null) startTime = currentTime;
          const timeElapsed = currentTime - startTime;
          const progress = Math.min(timeElapsed / duration, 1);
          const easing =
            progress < 0.5
              ? 4 * progress * progress * progress
              : 1 - Math.pow(-2 * progress + 2, 3) / 2;
          window.scrollTo(0, start + distance * easing);
          if (timeElapsed < duration) requestAnimationFrame(scroll);
        };

        requestAnimationFrame(scroll);
      };

      const trackingElement = document.getElementById('carousel-item-caption');
      if (trackingElement) {
        const headerHeight = document.querySelector('.navbar').offsetHeight;
        scrollToElement(trackingElement, headerHeight);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .v-btn::before {
    background-color: transparent;
  }
  .v-tabs-items {
    background-color: transparent !important;
  }
}

html,
body {
  height: 100%;
  margin: 0;
  scroll-behavior: smooth;
}

main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f2f2f2 !important;
}

.carousel-slide {
  margin-bottom: 30px !important;
}

#quote-page {
  display: block; /* Đảm bảo phần tử không bị ẩn */
  visibility: visible; /* Đảm bảo phần tử có thể nhìn thấy */
  margin-bottom: 70px;
  margin-top: 40px;
  background: #f2f2f2;
}

.container-quote {
  max-width: 1290px !important;
  padding: 14px 0 14px 23px;
  background: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(94, 86, 105, 0.1);
  border-radius: 8px;
}
.required-label,
.label {
  display: block;
  margin-bottom: 4px;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}

.required-label::before {
  content: '*';
  color: red;
  margin-right: 4px;
}

.margin-label {
  margin-top: 15px;
}
/* CSS cho ô nhập liệu date-picker */
.date-picker-input {
  position: relative !important;
}

:deep(.date-picker-input .v-input__icon--prepend) {
  position: absolute;
  left: 15px; /* Điều chỉnh khoảng cách từ bên phải */
  top: 45%;
  transform: translateY(-50%); /* Căn giữa theo chiều dọc */
}

:deep(.v-input__prepend-outer) {
  margin: 0 !important;
}

:deep(.col-6) {
  padding: 0 12px 0 12px !important;
}

:deep(.v-input) {
  max-height: 65px !important;
}

:deep(.specific-date-picker .v-input__control .v-input__slot input) {
  padding: 8px 0px 8px 35px !important;
}

:deep(.v-text-field.v-text-field--enclosed .v-text-field__details) {
  margin: 0 !important;
}

/* CSS cho các nút Find và Clear */
.search-button,
.clear-button {
  margin-left: 10px;
  background-color: #e8724a !important;
  color: #fff;
  font-size: 16px;
  text-transform: none; /* Ngăn việc chuyển đổi chữ cái thành in hoa */
}

footer {
  padding: 20px 0; /* Điều chỉnh padding nếu cần */
}
</style>
