<template>
  <v-card
    class="bg-get-started"
    rounded="xl"
  >
    <v-card-text
      class="text-center white--text"
      :class="{
        'pa-16': !$vuetify.breakpoint.mobile,
        'py-16': $vuetify.breakpoint.mobile,
      }"
    >
      <p
        class="text-h4"
        style="font-weight: 600; opacity: 0.87"
      >
        Combine everything in one place
      </p>
      <p
        class="font-weight-medium"
        :class="{
          'px-8': !$vuetify.breakpoint.mobile,
        }"
        style="font-size: 16px"
      >
        Search from more than 500,000 ocean freight quotes and get the latest
        international shipping rates from any carrier in seconds. With us you
        get a launching pad for your supply chain, and with it the
        effectiveness, control and transparency of your business
      </p>
      <v-btn
        width="250px"
        class="info--text font-weight-bold"
        >Get started</v-btn
      >
    </v-card-text>
  </v-card>
</template>

<script>
export default {};
</script>

<style scoped>
.bg-get-started {
  background: linear-gradient(129.86deg, #08f -2.99%, #001b7b 201.26%);
}
</style>
