import UserContactApi from '@/api/base/user-contact.api';

const initialState = () => ({
  contactDetail: {
    id: null,
    title: '',
    name: '',
    phone: '',
    address: '',
    email1: '',
    email2: '',
    isDefault: false,
  },
  contactList: [],
});

const state = initialState();
const getters = {};
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  SET_CONTACT_DETAIL(state, payload) {
    state.contactDetail = payload;
  },
  SET_CONTACT_LIST(state, payload) {
    state.contactList = payload;
  },
};
const actions = {
  async fetchContactList({ commit }, payload) {
    const res = await UserContactApi.fetchContactList();
    const mapped = res.data.data.map((item) => {
      const mappedItem = {
        id: item.id,
        title: item.title,
        name: item.name,
        phone: item.phone,
        address: item.address,
        emails: item.emails.map(({ email }) => email),
        isDefault: item.is_default,
        provinceCode: item.province?.code,
        districtCode: item.district?.code,
      };
      return mappedItem;
    });
    commit('SET_CONTACT_LIST', mapped);
  },
  async fetchContactDetail({ commit }, payload) {
    const { id } = payload;
    const res = await UserContactApi.fetchContactDetail({ id });
    const item = res.data.data;
    const mappedItem = {
      id: item.id,
      title: item.title,
      name: item.name,
      phone: item.phone,
      address: item.address,
      emails: item.emails.map(({ id, email }) => ({ id, email })),
      isDefault: item.is_default,
      provinceCode: item.province?.code,
      districtCode: item.district?.code,
    };
    commit('SET_CONTACT_DETAIL', mappedItem);
  },
  async createContact({ commit }, payload) {
    const {
      title,
      name,
      phone,
      address,
      emails,
      isDefault,
      provinceCode,
      districtCode,
    } = payload;
    await UserContactApi.createContact({
      title,
      name,
      phone,
      address,
      emails: emails.map(({ email }) => email),
      is_default: isDefault,
      province_code: provinceCode,
      district_code: districtCode,
    });
  },
  async updateContact({ commit }, payload) {
    const {
      id,
      title,
      name,
      phone,
      address,
      emails,
      provinceCode,
      districtCode,
    } = payload;
    await UserContactApi.updateContact(
      { id },
      {
        title,
        name,
        phone,
        address,
        emails: emails.map(({ email }) => email),
        province_code: provinceCode,
        district_code: districtCode,
      }
    );
  },
  async deleteContact({ commit }, payload) {
    const { id } = payload;
    await UserContactApi.deleteContact({ id });
  },
  async setDefaultContact({ commit }, payload) {
    const { id } = payload;
    await UserContactApi.setDefaultContact({ id });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
