<template>
  <div style="display: flex; justify-content: center">
    <template>
      <div style="width: 100%">
        <div style="display: flex; justify-content: start">
          <span style="text-transform: uppercase; font-size: 35px"
            >Bookings</span
          >
        </div>
        <div style="display: flex; justify-content: end">
          <v-pagination
            v-model="page"
            :value="1"
            :length="2"
          />
        </div>
        <div style="margin: 20px 0">
          <a-card
            width="100%"
            style="
              margin: 20px 0;
              box-shadow: 0 25px 25px -12px rgb(0 0 0 / 0.25);
              border-radius: 20px;
            "
            @click="onClickBookingDetail"
          >
            <template #title>
              <div style="display: flex; justify-content: space-between">
                <div style="display: flex; align-items: center">
                  <span>
                    <img
                      style="height: 20px"
                      src="/assets/img/location-start.png"
                    />
                    Hà Nội
                  </span>
                  <hr style="width: 20px; margin: 0 5px" />
                  <span>
                    <img
                      style="height: 20px"
                      src="/assets/img/location-end.png"
                    />
                    Hồ Chí Minh
                  </span>
                </div>
                <div>
                  <span style="margin-right: 10px">20/3/2024</span>
                  <v-btn
                    style="
                      color: #aec76d;
                      background-color: #f0f9eb;
                      text-transform: none;
                    "
                  >
                    Done
                  </v-btn>
                </div>
              </div>
            </template>
            <template>
              <div style="display: flex">
                <div style="width: 20%">
                  <div>#OD00051</div>
                  <div style="font-weight: 900">40HC</div>
                </div>
                <div style="width: 70%">
                  <div>
                    <span> Commodity </span
                    ><span style="margin-left: 5px; font-weight: 900"
                      >Thực phẩm</span
                    >
                  </div>
                  <div>
                    <span>Delivery date</span
                    ><span style="margin-left: 5px; font-weight: 900"
                      >18/03/2024</span
                    >
                  </div>
                </div>
                <div
                  style="
                    width: 10%;
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    color: #2e90fa;
                    font-size: 23px;
                    font-weight: 600;
                  "
                >
                  $100
                </div>
              </div>
            </template>
          </a-card>
        </div>
        <div style="display: flex; justify-content: end; margin-top: 40px">
          <v-pagination
            v-model="page"
            :value="1"
            :length="2"
          />
        </div>
      </div>
    </template>
  </div>
</template>
  
  <script>
export default {
  methods: {
    async onClickBookingDetail() {
      await this.$router.push('bookings/1').catch((e) => e);
    },
  },
};
</script>
  
  <style>
/* CSS styles */
</style>
  