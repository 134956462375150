import debitV2Api from '@/api/vsl/debit-v2.api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import debitApi from '@/api/vsl/debit.api';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Ho_Chi_Minh');

const initialState = () => ({
  myDebitList: [],
  myDebitListQueryParams: {
    search: null,
    page: null,
    size: null,
  },
  myDebitListTotal: 0,
  myDebitListTotalPage: 0,
});

const state = initialState();
const getters = {};
const mutations = {
  RESET(state, payload) {
    const initStates = initialState();
    if (!payload) {
      Object.keys(initStates).map((key) => {
        state[key] = initStates[key];
      });
    } else {
      const statesToReset = payload;
      statesToReset.map((key) => {
        state[key] = initStates[key];
      });
    }
  },
  SET_MY_DEBIT_LIST(state, payload) {
    state.myDebitList = payload;
  },
  SET_MY_DEBIT_LIST_QUERY_PARAMS(state, payload) {
    state.myDebitListQueryParams = {
      ...state.myDebitListQueryParams,
      ...payload,
    };
  },
  SET_MY_DEBIT_LIST_TOTAL(state, payload) {
    state.myDebitListTotal = payload;
  },
  SET_MY_DEBIT_LIST_TOTAL_PAGE(state, payload) {
    state.myDebitListTotalPage = payload;
  },
};
const actions = {
  async fetchMyDebitList({ state, commit }, payload) {
    console.log('[LOG] : state', state);
    const res = await debitV2Api.fetchMyDebitList({
      search: state.myDebitListQueryParams.search,
      page: state.myDebitListQueryParams.page,
      size: state.myDebitListQueryParams.size,
    });
    const mapped = res.data.data.map((item) => {
      const mappedItem = {
        id: item.id,
        debitNo: item.invoice_no,
        debitDate: dayjs.tz(item?.invoice?.invoice_date).toDate(),
        paymentType: item.payment_type,
        bookingNo: item?.invoice?.booking_no,
        voyageCode: item?.debit?.voyage_code,
        polCode: item?.debit?.pol_code,
        podCode: item?.debit?.pod_code,
        shipperCode: item.shipper_code,
        consigneeCode: item.consignee_code,
      };
      return mappedItem;
    });
    const size = state.myDebitListQueryParams.size || 10;
    const page = res.data.total === 0 ? 1 : Math.ceil(res.data.total / size);
    commit('SET_MY_DEBIT_LIST', mapped);
    commit('SET_MY_DEBIT_LIST_TOTAL', res.data.total);
    commit('SET_MY_DEBIT_LIST_TOTAL_PAGE', page);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
