import UploadApi from '@/api/vsl/upload.api';

const initialState = () => ({
  uploadedFile: {
    fileName: '',
    displayName: '',
    mimetype: null,
    size: 0,
  },
  uploadedFileList: [],
});

const state = initialState();
const getters = {};
const mutations = {
  RESET(state, payload) {
    const initStates = initialState();
    if (!payload) {
      Object.keys(initStates).map((key) => {
        state[key] = initStates[key];
      });
    } else {
      const statesToReset = payload;
      statesToReset.map((key) => {
        state[key] = initStates[key];
      });
    }
  },
  SET_UPLOADED_FILE(state, payload) {
    state.uploadedFile = payload;
  },
  SET_UPLOADED_FILE_LIST(state, payload) {
    state.uploadedFileList = payload;
  },
};
const actions = {
  async uploadFile({ commit }, payload) {
    const { formDataList } = payload;

    const toUploads = formDataList.map((formData) =>
      UploadApi.upload(formData)
    );
    const responses = await Promise.all(toUploads);

    const mapped = responses.map((res) => {
      const {
        file_name: fileName,
        display_name: displayName,
        mimetype,
        size,
      } = res.data.data;
      return {
        fileName,
        displayName,
        mimetype,
        size,
        link: `${process.env.VUE_APP_VSL_API}/v1/upload/${fileName}`,
      };
    });

    commit('SET_UPLOADED_FILE_LIST', mapped);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
