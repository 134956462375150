<template>
  <gmap-map
    :center="center"
    :zoom="5"
    style="width: 100%; height: 500px"
  >
    <!--tạo marker cho mỗi địa điểm -->
    <gmap-marker
      :key="'marker-' + index"
      v-for="(place, index) in places"
      :position="{ lat: place.latitude, lng: place.longitude }"
      :title="place.title"
      :clickable="true"
      :draggable="true"
      @click="center = { lat: place.latitude, lng: place.longitude }"
    ></gmap-marker>

    <!-- Polyline để nối các điểm với nhau -->
    <gmap-polyline
      :path="path"
      :options="polylineOptions"
    ></gmap-polyline>
  </gmap-map>
</template>

<script>
export default {
  props: {
    places: Array,
  },
  data() {
    return {
      center: {
        lat: this.places.length > 0 ? this.places[0].latitude : 0,
        lng: this.places.length > 0 ? this.places[0].longitude : 0,
      },
      polylineOptions: {
        strokeColor: '#FF0000', // Màu sắc của đường đi
        strokeOpacity: 1.0, // Độ trong suốt của đường đi
        strokeWeight: 2, // Độ rộng của đường đi
      },
    };
  },
  computed: {
    path() {
      return this.places.map((place) => ({
        lat: place.latitude,
        lng: place.longitude,
      }));
    },
  },
};
</script>
