import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import vslStoreModules from './vsl';
import vplStoreModules from './vpl';

import UIStore from './base/ui.store';
import AuthStore from './base/auth.store';
import UserStore from './base/user.store';
import PwaStore from './base/pwa.store';
import UserContactStore from './base/user-contact.store';
import UploadStore from './base/upload.store';
import ProvinceStore from './base/province.store';

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    vsl: vslStoreModules,
    vpl: vplStoreModules,
    ui: UIStore,
    auth: AuthStore,
    user: UserStore,
    pwa: PwaStore,
    userContact: UserContactStore,
    upload: UploadStore,
    province: ProvinceStore,
  },
});
