var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        padding: "30px",
        "margin-top": "20px",
        display: "flex",
        "justify-content": "center",
      },
    },
    [
      [
        _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "div",
              [
                _c("a-button", { on: { click: _vm.goBack } }, [
                  _c("span", { staticClass: "mdi mdi-arrow-left" }),
                  _c("span", [_vm._v("Back")]),
                ]),
              ],
              1
            ),
            _c("RequestBookingHistoryListItem"),
            _c("RequestBookingHistoryPartner"),
            _c("RequestBookingHistoryProcess"),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }