<template>
  <div>
    <div>
      <a-button @click="handleClickBack"
        ><span class="mdi mdi-arrow-left"></span><span>Back</span></a-button
      >
    </div>
    <div style="margin-bottom: 40px">
      <a-card
        width="100%"
        style="
          margin: 20px 0;
          box-shadow: 0 25px 25px -12px rgb(0 0 0 / 0.25);
          border-radius: 20px;
        "
      >
        <template #title>
          <div style="display: flex; justify-content: space-between">
            <div style="display: flex; align-items: center">
              <span>
                <img
                  style="height: 20px"
                  src="/assets/img/location-start.png"
                />
                Hà Nội
              </span>
              <hr style="width: 20px; margin: 0 5px" />
              <span>
                <img
                  style="height: 20px"
                  src="/assets/img/location-end.png"
                />
                Hồ Chí Minh
              </span>
            </div>
            <div>
              <span style="margin-right: 10px">20/3/2024</span>
              <v-btn
                style="
                  color: #aec76d;
                  background-color: #f0f9eb;
                  text-transform: none;
                "
              >
                Done
              </v-btn>
            </div>
          </div>
        </template>
        <template>
          <div style="display: flex">
            <div style="width: 20%">
              <div>#OD00051</div>
              <div style="font-weight: 900">40HC</div>
            </div>
            <div style="width: 70%">
              <div>
                <span> Commodity </span
                ><span style="margin-left: 5px; font-weight: 900"
                  >Thực phẩm</span
                >
              </div>
              <div>
                <span>Delivery date</span
                ><span style="margin-left: 5px; font-weight: 900"
                  >18/03/2024</span
                >
              </div>
            </div>
            <div
              style="
                width: 10%;
                display: flex;
                align-items: center;
                justify-content: end;
                color: #2e90fa;
                font-size: 23px;
                font-weight: 600;
              "
            >
              $100
            </div>
          </div>
        </template>
      </a-card>
    </div>
    <div
      style="
        background-color: #fff;
        padding: 20px;
        border-radius: 20px;
        box-shadow: 0 25px 25px -12px rgb(0 0 0 / 0.25);
      "
    >
      <a-tabs
        default-active-key="1"
        @change="callback"
      >
        <a-tab-pane
          key="1"
          tab="Shipment information"
        >
          <template>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <span>
                <div style="display: flex; justify-content: center">
                  <img
                    style="height: 20px"
                    src="/assets/img/location-start.png"
                  />
                </div>
                <h3>Hà Nội</h3>
              </span>
              <img
                style="height: 40px; margin: 0 20px"
                src="/assets/img/land-transportation.png"
              />
              <span>
                <div style="display: flex; justify-content: center">
                  <img
                    style="height: 20px"
                    src="/assets/img/location-end.png"
                  />
                </div>
                <h3>Hồ Chí Minh</h3>
              </span>
            </div>
            <div>
              <div>
                <h3
                  style="
                    background-color: #eaecf0;
                    padding: 10px;
                    font-size: 18px;
                    font-weight: 900;
                  "
                >
                  Vận chuyển từ kho hàng tới Cảng
                </h3>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    padding: 0 20px;
                  "
                >
                  <div style="text-transform: uppercase">cma cgm</div>
                  <div style="display: flex; align-items: center">
                    <a-button>
                      <img
                        style="height: 20px"
                        src="/assets/img/message-box.png"
                      />
                    </a-button>
                    <div style="margin: 0 20px; font-weight: 900; color: #000">
                      $100
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </a-tab-pane>
        <!-- Tabs 2 -->
        <a-tab-pane
          key="2"
          tab="Shipment details"
          force-render
        >
          <div style="border-bottom: 1px solid #ccc; padding: 20px 0">
            <a-steps>
              <a-step
                status="finish"
                title="Đã xác nhận"
                class="custom-step"
              >
                <a-icon
                  slot="icon"
                  type="file-text"
                />
              </a-step>
              <a-step
                status="wait"
                title="Đã lấy hàng tại kho"
              >
                <a-icon
                  slot="icon"
                  type="file-text"
                />
              </a-step>
              <a-step
                status="wait"
                title="Đã vận chuyển tới Cảng đi"
              >
                <a-icon
                  slot="icon"
                  type="file-text"
                />
              </a-step>
              <a-step
                status="wait"
                title="Đã vận chuyển tới Cảng đến"
              >
                <a-icon
                  slot="icon"
                  type="file-text"
                />
              </a-step>
              <a-step
                status="wait"
                title="Đã vận chuyển tới Khách hàng"
              >
                <a-icon
                  slot="icon"
                  type="file-text"
                />
              </a-step>
            </a-steps>
          </div>
          <div style="padding: 20px">
            <div style="font-weight: 600; font-size: 18px">Processing</div>
            <div style="margin: 20px">
              <a-steps
                progress-dot
                :current="1"
                direction="vertical"
                class="custom-step-vertical"
              >
                <a-step
                  title="Khách hàng đã đặt hàng"
                  description="20/03/2024 12:00:00"
                />
              </a-steps>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    callback(key) {
      console.log(key);
    },
    async handleClickBack() {
      await this.$router.go(-1);
    },
  },
};
</script>

<style>
.custom-step .ant-steps-item-title {
  color: green !important;
  font-size: 14px !important;
}
.custom-step .ant-steps-icon {
  color: green !important;
}
.custom-step-vertical .ant-steps-item-content {
  width: 50% !important;
}
.custom-step-vertical .ant-steps-icon {
  color: green !important;
}
</style>