<template>
  <a-card
    width="100%"
    style="
      margin: 20px 0;
      box-shadow: 0 25px 25px -12px rgb(0 0 0 / 0.25);
      border-radius: 20px;
    "
  >
    <template #title>
      <div class="partners-title">
        <p
          class="display-1 ma-0"
          :style="
            $vuetify.breakpoint.mobile
              ? `text-align: center; font-size: 1.5rem!important; width: 100%`
              : ''
          "
        >
          {{ $t('title-partners') }}
        </p>
      </div>
      <RequestBookingHistoryPartnerItem />
      <RequestBookingHistoryPartnerItem />
      <RequestBookingHistoryPartnerItem />
      <RequestBookingHistoryPartnerItem />
    </template>
  </a-card>
</template>

<script>
import RequestBookingHistoryPartnerItem from './RequestBookingHistoryPartnerItem.vue';
export default {
  components: {
    RequestBookingHistoryPartnerItem,
  },
};
</script>

<style scoped>
.partners-title {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.partners-title p {
  font-size: 18px !important;
  font-weight: 600;
}
</style>
