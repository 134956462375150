<template>
  <v-card class="pa-4">
    <v-card-title
      primary-title
      class="mb-4"
    >
      <v-row
        no-gutters
        align="center"
      >
        <div>
          <div>Request voyage</div>
          <div class="caption">Submit your voyage information</div>
        </div>
        <v-spacer />
        <div
          :style="
            $vuetify.breakpoint.mobile
              ? 'width: 100%; display:flex; justify-content: flex-end; margin-top: 12px'
              : ''
          "
        >
          <v-btn
            class="mr-2"
            color="error"
            :disabled="isLoading"
            @click="$emit('close-dialog')"
          >
            <v-icon left>mdi-close</v-icon>
            Cancel</v-btn
          >
          <v-btn
            color="success"
            :loading="isLoading"
            @click="onSubmitRequest"
          >
            <v-icon left>mdi-send</v-icon>
            Submit</v-btn
          >
        </div>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form onSubmit="return false;">
        <v-row no-gutters>
          <v-col cols="12">
            <v-row no-gutters>
              <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 8">
                <v-row
                  no-gutters
                  align="end"
                >
                  <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 5">
                    <div class="caption">From</div>
                    <PortPicker
                      :value="selectedPolCode"
                      @change="onSelectPOL"
                      solo
                      label="Port of Departure"
                      clearable
                      hide-details
                      outlined
                      dense
                      no-self-load-data
                    />
                  </v-col>

                  <v-col
                    :cols="$vuetify.breakpoint.mobile ? 12 : 1"
                    class="d-flex align-center justify-center"
                  >
                    <v-icon
                      color="primary"
                      class="mb-2"
                    >
                      mdi-swap-horizontal-circle
                    </v-icon>
                  </v-col>

                  <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 5">
                    <div class="caption">To</div>
                    <PortPicker
                      :value="selectedPodCode"
                      @change="onSelectPOD"
                      solo
                      label="Port of Arrival"
                      clearable
                      hide-details
                      outlined
                      dense
                      no-self-load-data
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 4">
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  persistent
                  max-width="670px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div>
                      <div class="caption">Departure Date - Arrival Date</div>
                      <v-text-field
                        :value="dateRangeText"
                        label="ETD - ETA"
                        hide-details
                        prepend-inner-icon="mdi-calendar-month"
                        readonly
                        solo
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      />
                    </div>
                  </template>
                  <v-date-picker
                    v-model="dates"
                    @change="onChangeDates"
                    range
                    :landscape="!$vuetify.breakpoint.mobile"
                    full-width
                    :allowedDates="allowedDates"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="modal = false"
                    >
                      Hủy
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="onSelectEtdEta"
                    >
                      Chọn
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>

            <v-row
              align="end"
              no-gutters
            >
              <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 8">
                <v-row no-gutters>
                  <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 11">
                    <div class="caption">Voyage</div>
                    <v-text-field
                      v-model="typedSearch"
                      label="Enter voyage code"
                      prepend-inner-icon="mdi-compass"
                      type="text"
                      solo
                      flat
                      outlined
                      dense
                      hide-details
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 4">
                <div class="caption">Vessel</div>
                <VesselPicker
                  :value="selectedVesselCode"
                  @change="onSelectVessel"
                  label="Choose vessel"
                  clearable
                  hide-details
                  solo
                  outlined
                  dense
                  no-self-load-data
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div class="overline mt-8">Container information</div>
        <v-row no-gutters>
          <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 4">
            <div class="caption">Container type size</div>
            <ContainerPicker
              class="pr-3"
              label="Select container type size"
              prepend-inner-icon="mdi-checkbox-intermediate"
              solo
              outlined
              dense
            />
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 4">
            <div class="caption">Commodity</div>
            <CommodityPicker
              class="pr-3"
              label="Select commodity"
              prepend-inner-icon="mdi-package"
              solo
              outlined
              dense
            />
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 4">
            <div class="caption">Quantity</div>
            <v-text-field
              type="number"
              prepend-inner-icon="mdi-dots-triangle"
              label="Pick quantity"
              solo
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapMutations, mapState } from 'vuex';
import PortPicker from '@/components/shared/local/vsl/PortPicker.vue';
import VesselPicker from '@/components/shared/local/vsl/VesselPicker.vue';
import ContainerPicker from '@/components/shared/local/vsl/ContainerPicker.vue';
import CommodityPicker from '@/components/shared/local/vsl/CommodityPicker.vue';

export default {
  components: { PortPicker, VesselPicker, ContainerPicker, CommodityPicker },
  data: () => ({
    isLoading: false,
    dialog: {
      request: false,
    },
    dates: [dayjs().format('YYYY-MM-DD')],
    modal: false,
    typedSearch: '',
    selectedVesselCode: null,
    selectedEtdEta: [],
    selectedPolCode: null,
    selectedPodCode: null,
  }),
  computed: {
    dateRangeText() {
      const formated = this.selectedEtdEta.map((date) => {
        return dayjs(date).format('DD.MM.YYYY');
      });
      return formated.join(' - ');
    },
  },
  created() {
    let { search, vessel_code, pol_code, pod_code, from_etd, to_eta } =
      this.$route.query;
    if (to_eta) {
      this.selectedEtdEta = [from_etd, to_eta];
    } else {
      this.selectedEtdEta = [from_etd];
    }
    this.typedSearch = search;
    this.selectedVesselCode = vessel_code;
    this.selectedPolCode = pol_code;
    this.selectedPodCode = pod_code;
  },
  methods: {
    allowedDates(value) {
      return dayjs(value) > dayjs().subtract(1, 'day');
    },
    async searchSchedule() {
      try {
        this.isLoading = true;
        await this.fetchVesselScheduleList();

        this.setIsSearched(true);
      } finally {
        this.isLoading = false;
      }
    },
    async onSelectVessel(item) {
      this.selectedVesselCode = item?.code;
    },
    async onSelectPOL(item) {
      this.selectedPolCode = item?.code;
    },
    async onSelectPOD(item) {
      this.selectedPodCode = item?.code;
    },
    onChangeDates(value) {
      this.dates = value;
    },
    async onSelectEtdEta() {
      this.modal = false;
      if (this.dates.length > 1) {
        if (dayjs(this.dates[0]).isAfter(dayjs(this.dates[1]))) {
          this.dates = [this.dates[1], this.dates[0]];
        }
      }
      this.selectedEtdEta = this.dates;
    },
    async onSubmitRequest() {
      this.isLoading = true;
      await new Promise((resolve) => setTimeout(resolve, 2500));
      this.isLoading = false;
      this.$emit('close-dialog');
      this.$message.success(
        'Your voyage request has been successfully submitted!'
      );
    },
  },
};
</script>

<style></style>
