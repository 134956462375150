import { vplApi } from '@/api';

export default {
  fetchOrderList() {
    return vplApi.get('/v1/orders');
  },
  createOrder(data) {
    return vplApi.post('/v1/orders', data);
  },
  updateOrder({ id }, data) {
    return vplApi.patch(`/v1/orders/${id}`, data);
  },
  updateOrderUserNote({ id }, data) {
    return vplApi.patch(`/v1/orders/${id}/user-note`, data);
  },
  updateOrderAttachments({ id }, data) {
    return vplApi.patch(`/v1/orders/${id}/attachments`, data);
  },
  deleteOrder({ id }) {
    return vplApi.delete(`/v1/orders/${id}`);
  },
  fetchOrder({ id }) {
    return vplApi.get(`/v1/orders/${id}`);
  },
  createOrderShipment(data) {
    return vplApi.post(`/v1/order-shipments`, data);
  },
  updateOrderShipment({ id }, data) {
    return vplApi.patch(`/v1/order-shipments/${id}`, data);
  },
  fetchOrderShipmentList({ orderId }) {
    return vplApi.get(`/v1/order-shipments?order_id=${orderId}`);
  },
  deleteOrderShipment({ id }) {
    return vplApi.delete(`/v1/order-shipments/${id}`);
  },
  submitOrder({ id }) {
    return vplApi.post(`/v1/orders/${id}/submit`);
  },
  fetchOrderActionLogList({ id }) {
    return vplApi.get(`/v1/orders/${id}/action-logs`);
  },
  fetchOrderCommentList({ id }) {
    return vplApi.get(`/v1/orders/${id}/comments?sort=asc`);
  },
  createOrderComment({ id }, data) {
    return vplApi.post(`/v1/orders/${id}/comments`, data);
  },
};
