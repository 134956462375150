<template>
  <div class="pa-0">
    <div>
      <v-row
          width="100%"
      >
        <v-col
            :cols="$vuetify.breakpoint.mobile ? 12 : 3"
            :class="{
                      'pl-3 pt-11': !$vuetify.breakpoint.mobile,
                    }"
        >
          <v-card
              flat
              tile
              width="100%"
              class="py-2 px-1"
          >
            <v-col>
              <h3 class="text-center justify-center" style="font-weight: bold;">{{ $t('included-services') }}</h3>
              <div width="100%" align="center" style="gap: 5px; border-bottom: 1px solid rgb(220, 223, 230);"/>
              <v-checkbox
                  v-for="item in includedServiceList"
                  :key="item.code"
                  :label="$t(item.label)"
                  color="info"
                  v-model="selectedServices[item.code]"
                  hide-details
                  :disabled="!item.isDefault"
              ></v-checkbox>
            </v-col>
          </v-card>
        </v-col>
        <v-col
            :cols="$vuetify.breakpoint.mobile ? 12 : 9"
            :class="{
              'pl-3': !$vuetify.breakpoint.mobile,
            }"
        >
          <div v-for="item in listTransport"
               :key="item.code"
               class="pt-8">
            <v-card
                flat
                tile
                width="100%"
                class="py-2 pt-3"
            >
              <v-row class="align-center">
                <v-col :cols="10" class="justify-center">
                  <v-row class="align-center justify-center">
                    <v-col :cols="1" class="text-center"></v-col>
                    <v-col :cols="3" class="text-center">
                      <v-row class="align-center justify-center">
                        <v-col :cols="7" class="text-center">
                          <div class="caption bubble-item">
                            <v-icon
                                color="primary"
                                class="mb-2"
                            >
                              mdi-map-marker-radius
                            </v-icon>
                          </div>
                          <div class="caption bubble-item" style="font-weight: bold;">Hà Nội</div>
                          <div class="caption bubble-item">16/09/2024</div>
                        </v-col>
                        <v-col :cols="5" class="text-center">
                          <div class="">
                            <div>
                              <img height="24px" src="https://vhub.irtech.com.vn/assets/transport_1-f24020ab.png"/>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col :cols="3" class="text-center">
                      <v-row class="align-center justify-center">
                        <v-col :cols="7" class="text-center">
                          <div class="caption bubble-item">
                            <v-icon
                                class="mb-2"
                            >
                              mdi-anchor
                            </v-icon>
                          </div>
                          <div class="caption bubble-item" style="font-weight: bold;">Tân Vũ</div>
                          <div class="caption bubble-item">16/09/2024</div>
                        </v-col>
                        <v-col :cols="5" class="text-center">
                          <div class="">
                            <div>
                              <img height="24px" src="https://vhub.irtech.com.vn/assets/transport_2-ed1df925.png"/>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col :cols="3" class="text-center">
                      <v-row class="align-center justify-center">
                        <v-col :cols="7" class="text-center">
                          <div class="caption bubble-item">
                            <v-icon
                                class="mb-2"
                            >
                              mdi-anchor
                            </v-icon>
                          </div>
                          <div class="caption bubble-item" style="font-weight: bold;">Sơn Trà</div>
                          <div class="caption bubble-item">16/09/2024</div>
                        </v-col>
                        <v-col :cols="5" class="text-center">
                          <div class="">
                            <div>
                              <img height="24px" src="https://vhub.irtech.com.vn/assets/transport_1-f24020ab.png"/>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col :cols="2" class="text-center">
                      <v-row class="align-center justify-center">
                        <v-col :cols="11" class="text-center">
                          <div class="caption bubble-item">
                            <v-icon
                                color="red"
                                class="mb-2"
                            >
                              mdi-map-marker-radius
                            </v-icon>
                          </div>
                          <div class="caption bubble-item" style="font-weight: bold;">Đà Nẵng</div>
                          <div class="caption bubble-item">16/09/2024</div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col :cols="2" class="text-center">
                  <div style="border-left: 1px solid rgb(220, 223, 230);">
                    <button
                        class="px-20 mt-2 mr-3 multiline-button"
                        style="line-height: 0.8;
                        border-radius: 3px;
                        width: 115px;
                        background-color: #409eff;
                        color: white;
                        margin-left: 4px;"
                        @click="onClick"
                    >
                      <div class="mt-4 mb-4">
                        <h3 class="mb-2 text--lighten-1" style="color: white; font-weight: bold;">$100</h3>
                        {{ $t('book-now') }}
                      </div>
                    </button>
                    <button
                        class="px-20 mt-2 mr-3 multiline-button gray-hover"
                        style="line-height: 0.8;
                      border-radius: 3px;
                      width: 115px;
                      color: #606266;
                      margin-left: 4px;"
                        @click="toggleDetail(item.code)"
                    >
                      <div class="mt-4 mb-4">
                        {{ $t('view-details') }}
                      </div>
                    </button>
                  </div>
                </v-col>


              </v-row>
              <!--              viewDetail-->
              <ViewDetailBooking v-if="showDetail === item.code"/>
            </v-card>
          </div>
          <div class="mt-2 mb-2" align="center">
            <v-btn
                style="text-transform: none;"
                width="26%"
                class="mb-2"
                large
                color="info"
                depressed
                @click="onSend"
            >
              {{ $t('request-quote') }}
              <v-icon right> mdi-arrow-top-right</v-icon>
            </v-btn>
          </div>
        </v-col>

      </v-row>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import {mapActions, mapMutations, mapState} from 'vuex';
import VesselScheduleSearchResultListItemV2 from './VesselScheduleSearchResultListItemV2.vue';
import NoData from '@/components/shared/local/base/NoData.vue';
import ContainerPicker from "@/components/shared/local/vsl/ContainerPicker.vue";
import ViewDetailBooking from "@/components/shared/local/vsl/VesselScheduleSearch/ViewDetailBooking.vue";

export default {
  inject: ['getIsSearched'],
  components: {ViewDetailBooking, ContainerPicker, VesselScheduleSearchResultListItemV2, NoData},
  data: () => ({
    showDetail: false,
    currentIndex: 0,
    includedServiceList: [
      {
        code: "1",
        label: "pick-up",
        isDefault: true
      },
      {
        code: "2",
        label: "port-origin",
        isDefault: true
      },
      {
        code: "3",
        label: "ocean-freight",
        isDefault: false
      },
      {
        code: "4",
        label: "port-dischange",
        isDefault: true
      },
      {
        code: "5",
        label: "delivery",
        isDefault: true
      },
    ],
    listTransport: [
      {
        code: "1",
        label: "1"
      },
      {
        code: "2",
        label: "1"
      },
      {
        code: "3",
        label: "1"
      },
      {
        code: "4",
        label: "1"
      },
    ],
    selectedServices: {},
  }),
  computed: {
    ...mapState('vsl/vesselSchedule', [
      'vesselScheduleList',
      'vesselScheduleListQueryParams',
      'vesselScheduleListLoading',
      'vesselScheduleListTotal',
    ]),

  },
  async created() {
    this.includedServiceList.forEach(item => {
      this.selectedServices[item.code] = true;
    });
  },
  destroyed() {
    this.RESET();
  },
  methods: {
    async onClick() {
      try {
        this.isLoading = true;
        await this.$router
            .push({
              path: 'request-a-quote-V2',
              // query: {
              //   ...this.$route.query,
              //   vessel_code: item?.code,
              // },
            })
            .catch(() => {
            });

      } finally {
        this.isLoading = false;
      }
    },
    async onSend() {
      try {
        this.isLoading = true;
        await this.$router
            .push({
              path: 'request-a-quote-send',
              // query: {
              //   ...this.$route.query,
              //   vessel_code: item?.code,
              // },
            })
            .catch(() => {
            });

      } finally {
        this.isLoading = false;
      }
    },
    toggleDetail(code) {
      this.showDetail = this.showDetail === code ? null : code;
    },
  },
};
</script>

<style>
.gray-hover:hover {
  background-color: #f2f2f2;
}

.gray-hover {
  background-color: white;
}
</style>
