var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a-row",
        [
          _c("a-col", { attrs: { span: 24 } }, [
            _c("div", { staticClass: "partner-item-title" }, [
              _vm._v("Rút container tại cảng"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "a-row",
        { staticClass: "partner-item-title-detail" },
        [
          _c("a-col", { attrs: { span: 20 } }, [
            _c("span", [_vm._v("hãng tàu HAIAN LINES")]),
          ]),
          _c(
            "a-col",
            { staticStyle: { "margin-left": "-40px" }, attrs: { span: 4 } },
            [
              _c(
                "div",
                { staticClass: "controller", staticStyle: { display: "flex" } },
                [
                  _c(
                    "a-button",
                    [
                      _c("a-icon", {
                        attrs: { type: "message", theme: "filled" },
                      }),
                    ],
                    1
                  ),
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          "margin-left": "30px",
                          "margin-right": "30px",
                        },
                      },
                      [_vm._v("$100")]
                    ),
                  ]),
                  _c("a-button", { attrs: { type: "primary" } }, [
                    _vm._v(" Confirm "),
                  ]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }