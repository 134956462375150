<template>
  <v-app>
    <v-main>
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view />
      </transition>

      <GlobalComponents />
    </v-main>
  </v-app>
</template>

<script>
import GlobalComponents from '@/components/shared/global/index.vue';
export default {
  components: {
    GlobalComponents,
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@font-face {
  font-family: 'Segoe UI';
  src: url('~@/assets/fonts/segoeui.ttf');
}

$body-font-family: 'Montserrat', sans-serif;
/* $body-font-family: 'Segoe UI', sans-serif; */

html,
body {
  background: var(--v-background-base);
  margin: 0;
  padding: 0;
}

button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

.mx-input {
  display: block !important;
  border: 1px solid #ada598 !important;
  border-radius: 4px !important;
  color: #000 !important;
  font-family: 'OpenSans-Light', Arial !important;
  font-size: 13px !important;
  padding: 20px 10px !important;
  height: 100% !important;
  // width: 100% !important;
}

.font-diavlo {
  font-family: 'Diavlo' !important;

  &--light {
    font-family: 'Diavlo-Light' !important;
  }

  &--book {
    font-family: 'Diavlo-Book' !important;
  }
}

.v-card__title {
  word-break: normal; /* maybe !important  */
}

.v-data-table {
  overflow-x: auto !important;
}

table > tbody > tr > td.fixed:nth-last-child(1),
table > thead > tr > th.fixed:nth-last-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  right: 0;
  z-index: 9998;
  background: white;
  -webkit-box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19);
  box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19);
}

table > thead > tr > th.fixed:nth-last-child(1) {
  z-index: 9999;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.v-application {
  font-family: $body-font-family !important;
  .text-h1,
  .text-h2,
  .text-h3,
  .text-h4,
  .text-h5,
  .text-h6,
  .display-1,
  .display-2,
  .display-3,
  .title,
  .caption,
  .headline,
  .overline {
    font-family: $body-font-family !important;
  }

  .elevation {
    &-0 {
      box-shadow: none !important;
    }
    &-1 {
      box-shadow: 0 2px 10px 0 rgb(94 86 105 / 10%) !important;
    }
    &-2 {
      box-shadow: 0 2px 10px 0 rgb(94 86 105 / 10%) !important;
    }
  }
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0 2px 10px 0 rgb(94 86 105 / 10%);
}

.v-btn--is-elevated {
  box-shadow: 0 3px 10px -2px rgba(85, 85, 85, 0.08),
    0 2px 20px 0 rgba(85, 85, 85, 0.06), 0 1px 30px 0 rgba(85, 85, 85, 0.03) !important;
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none;
}

.v-application.theme--light .v-menu__content {
  box-shadow: 0 4px 14px 0 rgb(94 86 105 / 14%);
  border-radius: 5px;
}

.v-card--link:before {
  border-radius: 24px;
}

.theme--light.v-card > .v-card__text,
.theme--light.v-card > .v-card__subtitle {
  color: #585858;
}

.v-timeline-item__dot {
  z-index: 0;
  box-shadow: initial !important;
}
.disabled {
  color: grey !important;
  pointer-events: none !important;
}
.flag-icon {
  background-size: cover !important;
  box-shadow: 0 1px 2px 1px rgb(119 149 255 / 10%);
  border-radius: 4px;
}

.v-stepper__label {
  text-shadow: none !important;
}

.w-100 {
  width: 100%;
}

.v-avatar {
  justify-content: center !important;
  > img {
    width: auto;
  }
}
</style>
