<template>
  <v-card
    :color="background"
    elevation="0"
    :min-height="minHeight"
    class="d-flex flex-column justify-center align-center text-center font-italic text--secondary pb-12"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-img
      src="/assets/icons/empty-state-1.png"
      max-height="250"
      contain
    ></v-img>
    <div>{{ text }}</div>
  </v-card>
</template>

<script>
export default {
  props: {
    background: {
      type: String,
      default: 'background',
    },
    text: {
      type: String,
      default: 'Không có dữ liệu',
    },
    minHeight: {
      type: String,
      default: '300px',
    },
  },
};
</script>

<style></style>
