<template>
  <div>
    <v-card
      rounded="lg"
      elevation="0"
    >
      <v-timeline
        align-top
        dense
      >
        <v-timeline-item
          v-for="(log, index) in logs"
          :key="log.step"
          small
          color="none"
        >
          <template v-slot:icon>
            <StatusIndicator
              :status="index === 0 ? 'active' : ''"
              :pulse="index === 0"
            />
          </template>
          <v-row class="pt-1">
            <v-col cols="3">
              <div>
                <strong>DATETIME</strong>
              </div>
              <div>
                {{ $filters.formatDatetime(log.movementDate) }}
              </div>
            </v-col>
            <v-col cols="2">
              <strong>STATUS</strong>
              <div v-if="log.status">
                {{ log.status.replace('_', ' ') }}
              </div>
            </v-col>
            <v-col cols="7">
              <strong>LOCATION</strong>
              <div>
                {{ log.depotName || log.terminalName }}
                <span v-if="log.depotPortName || log.terminalPortName"
                  >({{ log.depotPortName || log.terminalPortName }})</span
                >
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  components: {},
  props: ['logs'],
  data: () => ({}),
  computed: {
    ...mapState('vsl/containerTracking', ['containerMovements']),
  },

  created() {},
  methods: {},
};
</script>

<style scoped lang="scss">
:deep {
}
</style>
