const initialState = () => ({
  isFocusMenu: false,
  snackbar: {
    status: false,
    timeout: 4000,
    message: '',
    color: 'blue-grey',
  },
  dialogAuth: false,
  dialogUserSettings: {
    status: false,
    tabKey: 'account',
  },
  drawer: true,
  updatePrompt: false,
});

const state = initialState();
const getters = {};
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  SET_IS_FOCUS_MENU(state, payload) {
    state.isFocusMenu = payload;
  },
  SET_SNACKBAR(state, payload) {
    const { timeout = 4000, message, color = 'blue-grey' } = payload;
    state.snackbar.timeout = timeout;
    state.snackbar.message = message;
    state.snackbar.color = color;
  },
  TOGGLE_SNACKBAR(state, payload) {
    state.snackbar.status = payload;
  },
  TOGGLE_DIALOG_AUTH(state, payload) {
    state.dialogAuth = payload;
  },
  TOGGLE_DIALOG_USER_SETTINGS(state, payload) {
    state.dialogUserSettings = {
      status: payload.status,
      tabKey: payload.tabKey ? payload.tabKey : 'account',
    };
  },
  TOGGLE_DRAWER(state, payload) {
    state.drawer = payload;
  },
  TOGGLE_UPDATE_PROMPT(state, payload) {
    state.updatePrompt = payload;
  },
};
const actions = {
  toggleFocusMenu({ commit }, payload) {
    commit('SET_IS_FOCUS_MENU', payload);
  },
  showSnackbar({ commit }, payload) {
    commit('SET_SNACKBAR', payload);
    commit('TOGGLE_SNACKBAR', true);
  },
  closeSnackbar({ commit }) {
    commit('TOGGLE_SNACKBAR', false);
  },
  toggleUpdatePrompt({ commit }, payload) {
    commit('TOGGLE_UPDATE_PROMPT', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
