var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    {
      staticStyle: {
        margin: "20px 0",
        "box-shadow": "0 25px 25px -12px rgb(0 0 0 / 0.25)",
        "border-radius": "20px",
      },
      attrs: { width: "100%" },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _c("span", [
                        _c("span", {
                          staticClass: "mdi mdi-record-circle",
                          staticStyle: { color: "blue" },
                        }),
                        _vm._v(" Hà Nội "),
                      ]),
                      _c("hr", {
                        staticStyle: { width: "20px", margin: "0 5px" },
                      }),
                      _c("span", [
                        _c("span", {
                          staticClass: "mdi mdi-map-marker",
                          staticStyle: { color: "red" },
                        }),
                        _vm._v(" Hồ Chí Minh "),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    [
                      _c("span", { staticStyle: { "margin-right": "10px" } }, [
                        _vm._v("20/3/2024"),
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticStyle: {
                            color: "#aec76d",
                            "background-color": "#f0f9eb",
                            "text-transform": "none",
                          },
                        },
                        [_vm._v(" Done ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      [
        _c("div", { staticStyle: { display: "flex" } }, [
          _c("div", { staticStyle: { width: "20%" } }, [
            _c("div", [_vm._v("#OD00051")]),
            _c("div", { staticStyle: { "font-weight": "900" } }, [
              _vm._v("40HC"),
            ]),
          ]),
          _c("div", { staticStyle: { width: "70%" } }, [
            _c("div", [
              _c("span", [_vm._v(" Commodity ")]),
              _c(
                "span",
                { staticStyle: { "margin-left": "5px", "font-weight": "900" } },
                [_vm._v("Thực phẩm")]
              ),
            ]),
            _c("div", [
              _c("span", [_vm._v("Delivery date")]),
              _c(
                "span",
                { staticStyle: { "margin-left": "5px", "font-weight": "900" } },
                [_vm._v("18/03/2024")]
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                width: "10%",
                display: "flex",
                "align-items": "center",
                "justify-content": "end",
                color: "#2e90fa",
                "font-size": "23px",
                "font-weight": "600",
              },
            },
            [_vm._v(" $100 ")]
          ),
        ]),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }