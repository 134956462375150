<template>
  <div class="d-flex align-center justify-center mr-2">
    <v-btn
      v-if="lang === 'vi'"
      width="160px"
      class="px-2 py-4"
      depressed
      color="white"
      small
      @click="onChangeLanguage('en')"
    >
      <v-img
        height="24"
        width="50"
        contain
        :src="require('@/assets/country/vietnam.png')"
      />
      Tiếng Việt
      <v-icon right> mdi-arrow-down-drop </v-icon>
    </v-btn>
    <v-btn
      v-if="lang === 'en'"
      width="160px"
      class="px-2 py-4"
      depressed
      small
      color="white"
      @click="onChangeLanguage('vi')"
    >
      <v-img
        height="24"
        width="50"
        contain
        :src="require('@/assets/country/united-kingdom.png')"
      />
      English
      <v-icon right> mdi-arrow-down-drop </v-icon>
    </v-btn>
  </div>
</template>

<script>
import i18n from '@/i18n';

export default {
  data: () => ({
    lang: i18n.locale,
  }),
  methods: {
    onChangeLanguage(code) {
      localStorage.setItem('locale', code);
      window.location.reload();
    },
  },
};
</script>

<style></style>
