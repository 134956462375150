import AuthLogin from '@/views/Auth/Login';
import AuthSignup from '@/views/Auth/Signup';
import AuthOTPVerify from '@/views/Auth/OTPVerify';
import AuthUpdateRequireInfo from '@/views/Auth/UpdateRequireInfo';

const routes = [
  {
    path: '/auth',
    redirect: '/auth/login',
    name: 'auth',
    component: () => import('@/views/Auth'),
    children: [
      {
        path: 'login',
        name: 'login',
        meta: {
          title: 'Đăng nhập',
        },
        component: AuthLogin,
      },
      {
        path: 'signup',
        name: 'signup',
        meta: {
          title: 'Tạo tài khoản',
        },
        component: AuthSignup,
      },
      {
        path: 'signup/otp-verify',
        name: 'otp verify',
        meta: {
          title: 'Nhập mã xác thực',
        },
        component: AuthOTPVerify,
      },
      {
        path: 'signup/update-require-info',
        name: 'update require info',
        meta: {
          title: 'Cập nhật thông tin tài khoản',
        },
        component: AuthUpdateRequireInfo,
      },
      // {
      //   path: "forgot-password",
      //   name: "forgot-password",
      //   meta: {
      //     title: "Quên mật khẩu"
      //   },
      //   component: () => import("@/views/Auth/ForgotPassword")
      // }
    ],
  },
];

export default routes;
