<template>
  <div>
    <template v-if="!isLoading">
      <v-card
        v-for="item of contactList"
        :key="item.id"
        class="mb-4"
        flat
      >
        <UserSettingsContactListItem
          :item="item"
          @delete="onDeleteContactItem"
          @update="onUpdateContactItem"
        />
      </v-card>
    </template>
    <template v-if="isLoading">
      <v-card
        v-for="item in 3"
        :key="item.id"
        class="mb-4"
        flat
      >
        <v-skeleton-loader
          type="text@3"
          :boilerplate="false"
          class="mb-2"
        />
        <v-divider />
      </v-card>
    </template>
    <v-dialog
      v-model="dialog.delete"
      max-width="600px"
      transition="dialog-transition"
    >
      <UserSettingsContactDelete
        :item="selectedItem"
        v-if="dialog.delete"
        @close-dialog="dialog.delete = false"
      />
    </v-dialog>
    <v-dialog
      v-model="dialog.update"
      max-width="600px"
      :fullscreen="$vuetify.breakpoint.mobile"
      transition="dialog-transition"
    >
      <UserSettingsContactUpdate
        :item="selectedItem"
        v-if="dialog.update"
        @close-dialog="dialog.update = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import UserSettingsContactDelete from './UserSettingsContactDelete.vue';
import UserSettingsContactListItem from './UserSettingsContactListItem.vue';
import UserSettingsContactUpdate from './UserSettingsContactUpdate.vue';
export default {
  components: {
    UserSettingsContactListItem,
    UserSettingsContactDelete,
    UserSettingsContactUpdate,
  },
  data: () => ({
    isLoading: false,
    dialog: {
      delete: false,
      update: false,
    },
    selectedItem: null,
  }),
  computed: {
    ...mapState('userContact', ['contactList']),
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchContactList();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions('userContact', ['fetchContactList']),
    onDeleteContactItem(item) {
      this.selectedItem = item;
      this.dialog.delete = true;
    },
    onUpdateContactItem(item) {
      this.selectedItem = item;
      this.dialog.update = true;
    },
  },
};
</script>

<style></style>
