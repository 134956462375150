<template>
  <div>
    <a-row>
      <a-col :span="24">
        <div class="partner-item-title">Rút container tại cảng</div></a-col
      >
    </a-row>
    <a-row class="partner-item-title-detail">
      <a-col :span="20"> <span>hãng tàu HAIAN LINES</span> </a-col>
      <a-col :span="4" style="margin-left: -40px;">
        <div class="controller" style="display: flex">
          <a-button>
            <a-icon
              type="message"
              theme="filled"
            />
          </a-button>
          <div><span style="font-weight: bold;margin-left: 30px;margin-right: 30px">$100</span></div>
          <a-button type="primary"> Confirm </a-button>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.partner-item-title {
  background-color: #eaecf0;
  padding: 12px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}
.partner-item-title-detail {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  padding-left: 12px;
}
.partner-item-title-detail span {
  font-size: 14px;
  font-weight: normal;
}
.controller {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controller > div {
  /* display: flex;
  justify-content: center;
  align-content: center; */
}
</style>
