import ProvinceApi from '@/api/base/province.api';

const initialState = () => ({
  provinceList: [],
  provinceDistrictList: [],
});

const state = initialState();
const getters = {};
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  SET_PROVINCE_LIST(state, payload) {
    state.provinceList = payload;
  },
  SET_PROVINCE_DISTRICT_LIST(state, payload) {
    state.provinceDistrictList = payload;
  },
};
const actions = {
  async fetchProvinceList({ commit }, payload) {
    const res = await ProvinceApi.fetchProvinceList();
    const mapped = res.data.data.map((item) => {
      const mappedItem = {
        code: item.code,
        name: item.name,
        slug: item.slug,
        type: item.type,
        nameWithType: item.name_with_type,
      };
      return mappedItem;
    });
    commit('SET_PROVINCE_LIST', mapped);
  },
  async fetchProvinceDistrictList({ commit }, payload) {
    const { code } = payload;
    const res = await ProvinceApi.fetchProvinceDistrictList({ code });
    const mapped = res.data.data.map((item) => {
      const mappedItem = {
        code: item.code,
        name: item.name,
        slug: item.slug,
        type: item.type,
        nameWithType: item.name_with_type,
        path: item.path,
        pathWithType: item.path_with_type,
      };
      return mappedItem;
    });
    commit('SET_PROVINCE_DISTRICT_LIST', mapped);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
