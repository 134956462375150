<template>
  <a-card
    width="100%"
    style="
      margin: 20px 0;
      box-shadow: 0 25px 25px -12px rgb(0 0 0 / 0.25);
      border-radius: 20px;
    "
  >
    <template #title>
      <div class="processing-title">
        <p
          class="display-1 ma-0"
          :style="
            $vuetify.breakpoint.mobile
              ? `text-align: center; font-size: 1.5rem!important; width: 100%`
              : ''
          "
        >
          {{ $t('title-processing') }}
        </p>
      </div>
      <template>
        <a-timeline class="process-timeline">
          <a-timeline-item color="green">
            <p class="process-timeline-title">
              Khách hàng hủy xác nhận nhà cung cấp HAIAN LINES
            </p>
            <p class="process-timeline-time">20-03-2024 12:43:07</p>
          </a-timeline-item>
          <a-timeline-item color="green">
            <p class="process-timeline-title">
              Khách hàng hủy xác nhận nhà cung cấp HAIAN LINES
            </p>
            <p class="process-timeline-time">20-03-2024 12:43:07</p>
          </a-timeline-item>
          <a-timeline-item color="green">
            <p class="process-timeline-title">
              Khách hàng hủy xác nhận nhà cung cấp HAIAN LINES
            </p>
            <p class="process-timeline-time">20-03-2024 12:43:07</p>
          </a-timeline-item>
          <a-timeline-item color="green">
            <p class="process-timeline-title">
              Khách hàng hủy xác nhận nhà cung cấp HAIAN LINES
            </p>
            <p class="process-timeline-time">20-03-2024 12:43:07</p>
          </a-timeline-item>
          <a-timeline-item color="green">
            <p class="process-timeline-title">
              Khách hàng hủy xác nhận nhà cung cấp HAIAN LINES
            </p>
            <p class="process-timeline-time">20-03-2024 12:43:07</p>
          </a-timeline-item>
          <a-timeline-item color="green">
            <p class="process-timeline-title">
              Khách hàng hủy xác nhận nhà cung cấp HAIAN LINES
            </p>
            <p class="process-timeline-time">20-03-2024 12:43:07</p>
          </a-timeline-item>
          <a-timeline-item color="green">
            <p class="process-timeline-title">
              Khách hàng hủy xác nhận nhà cung cấp HAIAN LINES
            </p>
            <p class="process-timeline-time">20-03-2024 12:43:07</p>
          </a-timeline-item>
          <a-timeline-item color="green">
            <p class="process-timeline-title">
              Khách hàng hủy xác nhận nhà cung cấp HAIAN LINES
            </p>
            <p class="process-timeline-time">20-03-2024 12:43:07</p>
          </a-timeline-item>
        </a-timeline>
      </template>
    </template>
  </a-card>
</template>

<script>
export default {};
</script>

<style scoped>
.processing-title {
  display: flex;
  justify-content: start;
  margin-bottom: 20px;
}

.processing-title p {
  font-size: 16px !important;
  font-weight: 500;
}

.process-timeline .process-timeline-title {
  font-size: 14px;
}

.process-timeline .process-timeline-time {
  font-size: 13px;
}
</style>
