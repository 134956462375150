<!-- Nav Bar Start -->
<template>
  <div class="container-wrapper">
    <div class="row">
      <div class="col-12">
        <nav
          class="navbar nav-sticky navbar-expand-md navbar-light"
          id="navbarCr"
          style="margin-top: 10px"
        >
          <div class="container-nav">
            <a
              class="navbar-brand"
              target="_blank"
              style="padding-left: 30px; margin-top: auto; margin-bottom: auto"
            >
              <img
                src="../../../assets/icons/icon-menu-nav.png"
                class="nav-column-open-btn"
                aria-label="Open menu"
                data-nav-column-toggler
              />
            </a>
            <a
              href="/"
              class="navbar-brand-logo"
              style="padding-left: 40px; margin-top: auto; margin-bottom: auto"
            >
              <img
                src="../../../libhome/img/footer1.svg"
                alt=""
              />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div
              class="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul class="navbar-nav ml-auto py-4 py-md-0">
                <li
                  class="nav-item pl-4 pl-md-0 ml-0 ml-md-4"
                  :class="{ active: active === 'home' }"
                >
                  <a
                    class="nav-link"
                    href="/"
                    >{{ $t('home') }}</a
                  >
                </li>
                <!-- <li
                  class="nav-item pl-4 pl-md-0 ml-0 ml-md-4"
                  :class="{ active: active === 'about' }"
                >
                  <a
                    class="nav-link"
                    href="/about"
                    >{{ $t('aboutUs') }}</a
                  >
                </li> -->
                <li
                  class="nav-item pl-4 pl-md-0 ml-0 ml-md-4"
                  :class="{ active: active === 'blog' }"
                >
                  <a
                    class="nav-link"
                    href="/blogs"
                    >{{ $t('blogs') }}</a
                  >
                </li>
                <li
                  class="nav-item pl-4 pl-md-0 ml-0 ml-md-4"
                  :class="{ active: active === 'services' }"
                >
                  <a class="nav-link">{{ $t('services') }}</a>
                  <ul class="submenu">
                    <li>
                      <a
                        class="nav-link-sub"
                        href="/vesselSchedule"
                        >{{ $t('vesselSchedule') }}</a
                      >
                    </li>
                    <li>
                      <a
                        class="nav-link-sub"
                        href="/Quote"
                        >{{ $t('Quote') }}</a
                      >
                    </li>
                    <li>
                      <a
                        class="nav-link-sub"
                        href="/Booking"
                        >{{ $t('Booking') }}</a
                      >
                    </li>
                    <li>
                      <a
                        class="nav-link-sub"
                        href="/Tracking"
                        >{{ $t('Tracking') }}</a
                      >
                    </li>
                  </ul>
                </li>

                <li
                  class="nav-item pl-4 pl-md-0 ml-0 ml-md-4"
                  :class="{ active: active === 'contact' }"
                >
                  <a
                    class="nav-link"
                    href="/contact"
                    >{{ $t('contact') }}</a
                  >
                </li>
                <li
                  class="nav-item pl-4 pl-md-0 ml-0 ml-md-4"
                  :class="{ active: active === 'recruitment' }"
                >
                  <a
                    class="nav-link"
                    href="/recruitment"
                    >{{ $t('recruitment') }}</a
                  >
                </li>
              </ul>
              <div class="ml-auto d-flex align-items-center">
                <div
                  class="language-switcher mr-3"
                  @mouseover="showLanguageDropdown = true"
                  @mouseleave="showLanguageDropdown = false"
                >
                  <button>
                    <span
                      :class="
                        currentLanguage === 'vi'
                          ? 'flag-icon flag-icon-vn'
                          : 'flag-icon flag-icon-gb'
                      "
                    ></span>
                  </button>
                  <div
                    v-if="showLanguageDropdown"
                    class="dropdown-menu"
                  >
                    <button
                      @click="changeLanguage('en')"
                      :class="{ active: currentLanguage === 'en' }"
                      class="dropdown-item"
                    >
                      <span class="flag-icon flag-icon-gb"></span>&nbsp;{{
                        $t('eng')
                      }}
                    </button>
                    <button
                      @click="changeLanguage('vi')"
                      :class="{ active: currentLanguage === 'vi' }"
                      class="dropdown-item"
                    >
                      <span class="flag-icon flag-icon-vn"></span>
                      &nbsp;{{ $t('vie') }}
                    </button>
                  </div>
                </div>
                <a
                  class="btn btn-custom"
                  href="/contact"
                  >{{ $t('getAQuote') }}</a
                >
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: String,
    },
  },
  data() {
    return {
      currentLanguage: this.$i18n.locale || 'vi', // Set default to Vietnamese
      showLanguageDropdown: false,
      elementId: 'message-form',
      showSubmenu: false,
    };
  },
  methods: {
    changeLanguage(language) {
      localStorage.setItem('locale', language);
      localStorage.setItem('scrollPosition', window.scrollY);
      this.$i18n.locale = language;
      this.currentLanguage = language;
      this.showLanguageDropdown = false;
      window.location.reload(); // Reload the page to apply the language change
    },
  },
  mounted() {
    const savedScrollPosition = localStorage.getItem('scrollPosition');
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition));
      localStorage.removeItem('scrollPosition');
    }
  },
};
</script>

<style>
.language-switcher {
  position: relative;
}

.language-switcher button {
  background: none;
  border: 1px solid #fff;
  cursor: pointer;
  padding: 0 5px;
  font-size: 1rem;
}

.language-switcher button .flag-icon {
  font-size: 1.5rem;
}

.language-switcher .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  border: none !important;
  background-color: #fff !important;
  color: #e8734e !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.language-switcher .dropdown-item {
  padding: 10px 20px;
  cursor: pointer;
}

.language-switcher .dropdown-item.active {
  font-weight: bold;
}

.language-switcher .dropdown-item:hover {
  background-color: #e8734e !important;
  color: #fff !important;
}

.btn-custom {
  margin-left: 10px;
}

.submenu {
  position: absolute;
  left: 0;
  top: 100%;
  padding: 5px 15px 5px 15px !important;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;
  z-index: 1000;
}

.nav-item:hover .submenu {
  display: block;
}

.submenu .nav-link-sub {
  font-family: Inter, sans-serif;
  line-height: 1.6;
  font-weight: 500;
  font-size: 16px;
  padding: 10px 20px;
  display: block;
  white-space: nowrap;
  text-decoration: none;
  color: #000;
}

.submenu .nav-link-sub:hover {
  color: #ff6600;
}
</style>
