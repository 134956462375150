<template>
  <div
    class="headerN"
    :class="{ 'sidebar-open': sidebarVisible }"
    data-header
  >
    <div class="container">
      <nav
        class="navbar-column"
        data-navbar-column
      >
        <div class="navbar-column-top">
          <a href="/">
            <img
              src="/assets/logo_vimc_lines.PNG"
              alt=""
              style="font-size: small; max-height: 40px"
            />
          </a>
          <button
            class="nav-column-close-btn"
            aria-label="Close menu"
            data-nav-column-toggler
            style="text-align: right; margin-left: 95px"
            @click="toggleSidebar"
          >
            <v-icon right>mdi-close</v-icon>
          </button>
        </div>

        <ul
          class="navbar-column-list"
          style="list-style: none; margin-top: 10px"
        >
          <li
            class="navbar-column-item nav-item"
            @mouseenter="showSubmenu('home')"
            @mouseleave="hideSubmenu('home')"
          >
            <a
              href="/"
              class="navbar-column-link nav-link"
              data-nav-column-link
            >
              <span>{{ $t('home') }}</span>
            </a>
            <ul
              class="submenu"
              v-if="submenuVisible.home"
            >
              <li
                class="submenu-item"
                @click="handleSubmenuItemClick('services')"
              >
                <a
                  href="#services"
                  @click.prevent
                >
                  {{ $t('services') }}
                </a>
              </li>
              <li
                class="submenu-item"
                @click="handleSubmenuItemClick('about-us')"
              >
                <a
                  href="#about-us"
                  @click.prevent
                >
                  {{ $t('aboutUsPage') }}
                </a>
              </li>
              <li
                class="submenu-item"
                @click="handleSubmenuItemClick('quote')"
              >
                <a
                  href="#quote"
                  @click.prevent
                >
                  {{ $t('quote') }}
                </a>
              </li>
              <li
                class="submenu-item"
                @click="handleSubmenuItemClick('all-services')"
              >
                <a
                  href="#all-services"
                  @click.prevent
                >
                  {{ $t('allServices') }}
                </a>
              </li>
              <li
                class="submenu-item"
                @click="handleSubmenuItemClick('achivement')"
              >
                <a
                  href="#achivement"
                  @click.prevent
                >
                  {{ $t('achivement') }}
                </a>
              </li>
              <li
                class="submenu-item"
                @click="handleSubmenuItemClick('history')"
              >
                <a
                  href="#history"
                  @click.prevent
                >
                  {{ $t('history') }}
                </a>
              </li>
              <li
                class="submenu-item"
                @click="handleSubmenuItemClick('our-team')"
              >
                <a
                  href="#our-team"
                  @click.prevent
                >
                  {{ $t('meetOurTeamLow') }}
                </a>
              </li>
              <!-- <li
                class="submenu-item"
                @click="handleSubmenuItemClick('call-us')"
              >
                <a
                  href="#call-us"
                  @click.prevent
                >
                  {{ $t('callUsNow') }}
                </a>
              </li> -->
            </ul>
          </li>

          <!-- <li
            class="navbar-column-item nav-item"
            @mouseenter="showSubmenu('about-us')"
            @mouseleave="hideSubmenu('about-us')"
          >
            <a
              href="/about"
              class="navbar-column-link nav-link"
            >
              <span>{{ $t('aboutUs') }}</span>
            </a>
            <ul
              class="submenu"
              v-if="submenuVisible['about-us']"
            >
              <li
                class="submenu-item"
                @click="handleSubmenuItemClick('about-us-page')"
              >
                <a
                  href="#about-us-page"
                  @click.prevent
                >
                  {{ $t('aboutUsPage') }}
                </a>
              </li>
              <li
                class="submenu-item"
                @click="handleSubmenuItemClick('mission')"
              >
                <a
                  href="#mission"
                  @click.prevent
                >
                  {{ $t('mission') }}
                </a>
              </li>
              <li
                class="submenu-item"
                @click="handleSubmenuItemClick('history')"
              >
                <a
                  href="#history"
                  @click.prevent
                >
                  {{ $t('history') }}
                </a>
              </li>
              <li
                class="submenu-item"
                @click="handleSubmenuItemClick('our-team')"
              >
                <a
                  href="#our-team"
                  @click.prevent
                >
                  {{ $t('ourTeam') }}
                </a>
              </li>

              <li
                class="submenu-item"
                @click="handleSubmenuItemClick('our-blog')"
              >
                <a
                  href="#our-blog"
                  @click.prevent
                >
                  {{ $t('ourBlog') }}
                </a>
              </li>
            </ul>
          </li> -->

          <li
            class="navbar-column-item nav-item"
            @mouseenter="showSubmenu('blogs')"
            @mouseleave="hideSubmenu('blogs')"
          >
            <a
              href="/blogs"
              class="navbar-column-link nav-link"
              data-nav-column-link
            >
              <span>{{ $t('blogs') }}</span>
              <ul
                class="submenu"
                v-if="submenuVisible.blogs"
              >
                <li
                  class="submenu-item"
                  @click="handleSubmenuItemClick('blogs-1')"
                >
                  <a
                    href="#blogs-1"
                    @click.prevent
                  >
                    {{ $t('establishment') }}
                  </a>
                </li>
                <li
                  class="submenu-item"
                  @click="handleSubmenuItemClick('blogs-4')"
                >
                  <a
                    href="#blogs-4"
                    @click.prevent
                  >
                    {{ $t('annoucement') }}
                  </a>
                </li>
                <li
                  class="submenu-item"
                  @click="handleSubmenuItemClick('blogs-2')"
                >
                  <a
                    href="#blogs-2"
                    @click.prevent
                  >
                    {{ $t('seafarer') }}
                  </a>
                </li>
                <li
                  class="submenu-item"
                  @click="handleSubmenuItemClick('blogs-3')"
                >
                  <a
                    href="#blogs-3"
                    @click.prevent
                  >
                    {{ $t('containerTerminal') }}
                  </a>
                </li>
              </ul>
            </a>
          </li>

          <li
            class="navbar-column-item nav-item"
            @mouseenter="showSubmenu('services')"
            @mouseleave="hideSubmenu('services')"
          >
            <a
              class="navbar-column-link nav-link"
              data-nav-column-link
            >
              <span>{{ $t('services') }}</span>
            </a>
            <ul
              class="submenu"
              v-if="submenuVisible.services"
            >
              <li
                class="submenu-item"
                @click="handleSubmenuItemClick('vesselSchedule')"
              >
                <a href="/vesselSchedule">
                  {{ $t('vesselSchedule') }}
                </a>
              </li>
              <li
                class="submenu-item"
                @click="handleSubmenuItemClick('Quote')"
              >
                <a href="/Quote">
                  {{ $t('Quote') }}
                </a>
              </li>
              <li
                class="submenu-item"
                @click="handleSubmenuItemClick('Booking')"
              >
                <a href="/Booking">
                  {{ $t('Booking') }}
                </a>
              </li>
              <li
                class="submenu-item"
                @click="handleSubmenuItemClick('Tracking')"
              >
                <a href="/Tracking">
                  {{ $t('Tracking') }}
                </a>
              </li>
            </ul>
          </li>

          <li
            class="navbar-column-item nav-item"
            @mouseenter="showSubmenu('contact')"
            @mouseleave="hideSubmenu('contact')"
          >
            <a
              href="/contact"
              class="navbar-column-link nav-link"
              data-nav-column-link
            >
              <span>{{ $t('contact') }}</span>
            </a>
            <ul
              class="submenu"
              v-if="submenuVisible.contact"
            >
              <li
                class="submenu-item"
                @click="handleSubmenuItemClick('contact-1')"
              >
                <a
                  href="#contact-1"
                  @click.prevent
                >
                  {{ $t('contact-1') }}
                </a>
              </li>
              <li
                class="submenu-item"
                @click="handleSubmenuItemClick('contact-2')"
              >
                <a
                  href="#contact-2"
                  @click.prevent
                >
                  {{ $t('contact-2') }}
                </a>
              </li>
            </ul>
          </li>
          <!-- <li class="navbar-column-item nav-item">
            <a
              href="/contact"
              class="navbar-column-link nav-link"
            >
              <span>{{ $t('contact') }}</span>
            </a>
          </li> -->
          <li
            class="navbar-column-item nav-item"
            @mouseenter="showSubmenu('recruitment')"
            @mouseleave="hideSubmenu('recruitment')"
          >
            <a
              href="/recruitment"
              class="navbar-column-link nav-link"
              data-nav-column-link
            >
              <span>{{ $t('recruitment') }}</span>
            </a>
            <ul
              class="submenu"
              v-if="submenuVisible.recruitment"
            >
              <li
                class="submenu-item"
                @click="handleSubmenuItemClick('recruitment-1')"
              >
                <a
                  href="#recruitment-1"
                  @click.prevent
                >
                  {{ $t('recruitment1') }}
                </a>
              </li>
              <li
                class="submenu-item"
                @click="handleSubmenuItemClick('recruitment-2')"
              >
                <a
                  href="#recruitment-2"
                  @click.prevent
                >
                  {{ $t('recruitment2') }}
                </a>
              </li>
              <li
                class="submenu-item"
                @click="handleSubmenuItemClick('recruitment-3')"
              >
                <a
                  href="#recruitment-3"
                  @click.prevent
                >
                  {{ $t('recruitment3') }}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>

      <div
        class="overlay"
        data-nav-column-toggler
        data-overlay
        @click="toggleSidebar"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      submenuVisible: {
        home: false,
        'about-us': false,
        services: false,
        recruitment: false,
        blogs: false,
      },
      sidebarVisible: false,
    };
  },
  methods: {
    showSubmenu(menu) {
      this.$set(this.submenuVisible, menu, true);
    },
    hideSubmenu(menu) {
      this.$set(this.submenuVisible, menu, false);
    },
    hideAllSubmenus() {
      Object.keys(this.submenuVisible).forEach((key) => {
        this.submenuVisible[key] = false;
      });
    },
    toggleSidebar() {
      this.sidebarVisible = !this.sidebarVisible;
    },
    handleSubmenuItemClick(sectionId) {
      this.scrollToSection(sectionId);
      this.toggleSidebar();
      this.hideAllSubmenus();
    },
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
    toggleSubmenu(menu) {
      this.submenuVisible[menu] = !this.submenuVisible[menu];
    },
  },
};
</script>

<style>
.headerN {
  position: relative;
  margin: -30px;
}

.navbar-column {
  transition: transform 0.3s ease;
  transform: translateX(-100%); /* Hide by default */
}

.sidebar-open .navbar-column {
  transform: translateX(0); /* Show when sidebarVisible is true */
}

.navbar-column-list .navbar-column-item {
  position: relative;
}

.navbar-column-list .navbar-column-item .submenu {
  display: none;
  position: static;
  background: white;
  list-style: none;
  padding: 5px;
  width: max-content;
}

.navbar-column-list .navbar-column-item .submenu .submenu-item {
  padding: 5px 10px;
}

.navbar-column-list .navbar-column-item .submenu .submenu-item a {
  color: black;
  text-decoration: none;
}

.navbar-column-list .navbar-column-item .submenu .submenu-item a:hover {
  color: #e8734e; /* Change text color on hover */
}

.navbar-column-list .navbar-column-item:hover .submenu,
.navbar-column-list .navbar-column-item .submenu[style*='display: block'] {
  display: block; /* Show submenu when hovering or when submenu is visible */
}

/* Ensure submenu pushes other navbar items down */
.navbar-column-item {
  margin-bottom: 10px;
}
</style>
