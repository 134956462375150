var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a-card", {
    staticStyle: {
      margin: "20px 0",
      "box-shadow": "0 25px 25px -12px rgb(0 0 0 / 0.25)",
      "border-radius": "20px",
    },
    attrs: { width: "100%" },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [
            _c("div", { staticClass: "processing-title" }, [
              _c(
                "p",
                {
                  staticClass: "display-1 ma-0",
                  style: _vm.$vuetify.breakpoint.mobile
                    ? `text-align: center; font-size: 1.5rem!important; width: 100%`
                    : "",
                },
                [_vm._v(" " + _vm._s(_vm.$t("title-processing")) + " ")]
              ),
            ]),
            [
              _c(
                "a-timeline",
                { staticClass: "process-timeline" },
                [
                  _c("a-timeline-item", { attrs: { color: "green" } }, [
                    _c("p", { staticClass: "process-timeline-title" }, [
                      _vm._v(
                        " Khách hàng hủy xác nhận nhà cung cấp HAIAN LINES "
                      ),
                    ]),
                    _c("p", { staticClass: "process-timeline-time" }, [
                      _vm._v("20-03-2024 12:43:07"),
                    ]),
                  ]),
                  _c("a-timeline-item", { attrs: { color: "green" } }, [
                    _c("p", { staticClass: "process-timeline-title" }, [
                      _vm._v(
                        " Khách hàng hủy xác nhận nhà cung cấp HAIAN LINES "
                      ),
                    ]),
                    _c("p", { staticClass: "process-timeline-time" }, [
                      _vm._v("20-03-2024 12:43:07"),
                    ]),
                  ]),
                  _c("a-timeline-item", { attrs: { color: "green" } }, [
                    _c("p", { staticClass: "process-timeline-title" }, [
                      _vm._v(
                        " Khách hàng hủy xác nhận nhà cung cấp HAIAN LINES "
                      ),
                    ]),
                    _c("p", { staticClass: "process-timeline-time" }, [
                      _vm._v("20-03-2024 12:43:07"),
                    ]),
                  ]),
                  _c("a-timeline-item", { attrs: { color: "green" } }, [
                    _c("p", { staticClass: "process-timeline-title" }, [
                      _vm._v(
                        " Khách hàng hủy xác nhận nhà cung cấp HAIAN LINES "
                      ),
                    ]),
                    _c("p", { staticClass: "process-timeline-time" }, [
                      _vm._v("20-03-2024 12:43:07"),
                    ]),
                  ]),
                  _c("a-timeline-item", { attrs: { color: "green" } }, [
                    _c("p", { staticClass: "process-timeline-title" }, [
                      _vm._v(
                        " Khách hàng hủy xác nhận nhà cung cấp HAIAN LINES "
                      ),
                    ]),
                    _c("p", { staticClass: "process-timeline-time" }, [
                      _vm._v("20-03-2024 12:43:07"),
                    ]),
                  ]),
                  _c("a-timeline-item", { attrs: { color: "green" } }, [
                    _c("p", { staticClass: "process-timeline-title" }, [
                      _vm._v(
                        " Khách hàng hủy xác nhận nhà cung cấp HAIAN LINES "
                      ),
                    ]),
                    _c("p", { staticClass: "process-timeline-time" }, [
                      _vm._v("20-03-2024 12:43:07"),
                    ]),
                  ]),
                  _c("a-timeline-item", { attrs: { color: "green" } }, [
                    _c("p", { staticClass: "process-timeline-title" }, [
                      _vm._v(
                        " Khách hàng hủy xác nhận nhà cung cấp HAIAN LINES "
                      ),
                    ]),
                    _c("p", { staticClass: "process-timeline-time" }, [
                      _vm._v("20-03-2024 12:43:07"),
                    ]),
                  ]),
                  _c("a-timeline-item", { attrs: { color: "green" } }, [
                    _c("p", { staticClass: "process-timeline-title" }, [
                      _vm._v(
                        " Khách hàng hủy xác nhận nhà cung cấp HAIAN LINES "
                      ),
                    ]),
                    _c("p", { staticClass: "process-timeline-time" }, [
                      _vm._v("20-03-2024 12:43:07"),
                    ]),
                  ]),
                ],
                1
              ),
            ],
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }