<template>
  <v-row no-gutters>
    <v-col cols="12">
      <div
        v-if="provinceCaption"
        class="caption"
      >
        {{ provinceCaption }}
      </div>
      <v-autocomplete
        :value="value.provinceCode"
        :items="provinceList"
        :loading="isLoadingProvince"
        :disabled="isLoadingProvince || disabled"
        :label="provinceLabel"
        :filter="customFilter"
        spellcheck="false"
        type="text"
        prepend-inner-icon="mdi-map-marker-radius"
        item-text="nameWithType"
        item-value="code"
        return-object
        :error-messages="errorMessages"
        v-bind="$attrs"
        @change="onChangeProvince"
        @input="$emit('input', value)"
        @blur="$emit('input', value)"
      />
    </v-col>

    <v-col cols="12">
      <div
        v-if="districtCaption"
        class="caption"
      >
        {{ districtCaption }}
      </div>
      <v-autocomplete
        :value="value.districtCode"
        :items="provinceDistrictList"
        :loading="isLoadingDistrict"
        :disabled="isLoadingDistrict || disabled"
        :label="districtLabel"
        :filter="customFilter"
        spellcheck="false"
        type="text"
        prepend-inner-icon="mdi-map-marker-radius"
        item-text="nameWithType"
        item-value="code"
        return-object
        v-bind="$attrs"
        @change="onChangeDistrict"
      />
    </v-col>
  </v-row>
</template>

<script>
import { removeAccents, removeSpecialCharaters } from '@/utils/string.util';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  components: {},
  props: {
    value: {
      type: Object,
      default: () => ({
        provinceCode: null,
        districtCode: null,
      }),
    },
    disabled: {
      type: Boolean,
    },
    autoSelectFirstDistrict: {
      type: Boolean,
      default: false,
      required: false,
    },
    noSelfLoadData: {
      type: Boolean,
      default: false,
      required: false,
    },
    provinceLabel: {
      type: String,
    },
    districtLabel: {
      type: String,
    },
    provinceCaption: {
      type: String,
    },
    districtCaption: {
      type: String,
    },
    errorMessages: {
      type: Array,
    },
  },
  data: () => ({
    isLoadingProvince: false,
    isLoadingDistrict: false,
  }),
  computed: {
    ...mapState('province', ['provinceList', 'provinceDistrictList']),
  },
  async created() {
    await this.loadProvince();
  },
  destroyed() {
    this.RESET();
  },
  watch: {
    'value.provinceCode': {
      async handler(code) {
        if (code) {
          await this.loadDistrict(code);
          this.$emit('change', {
            provinceCode: code,
            districtCode:
              this.provinceDistrictList.length > 0 &&
              this.provinceDistrictList[0].code,
          });
        }
      },
    },
  },
  methods: {
    ...mapMutations('province', ['RESET']),
    ...mapActions('province', [
      'fetchProvinceList',
      'fetchProvinceDistrictList',
    ]),
    customFilter(item, queryText, itemText) {
      const textOne = removeSpecialCharaters(
        removeAccents(item.name.toLowerCase())
      );
      const searchText = removeSpecialCharaters(
        removeAccents(queryText.toLowerCase())
      );

      return textOne.indexOf(searchText) > -1;
    },
    async onChangeProvince(value) {
      console.log('[LOG] : onChangeProvince', value.code);
      await this.loadDistrict(value.code);
      this.$emit('input', {
        provinceCode: value.code,
        districtCode:
          this.provinceDistrictList.length > 0 &&
          this.provinceDistrictList[0].code,
      });
    },
    async onChangeDistrict(value) {
      console.log('[LOG] : onChangeProvince', value.code);
      this.$emit('input', {
        provinceCode: this.value.provinceCode,
        districtCode: value.code,
      });
    },
    async loadProvince() {
      try {
        if (!this.noSelfLoadData) {
          this.isLoadingProvince = true;
          await this.fetchProvinceList();
        }
      } finally {
        this.isLoadingProvince = false;
      }
    },
    async loadDistrict(provinceCode) {
      try {
        this.isLoadingDistrict = true;
        await this.fetchProvinceDistrictList({ code: provinceCode });
      } finally {
        this.isLoadingDistrict = false;
      }
    },
  },
};
</script>

<style></style>
