import { render, staticRenderFns } from "./Login.vue?vue&type=template&id=265c5608&"
import script from "./Login.vue?vue&type=script&lang=js&"
export * from "./Login.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("F:\\Git\\booking-fe\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('265c5608')) {
      api.createRecord('265c5608', component.options)
    } else {
      api.reload('265c5608', component.options)
    }
    module.hot.accept("./Login.vue?vue&type=template&id=265c5608&", function () {
      api.rerender('265c5608', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/shared/global/DialogAuth/Login.vue"
export default component.exports