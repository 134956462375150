<template>
  <v-card
    rounded="lg"
    class="elevation-0 py-6"
    :class="{
      'elevation-2 px-4': !$vuetify.breakpoint.mobile,
    }"
  >
    <div class="mx-2 mt-4 mb-0">
      <v-img
        src="/assets/img/logo.png"
        height="70px"
        contain
      />
    </div>
    <v-toolbar flat>
      <v-toolbar-title class="overline mx-auto"> Xác thực </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="py-0">
      <v-form
        @submit="verifyOTP()"
        onSubmit="return false;"
      >
        <v-text-field
          label="Nhập mã xác thực"
          prepend-inner-icon="mdi-cellphone-key"
          type="text"
          inputmode="numeric"
          autocomplete="one-time-code"
          outlined
          autofocus
          :disabled="isLoading"
          v-model.trim="$v.otp.$model"
          hide-details
        />
      </v-form>
    </v-card-text>
    <div
      class="mx-4 my-2"
      style="font-size: 14px"
    >
      Không nhận được mã xác thực?
      <span><a @click="resendOTP()">Gửi lại</a></span>
    </div>
    <div
      id="recaptcha-container"
      class="d-flex justify-center align-center my-4"
    ></div>
    <v-card-actions class="d-flex flex-column px-4">
      <v-btn
        width="100%"
        x-large
        color="primary"
        depressed
        :loading="isLoading"
        :disabled="isLoading || $v.$invalid"
        @click="verifyOTP()"
        >Tiếp tục
      </v-btn>
      <v-btn
        width="100%"
        x-large
        depressed
        :disabled="isLoading"
        class="mx-0 my-2"
        to="/auth/signup"
        >Quay lại
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { mapActions, mapState } from 'vuex';
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';
const PhoneNumber = require('awesome-phonenumber');

export default {
  data: () => ({
    isLoading: false,
    isLoadingOTP: false,
    otp: '',
  }),
  validations: {
    otp: {
      required,
      minLength: minLength(1),
    },
  },
  computed: {
    ...mapState('ui', ['dialogAuth']),
    otpErrors() {
      const errors = [];
      const field = this.$v.otp;
      if (!field.$dirty) return errors;
      !field.minLength && errors.push('Tài khoản không hợp lệ');
      !field.required && errors.push('Tài khoản bắt buộc');
      return errors;
    },
    dialog: {
      get() {
        return this.dialogAuth;
      },
      set(value) {
        this.TOGGLE_DIALOG_AUTH(value);
      },
    },
  },
  created() {
    if (!window.confirmationResult || !this.$route.query.phone) {
      this.$router.push('/auth/signup');
    }
  },
  methods: {
    ...mapActions('auth', ['signupWithSms']),
    async resendOTP() {
      try {
        this.isLoadingOTP = true;
        await window.recaptchaVerifier.clear();
        const auth = getAuth();
        auth.languageCode = 'vi';

        window.recaptchaVerifier = new RecaptchaVerifier(
          'recaptcha-container',
          {
            size: 'normal',
            callback: (response) => {
              console.log('[LOG] : response', response);
            },
            'expired-callback': () => {},
          },
          auth
        );

        window.recaptchaVerifier.render();

        const pn = new PhoneNumber(this.$route.query.phone, 'VN');

        const appVerifier = window.recaptchaVerifier;
        const confirmationResult = await signInWithPhoneNumber(
          auth,
          pn.getNumber(),
          appVerifier
        );
        window.confirmationResult = confirmationResult;
        console.log('[LOG] : confirmationResult', confirmationResult);
      } catch (error) {
        const snackbarOption = {
          message: '',
          color: 'error',
        };
        if (error?.response?.status === 401) {
          snackbarOption.message = 'Thông tin đăng nhập không chính xác!';
        } else {
          console.log('[LOG] : error', error);
          snackbarOption.message = 'Có lỗi xảy ra!';
        }
        this.$store.dispatch('ui/showSnackbar', snackbarOption);
      } finally {
        this.isLoadingOTP = false;
      }
    },
    async verifyOTP() {
      console.log('verify OTP');
      try {
        this.isLoading = true;
        const result = await window.confirmationResult.confirm(this.otp);
        console.log('[LOG] : result', result);
        const token = await getAuth().currentUser.getIdToken(true);
        console.log('[LOG] : token', token);
        await this.signupWithSms({ token });
        this.$router.push(`/auth/signup/update-require-info`);
      } catch (error) {
        const snackbarOption = {
          message: '',
          color: 'error',
        };
        if (error?.code === 'auth/invalid-verification-code') {
          snackbarOption.message = 'Mã OTP không chính xác';
        } else if (error?.response?.data?.message) {
          snackbarOption.message = error.response.data.message;
        } else {
          console.log('[LOG] Error', error);
          snackbarOption.message = 'Có lỗi xảy ra';
        }
        this.$store.dispatch('ui/showSnackbar', snackbarOption);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
