<template>
  <v-card rounded>
    <v-card-title>
      <v-row no-gutters>
        <div>{{ $t('title-contact-delete') }}</div>
        <v-spacer />
        <div class="">
          <v-btn
            :disabled="isLoading"
            text
            class="mr-2"
            @click="onCloseDialog"
            >{{ $t('button-close') }}</v-btn
          >
          <v-btn
            :disabled="isLoading"
            :loading="isLoading"
            color="error"
            @click="onDeleteContact"
            >{{ $t('button-delete') }}</v-btn
          >
        </div>
      </v-row>
    </v-card-title>

    <v-card-text class="pb-6 pt-2">
      {{ $t('text-contact-delete-warn') }}
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    item: {
      type: Object,
    },
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {},
  async created() {},
  watch: {},
  methods: {
    ...mapActions('userContact', ['deleteContact', 'fetchContactList']),
    onCloseDialog() {
      this.$emit('close-dialog');
    },
    async onDeleteContact() {
      try {
        this.isLoading = true;
        await this.deleteContact({
          id: this.item.id,
        });
        await this.fetchContactList();
        this.$message.success(this.$t('text-contact-delete-success'));
        this.$emit('close-dialog');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
