import axios from 'axios';

const CancelToken = axios.CancelToken;
export const source = CancelToken.source();

export const api = axios.create({ baseURL: process.env.VUE_APP_BASE_API });
export const vplApi = axios.create({ baseURL: process.env.VUE_APP_VPL_API });
export const vslApi = axios.create({ baseURL: process.env.VUE_APP_VSL_API });

///////////////////
api.interceptors.request.use(
  (request) => {
    request.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'accessToken'
    )}`;
    request.metadata = {
      startTime: new Date(),
      delayed: request.headers.common?.delayed,
    };
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    response.config.metadata.endTime = new Date();
    response.duration =
      response.config.metadata.endTime - response.config.metadata.startTime;

    const isDelayed = response.config.metadata.delayed;

    if (isDelayed && response.duration < 1000) {
      return new Promise((resolve) =>
        setTimeout(() => resolve(response), 1000 - response.duration)
      );
    }
    return response;
  },
  (error) => {
    error.config.metadata.endTime = new Date();
    error.duration =
      error.config.metadata.endTime - error.config.metadata.startTime;
    if (error?.response?.status === 401) {
      delete api.defaults.headers.common.Authorization;
      localStorage.removeItem('accessToken');
      if (window.location.pathname !== '/auth/login') {
        window.location.href = process.env.VUE_APP_BASE_URL + '/auth/login';
      }
    }
    const isDelayed = error.config.metadata.delayed;

    if (isDelayed && error.duration < 1000) {
      return new Promise((resolve) =>
        setTimeout(() => resolve(Promise.reject(error)), 1000 - error.duration)
      );
    }
    return Promise.reject(error);
  }
);

vplApi.interceptors.request.use(
  (request) => {
    request.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'accessToken'
    )}`;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

vplApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      delete api.defaults.headers.common.Authorization;
      localStorage.removeItem('accessToken');
      if (window.location.pathname !== '/auth/login') {
        window.location.href = process.env.VUE_APP_BASE_URL + '/auth/login';
      }
    }
    return Promise.reject(error);
  }
);

vslApi.interceptors.request.use(
  (request) => {
    request.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'accessToken'
    )}`;
    request.metadata = {
      startTime: new Date(),
      delayed: request.headers.common?.delayed,
    };

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

vslApi.interceptors.response.use(
  (response) => {
    response.config.metadata.endTime = new Date();
    response.duration =
      response.config.metadata.endTime - response.config.metadata.startTime;

    const isDelayed = response.config.metadata.delayed;

    if (isDelayed && response.duration < 1000) {
      return new Promise((resolve) =>
        setTimeout(() => resolve(response), 1000 - response.duration)
      );
    }
    return response;
  },
  (error) => {
    error.config.metadata.endTime = new Date();
    error.duration =
      error.config.metadata.endTime - error.config.metadata.startTime;
    if (error?.response?.status === 401) {
      delete api.defaults.headers.common.Authorization;
      localStorage.removeItem('accessToken');
      if (window.location.pathname !== '/auth/login') {
        window.location.href = process.env.VUE_APP_BASE_URL + '/auth/login';
      }
    }
    const isDelayed = error.config.metadata.delayed;

    if (isDelayed && error.duration < 1000) {
      return new Promise((resolve) =>
        setTimeout(() => resolve(Promise.reject(error)), 1000 - error.duration)
      );
    }
    return Promise.reject(error);
  }
);
