import DebitApi from '@/api/vsl/debit.api';

const initialState = () => ({
  debitInfo: {
    id: null,
    no: null,
    searchingType: null,
    availableFiles: [],
  },
});

const state = initialState();
const getters = {};
const mutations = {
  RESET(state, payload) {
    const initStates = initialState();
    if (!payload) {
      Object.keys(initStates).map((key) => {
        state[key] = initStates[key];
      });
    } else {
      const statesToReset = payload;
      statesToReset.map((key) => {
        state[key] = initStates[key];
      });
    }
  },
  SET_DEBIT_INFO(state, payload) {
    state.debitInfo = payload;
  },
};
const actions = {
  async fetchDebitInfo({ commit }, payload) {
    const { debitSearchNo } = payload;
    const res = await DebitApi.getDebitInfo({ debitSearchNo });
    const { data } = res.data;
    commit('SET_DEBIT_INFO', {
      id: data.id,
      recipientId: data.to_send_recipient_id,
      searchingType: data.searching_type,
      availableFiles: data.available_files,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
