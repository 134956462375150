var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { display: "flex", "justify-content": "center" } },
    [
      [
        _c("div", { staticStyle: { width: "100%" } }, [
          _vm._m(0),
          _c(
            "div",
            { staticStyle: { display: "flex", "justify-content": "end" } },
            [
              _c("v-pagination", {
                attrs: { value: 1, length: 2 },
                model: {
                  value: _vm.page,
                  callback: function ($$v) {
                    _vm.page = $$v
                  },
                  expression: "page",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { margin: "20px 0" } },
            [
              _c(
                "a-card",
                {
                  staticStyle: {
                    margin: "20px 0",
                    "box-shadow": "0 25px 25px -12px rgb(0 0 0 / 0.25)",
                    "border-radius": "20px",
                  },
                  attrs: { width: "100%" },
                  on: { click: _vm.onClickBookingDetail },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c("span", [
                                    _c("img", {
                                      staticStyle: { height: "20px" },
                                      attrs: {
                                        src: "/assets/img/location-start.png",
                                      },
                                    }),
                                    _vm._v(" Hà Nội "),
                                  ]),
                                  _c("hr", {
                                    staticStyle: {
                                      width: "20px",
                                      margin: "0 5px",
                                    },
                                  }),
                                  _c("span", [
                                    _c("img", {
                                      staticStyle: { height: "20px" },
                                      attrs: {
                                        src: "/assets/img/location-end.png",
                                      },
                                    }),
                                    _vm._v(" Hồ Chí Minh "),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-right": "10px" } },
                                    [_vm._v("20/3/2024")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticStyle: {
                                        color: "#aec76d",
                                        "background-color": "#f0f9eb",
                                        "text-transform": "none",
                                      },
                                    },
                                    [_vm._v(" Done ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  [
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c("div", { staticStyle: { width: "20%" } }, [
                        _c("div", [_vm._v("#OD00051")]),
                        _c("div", { staticStyle: { "font-weight": "900" } }, [
                          _vm._v("40HC"),
                        ]),
                      ]),
                      _c("div", { staticStyle: { width: "70%" } }, [
                        _c("div", [
                          _c("span", [_vm._v(" Commodity ")]),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-left": "5px",
                                "font-weight": "900",
                              },
                            },
                            [_vm._v("Thực phẩm")]
                          ),
                        ]),
                        _c("div", [
                          _c("span", [_vm._v("Delivery date")]),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-left": "5px",
                                "font-weight": "900",
                              },
                            },
                            [_vm._v("18/03/2024")]
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "10%",
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "end",
                            color: "#2e90fa",
                            "font-size": "23px",
                            "font-weight": "600",
                          },
                        },
                        [_vm._v(" $100 ")]
                      ),
                    ]),
                  ],
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "end",
                "margin-top": "40px",
              },
            },
            [
              _c("v-pagination", {
                attrs: { value: 1, length: 2 },
                model: {
                  value: _vm.page,
                  callback: function ($$v) {
                    _vm.page = $$v
                  },
                  expression: "page",
                },
              }),
            ],
            1
          ),
        ]),
      ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticStyle: { display: "flex", "justify-content": "start" } },
      [
        _c(
          "span",
          {
            staticStyle: { "text-transform": "uppercase", "font-size": "35px" },
          },
          [_vm._v("Bookings")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }