<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    scrollable
    persistent
    max-width="1000px"
    transition="dialog-transition"
  >
    <UserSettings
      v-if="dialog"
      @close-dialog="dialog = false"
    />
  </v-dialog>
</template>

<script>
import UserSettings from '@/components/shared/local/base/UserSettings';
import { mapMutations, mapState } from 'vuex';

export default {
  components: {
    UserSettings,
  },
  computed: {
    ...mapState('ui', ['dialogUserSettings']),
    dialog: {
      get() {
        return this.dialogUserSettings.status;
      },
      set(value) {
        this.TOGGLE_DIALOG_USER_SETTINGS({
          status: value,
        });
      },
    },
  },
  methods: {
    ...mapMutations('ui', ['TOGGLE_DIALOG_USER_SETTINGS']),
  },
};
</script>

<style></style>
