<template>
  <v-card
    class="py-1 px-2"
    rounded="lg"
  >
    <v-card-text
      class="pt-1"
      :class="{
        'pa-2': $vuetify.breakpoint.mobile,
      }"
    >
      <v-tabs
        v-model="tab"
        class="mb-4"
        height="36px"
      >
        <v-tab :ripple="false">{{ $t('title-find-by-port') }}</v-tab>
        <v-tab :ripple="false">{{ $t('title-find-by-voyage') }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          key="1"
          eager
        >
          <v-form
            v-if="!isSearch"
            @submit="searchSchedule"
            onSubmit="return false;"
          >
            <v-row no-gutters>
              <v-col :cols="12">
                <v-row no-gutters>
                  <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
                    <v-row
                      no-gutters
                      align="end"
                    >
                      <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 5">
                        <div class="caption">{{ $t('from') }}</div>
                        <PortPicker
                          :value="selectedPolCode"
                          @change="onSelectPOL"
                          solo
                          :label="$t('label-pol')"
                          clearable
                          hide-details
                          outlined
                          dense
                        />
                      </v-col>

                      <v-col
                        cols="1"
                        v-if="!$vuetify.breakpoint.mobile"
                        class="d-flex align-center justify-center"
                      >
                        <v-icon
                          color="primary"
                          class="mb-2"
                        >
                          mdi-swap-horizontal-circle
                        </v-icon>
                      </v-col>

                      <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 5">
                        <div class="caption">{{ $t('to') }}</div>
                        <PortPicker
                          :value="selectedPodCode"
                          @change="onSelectPOD"
                          solo
                          :label="$t('label-pod')"
                          clearable
                          hide-details
                          outlined
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 3">
                    <v-dialog
                      ref="dialog"
                      v-model="modal"
                      persistent
                      max-width="670px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div>
                          <div class="caption">{{ $t('etd-eta') }}</div>
                          <v-text-field
                            :value="dateRangeText"
                            label="ETD - ETA"
                            hide-details
                            prepend-inner-icon="mdi-calendar-month"
                            readonly
                            solo
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                          />
                        </div>
                      </template>
                      <v-date-picker
                        v-model="dates"
                        @change="onChangeDates"
                        range
                        :landscape="!$vuetify.breakpoint.mobile"
                        full-width
                        :allowedDates="allowedDates"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modal = false"
                        >
                          {{ $t('button-cancel') }}
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="onSelectEtdEta"
                        >
                          {{ $t('button-select') }}
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col
                    :cols="$vuetify.breakpoint.mobile ? 12 : 3"
                    :class="{
                      'pl-16': !$vuetify.breakpoint.mobile,
                    }"
                  >
<!--                    <div class="caption">{{ $t('vessel') }}</div>-->
<!--                    <VesselPicker-->
<!--                      :value="selectedVesselCode"-->
<!--                      @change="onSelectVessel"-->
<!--                      :label="$t('label-vessel-picker')"-->
<!--                      clearable-->
<!--                      hide-details-->
<!--                      solo-->
<!--                      outlined-->
<!--                      dense-->
<!--                    />-->
                    <v-btn
                        class="px-20 mt-4"
                        style="text-transform: none;"
                        color="info"
                        width="100%"
                        large
                        :loading="isLoading"
                        @click="searchSchedule"
                    >
                      <v-icon left> mdi-magnify </v-icon>
                      {{ $t('search') }}
                    </v-btn>
                  </v-col>

                </v-row>
              </v-col>
            </v-row>
          </v-form>
          <v-form
              v-if="isSearch"
              @submit="searchScheduleExtend"
              onSubmit="return false;"
          >
            <v-row no-gutters>
              <v-col :cols="12">
                <v-row no-gutters>
                  <v-col :cols="12">
                    <v-row
                        no-gutters
                        align="end"
                    >
                      <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
                        <div class="caption">{{ $t('from') }}</div>
                        <PortPicker
                            :value="selectedPolCode"
                            @change="onSelectPOL"
                            solo
                            :label="$t('label-pol')"
                            clearable
                            hide-details
                            outlined
                            dense
                        />
                      </v-col>

                      <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6"
                             :class="{
                              'pl-5': !$vuetify.breakpoint.mobile,
                             }"
                      >
                        <div class="caption">{{ $t('to') }}</div>
                        <PortPicker
                            :value="selectedPodCode"
                            @change="onSelectPOD"
                            solo
                            :label="$t('label-pod')"
                            clearable
                            hide-details
                            outlined
                            dense
                        />
                      </v-col>
                    </v-row>
                  </v-col>

                </v-row>
              </v-col>
              <v-col :cols="12">
                <v-row no-gutters class="pt-5">
                  <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 2">
                    <v-dialog
                        ref="dialog"
                        v-model="modalF"
                        persistent
                        max-width="670px"
                    >
                      <template v-slot:activator="{ on, attrsF }">
                        <div>
                          <div class="caption">{{ $t('pickup-date') }}</div>
                          <v-text-field
                              :value="dateFromText"
                              label="ETD"
                              hide-details
                              prepend-inner-icon="mdi-calendar-month"
                              readonly
                              solo
                              outlined
                              dense
                              v-bind="attrsF"
                              v-on="on"
                          />
                        </div>
                      </template>
                      <v-date-picker
                          v-model="datesFrom"
                          @change="onChangeFromDates"
                          :landscape="!$vuetify.breakpoint.mobile"
                          full-width
                          :allowedDates="allowedDates"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="modalF = false"
                        >
                          {{ $t('button-cancel') }}
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="onSelectFromEtdEta"
                        >
                          {{ $t('button-select') }}
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>

                  <!--                  Delivery date-->
                  <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 2 "
                         :class="{
                        'pl-5': !$vuetify.breakpoint.mobile,
                      }">
                    <v-dialog
                        ref="dialog"
                        v-model="modalT"
                        persistent
                        max-width="670px"
                    >
                      <template v-slot:activator="{ on, attrsT }">
                        <div>
                          <div class="caption">{{ $t('delivery-date') }}</div>
                          <v-text-field
                              :value="dateToText"
                              label="ETA"
                              hide-details
                              prepend-inner-icon="mdi-calendar-month"
                              readonly
                              solo
                              outlined
                              dense
                              v-bind="attrsT"
                              v-on="on"
                          />
                        </div>
                      </template>
                      <v-date-picker
                          v-model="datesTo"
                          @change="onChangeToDates"
                          :landscape="!$vuetify.breakpoint.mobile"
                          full-width
                          :allowedDates="allowedDates"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="modalT = false"
                        >
                          {{ $t('button-cancel') }}
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="onSelectToEtdEta"
                        >
                          {{ $t('button-select') }}
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>

                  <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 3"
                      :class="{
                        'pl-5': !$vuetify.breakpoint.mobile,
                      }"
                  >
                    <div class="caption">{{ $t('container-type') }}</div>
                    <ContainerPicker
                        :value="selectedContainerCode"
                        :placeholder="$t('button-select')"
                        @change="onSelectContainer"
                        clearable
                        hide-details
                        solo
                        outlined
                        dense
                    />
                  </v-col>

                  <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 2"
                      :class="{
                        'pl-5': !$vuetify.breakpoint.mobile,
                      }"
                  >
                    <div>
                      <div class="caption">{{ $t('quantity') }}</div>
                      <v-text-field
                          :value="dateRangeText"
                          :placeholder="$t('button-select')"
                          type="number"
                          solo
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                      />
                    </div>
                  </v-col>

                  <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 3"
                      :class="{
                      'pl-5': !$vuetify.breakpoint.mobile,
                    }"
                  >
                    <div class="caption">{{ $t('commodity') }}</div>
                    <CommodityPicker
                        :value="selectedCommodityCode"
                        :placeholder="$t('button-select')"
                        @change="onSelectedCommodity"
                        clearable
                        hide-details
                        solo
                        outlined
                        dense
                    />
                  </v-col>

                </v-row>
              </v-col>

              <v-col :cols="12">
                <div width="100%" align="center" style="gap: 5px; border-bottom: 1px solid rgb(220, 223, 230);"/>
                <div
                    class="text-right"
                >
                  <v-btn
                      style="text-transform: none;"
                      class="px-20 mt-4 ml-36"
                      color="info"
                      width="20%"
                      large
                      :loading="isLoading"
                      @click="searchScheduleExtend"
                  >
                    <v-icon left> mdi-magnify </v-icon>
                    {{ $t('search') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-tab-item>
        <v-tab-item
          key="2"
          eager
        >
          <v-form
            @submit="searchSchedule"
            onSubmit="return false;"
          >
            <v-row no-gutters>
              <v-col :cols="12">
                <div class="caption">{{ $t('voyage') }}</div>
                <v-text-field
                  v-model="typedSearch"
                  :label="$t('enter-voyage-code')"
                  prepend-inner-icon="mdi-compass"
                  type="text"
                  solo
                  flat
                  outlined
                  dense
                  hide-details
                  clearable
                />
              </v-col>
              <v-col :cols="12">
                <div
                    class="text-right"
                >
                  <v-btn
                      style="text-transform: none;"
                      class="px-20 mt-4 ml-36"
                      color="info"
                      width="100%"
                      large
                      :loading="isLoading"
                      @click="searchSchedule"
                  >
                    <v-icon left> mdi-magnify </v-icon>
                    {{ $t('search') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-tab-item>
      </v-tabs-items>
<!--      <v-row-->
<!--        no-gutters-->
<!--        align="center"-->
<!--        class="mt-4 font-italic text&#45;&#45;secondary"-->
<!--      >-->
<!--        <span class="mr-1">{{ $t('text-voyage-request') }}</span>-->
<!--        <a-->
<!--          href=""-->
<!--          @click.prevent="dialog.request = true"-->
<!--        >-->
<!--          {{ $t('button-voyage-request') }}</a-->
<!--        >-->
<!--      </v-row>-->
    </v-card-text>
    <v-dialog
      v-model="dialog.request"
      scrollable
      persistent
      max-width="900px"
      :fullscreen="$vuetify.breakpoint.mobile"
      transition="dialog-transition"
    >
      <VesselScheduleSearchRequest
        v-if="dialog.request"
        @close-dialog="dialog.request = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapMutations, mapState } from 'vuex';
import PortPicker from '@/components/shared/local/vsl/PortPicker.vue';
import VesselPicker from '@/components/shared/local/vsl/VesselPicker.vue';
import VesselScheduleSearchRequest from './VesselScheduleSearchRequest.vue';
import ContainerPicker from "@/components/shared/local/vsl/ContainerPicker.vue";
import CommodityPicker from "@/components/shared/local/vsl/CommodityPicker.vue";

export default {
  inject: ['setIsSearched'],
  components: {CommodityPicker, ContainerPicker, PortPicker, VesselPicker, VesselScheduleSearchRequest },
  data: () => ({
    tab: 0,
    dialog: {
      request: false,
    },
    dates: [dayjs().format('YYYY-MM-DD')],
    datesFrom: dayjs().format('YYYY-MM-DD'),
    datesTo: dayjs().format('YYYY-MM-DD'),
    modal: false,
    modalF: false,
    modalT: false,
    isExpandFilter: false,
    typedSearch: '',
    selectedVesselCode: null,
    selectedContainerCode: null,
    selectedCommodityCode: null,
    selectedEtdEta: [],
    selectedFromEtdEta: null,
    selectedToEtdEta: null,
    selectedPolCode: null,
    selectedPodCode: null,
    isSearch: false,
  }),
  computed: {
    ...mapState('vsl/vesselSchedule', {
      queryParams: 'vesselScheduleListQueryParams',
      vesselScheduleListLoading: 'vesselScheduleListLoading',
    }),
    isLoading: {
      get() {
        return this.vesselScheduleListLoading;
      },
      set(value) {
        this.SET_VESSEL_SCHEDULE_LIST_LOADING(value);
      },
    },
    dateRangeText() {
      const formated = this.selectedEtdEta.map((date) => {
        return dayjs(date).format('DD.MM.YYYY');
      });
      return formated.join(' - ');
    },
    dateFromText() {
      if(this.datesFrom){
        return dayjs(this.datesFrom).format('DD.MM.YYYY');
      }else{
        return "";
      }
    },
    dateToText() {
      if(this.datesTo){
        return dayjs(this.datesTo).format('DD.MM.YYYY');
      }else{
        return "";
      }
    },
    advancedFilterCount() {
      const filters = {
        selectedVesselCode: this.selectedVesselCode,
        selectedPolCode: this.selectedPolCode,
        selectedPodCode: this.selectedPodCode,
      };
      console.log('[LOG] : filters', filters);
      const count = Object.keys(filters).filter(
        (x) => filters[x] != undefined
      ).length;
      console.log('[LOG] : count', count);
      return count;
    },
  },
  created() {
    let { search, vessel_code, pol_code, pod_code, from_etd, to_eta } =
      this.$route.query;
    if (to_eta) {
      this.selectedEtdEta = [from_etd, to_eta];
      this.selectedFromEtdEta = from_etd;
      this.selectedToEtdEta = to_eta;
    } else {
      this.selectedEtdEta = [from_etd];
      this.selectedFromEtdEta = from_etd;
    }
    this.typedSearch = search;
    this.selectedVesselCode = vessel_code;
    this.selectedPolCode = pol_code;
    this.selectedPodCode = pod_code;
  },
  watch: {
    typedSearch(value) {
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({
        voyageCode: value,
      });
    },
  },
  methods: {
    ...mapMutations('vsl/vesselSchedule', [
      'SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS',
      'SET_VESSEL_SCHEDULE_LIST_LOADING',
    ]),
    ...mapActions('vsl/vesselSchedule', ['searchVesselSchedule']),
    allowedDates(value) {
      return dayjs(value) > dayjs().subtract(1, 'day');
    },
    async searchScheduleExtend(){

    },
    async searchSchedule() {
      try {
        this.isSearch=true;
        this.setIsSearched(true);
        this.isLoading = true;

        if (this.tab === 0) {
          if (!this.queryParams.polCode || !this.queryParams.podCode) {
            this.$message.error(this.$t('validation-message-pol-pod-required'));
            return;
          }
          if (this.queryParams.polCode === this.queryParams.podCode) {
            this.$message.error(this.$t('validation-message-pol-pod-not-same'));
            return;
          }
          await this.searchVesselSchedule({ searchType: 'portSearching' });
        }
        if (this.tab === 1) {
          if (!this.queryParams.voyageCode) {
            this.$message.error(
              this.$t('validation-message-voyage-code-required')
            );
            return;
          }
          await this.searchVesselSchedule({
            searchType: 'voyageCodeSearching',
          });
        }

        await this.$router
          .push({
            query: {
              voyage_code: this.queryParams.voyageCode,
              vessel_code: this.queryParams.vesselCode,
              pol_code: this.queryParams.polCode,
              pod_code: this.queryParams.podCode,
              from_etd: this.queryParams.etdEta[0]
                ? this.queryParams.etdEta[0]
                : undefined,
              to_etd: this.queryParams.etdEta[1]
                ? this.queryParams.etdEta[1]
                : undefined,
            },
          })
          .catch(() => {});

        this.setIsSearched(true);
      } finally {
        this.isLoading = false;
      }
    },
    async onSelectVessel(item) {
      this.selectedVesselCode = item?.code;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({ vesselCode: item?.code });
    },
    async onSelectContainer(item) {
      this.selectedContainerCode = item?.code;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({ vesselCode: item?.code });
    },
    async onSelectCommodity(item) {
      this.selectedCommodityCode = item?.code;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({ vesselCode: item?.code });
    },
    async onSelectPOL(item) {
      this.selectedPolCode = item?.code;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({ polCode: item?.code });
    },
    async onSelectPOD(item) {
      this.selectedPodCode = item?.code;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({ podCode: item?.code });
    },
    onChangeDates(value) {
      console.log(value);
      this.dates = value;
    },
    onChangeFromDates(value) {
      console.log(value);
      this.datesFrom = value;
      this.dates = [value];
    },
    onChangeToDates(value) {
      console.log(value);
      this.datesTo = value;
      this.dates = [this.datesFrom, value];
    },
    async onSelectFromEtdEta() {
      this.modalF = false;
      if (this.datesTo) {
        if (dayjs(this.datesFrom).isAfter(dayjs(this.datesTo))) {
          this.selectedFromEtdEta = this.datesTo;
          this.datesFrom = this.datesTo;
        }
      }
      this.selectedFromEtdEta = this.datesFrom;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({
        etdEta: [this.selectedFromEtdEta],
      });
    },
    async onSelectToEtdEta() {
      this.modalT = false;
      if (this.datesTo) {
        if (dayjs(this.datesFrom).isAfter(dayjs(this.datesTo))) {
          this.selectedToEtdEta = this.datesFrom;
          this.datesTo = this.datesFrom;
        }
      }
      console.log(this.dates);
      this.selectedToEtdEta = this.datesTo;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({
        etdEta: [this.datesFrom, this.datesTo],
      });
    },
    async onSelectEtdEta() {
      this.modal = false;
      if (this.dates.length > 1) {
        if (dayjs(this.dates[0]).isAfter(dayjs(this.dates[1]))) {
          this.dates = [this.dates[1], this.dates[0]];
        }
      }
      console.log(this.dates);
      this.selectedEtdEta = this.dates;
      this.datesFrom = this.dates[0];
      this.datesTo = this.dates[1];
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({
        etdEta: this.selectedEtdEta,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .v-tab {
    text-transform: initial;
  }

  .v-slide-group__prev {
    display: none !important;
  }
}
</style>
