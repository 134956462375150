<template>
  <v-card
    class="px-4"
    rounded="lg"
    flat
  >
    <div
      v-for="(schedule, index) of pathSchedules"
      :key="index"
    >
      <template v-if="pathSchedules.length > 1">
        <v-card
          v-if="index !== 0"
          color="orange darken-1"
          rounded="lg"
          class="py-1 px-2 my-2"
        >
          <v-row
            no-gutters
            align="center"
            class="white--text"
          >
            <div>
              <v-icon
                small
                dark
                >mdi-swap-vertical-circle</v-icon
              >
              {{ $t('text-transiting-to-voyage') }}
            </div>
            <div
              class="text-left"
              style="font-size: 12px; line-height: 1.75"
            >
              <span class="px-1">
                <v-icon
                  small
                  dark
                  >mdi-compass</v-icon
                >
                {{ schedule.voyageCode }}
              </span>
            </div>
          </v-row>
        </v-card>
        <v-card
          v-else
          color="cyan darken-1"
          rounded="lg"
          class="py-1 px-2 my-2"
        >
          <v-row
            no-gutters
            align="center"
            class="white--text"
          >
            <div>
              <v-icon
                small
                dark
                >mdi-chevron-up-circle</v-icon
              >
              {{ $t('text-starting-from-voyage') }}
            </div>
            <div
              class="text-left"
              style="font-size: 12px; line-height: 1.75"
            >
              <span class="px-1">
                <v-icon
                  small
                  dark
                  >mdi-compass</v-icon
                >
                {{ schedule.voyageCode }}
              </span>
            </div>
          </v-row>
        </v-card>
      </template>

      <v-card
        style="overflow: hidden"
        color="background"
        rounded="lg"
        flat
      >
        <v-card
          class="white--text py-1"
          color="grey darken-3"
          tile
        >
          <div
            class="text-left"
            style="font-size: 12px; line-height: 1.75"
          >
            <span class="px-2">
              <v-icon
                small
                dark
                >mdi-compass</v-icon
              >

              {{ schedule.voyageCode }}
            </span>
            <span>
              <v-icon
                small
                dark
                >mdi-ferry</v-icon
              >

              {{ schedule.vesselName }}
            </span>
          </div>
        </v-card>
        <v-stepper
          vertical
          elevation="0"
        >
          <v-stepper-step
            complete
            :step="1"
            :complete-icon="
              index === 0 ? 'mdi-map-marker' : 'mdi-radiobox-marked'
            "
          >
            <div class="text-left text-uppercase w-100">
              <div
                class="text--secondary overline"
                style="font-size: 12px; line-height: 1rem"
              >
                {{ schedule.etd && $filters.formatDatetime(schedule.etd) }}
              </div>
              <div
                class="text--primary my-1 text-uppercase text-truncate"
                style="font-weight: 600"
              >
                {{ schedule.polCode + ' - ' + schedule.polName }}
              </div>
              <div
                class="text--secondary text-uppercase"
                style="font-size: 12px"
              >
                {{ schedule.polTerminalName }}
              </div>
            </div>
          </v-stepper-step>
          <v-stepper-content :step="1"> </v-stepper-content>

          <v-stepper-step
            complete
            :step="2"
            :complete-icon="
              index === pathSchedules.length - 1
                ? 'mdi-map-marker-radius'
                : 'mdi-radiobox-marked'
            "
          >
            <div class="text-left text-uppercase w-100">
              <div
                class="text--secondary overline"
                style="font-size: 12px; line-height: 1rem"
              >
                {{ schedule.eta && $filters.formatDatetime(schedule.eta) }}
              </div>
              <div
                class="text--primary my-1 text-uppercase text-truncate"
                style="font-weight: 600"
              >
                {{ schedule.podCode + ' - ' + schedule.podName }}
              </div>
              <div
                class="text--secondary text-uppercase"
                style="font-size: 12px"
              >
                {{ schedule.podTerminalName }}
              </div>
            </div>
          </v-stepper-step>
        </v-stepper>
      </v-card>
    </div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: ['loading', 'pathSchedules'],
  data: () => ({}),
  computed: {},

  created() {},
  methods: {
    isActive(index) {
      return true;
      // const currentPortCodeIndex = this.portOfCalls.findIndex(
      //   poc => poc.portCode === this.currentPortCode
      // );

      // if (currentPortCodeIndex !== -1 && index >= currentPortCodeIndex) {
      //   return true;
      // }
      // return false;
    },
  },
};
</script>

<style scoped lang="scss">
:deep {
  .theme--light.v-stepper {
    background: #ffffff00;
  }

  .v-stepper--vertical .v-stepper__step__step {
    background: none !important;
  }

  .mdi-map-marker-radius::before {
    color: var(--v-error-darken1);
    text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff,
      1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
    font-size: 26px;
  }

  .mdi-radiobox-marked::before {
    color: #fb8c00;
    text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff,
      1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
    font-size: 20px;
  }
  .mdi-map-marker::before {
    color: #00acc1;
    text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff,
      1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
    font-size: 26px;
  }

  .v-stepper--vertical {
    padding-bottom: 0;
  }
}
</style>
