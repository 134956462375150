import QuotationApi from '@/api/vsl/quotation.api';
import VesselScheduleApi from '@/api/vsl/vessel-schedule.api';
import { nanoid } from 'nanoid';
import { DEFAULT_AVATAR } from '@/constants/common.constant';
import _ from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Ho_Chi_Minh');

const initialState = () => ({
  requestQuotation: {
    vesselScheduleCodes: [],
    polCode: null,
    podCode: null,
    polTermCode: 'CY',
    podTermCode: 'CY',
    senderName: '',
    senderEmail: '',
    senderPhone: '',
    senderAddress: '',
    senderNote: '',
    commodities: [],
  },
  myQuotation: {
    id: null,
    code: null,
    voyageCode: null,
    vesselName: '',
    etd: null,
    eta: null,
    polCode: null,
    polName: '',
    polCountryCode: null,
    polTerminalName: '',
    polTerminalCode: null,
    podCode: null,
    podName: '',
    podCountryCode: null,
    podTerminalName: '',
    podTerminalCode: null,
    status: null,
    senderName: '',
    senderEmail: '',
    senderPhone: '',
    senderAddress: '',
    senderNote: '',
    polTermCode: null,
    podTermCode: null,
    version: null,
  },
  myQuotationList: [],
  myQuotationActionLogList: [],
  myQuotationCommodityList: [],
  myQuotationSurchargeList: [],
  myQuotationCommentList: [],
  isEditableMyQuotationCommodity: false,
  myQuotationListQueryParams: {
    search: null,
    page: null,
    size: null,
    voyageCode: null,
    vesselCode: null,
    status: null,
    polCode: null,
    podCode: null,
    polTermCode: null,
    podTermCode: null,
    fromCreatedAt: null,
    toCreatedAt: null,
    fromEta: null,
    toEta: null,
    fromEtd: null,
    toEtd: null,
  },
  myQuotationTotal: 0,
  myQuotationTotalPage: 0,
});

const state = initialState();
const getters = {};
const mutations = {
  RESET(state, payload) {
    const initStates = initialState();
    if (!payload) {
      Object.keys(initStates).map((key) => {
        state[key] = initStates[key];
      });
    } else {
      const statesToReset = payload;
      statesToReset.map((key) => {
        state[key] = initStates[key];
      });
    }
  },
  SET_REQUEST_QUOTATION(state, payload) {
    Object.keys(payload).map((key) => {
      state.requestQuotation[key] = payload[key];
    });
  },
  SET_MY_QUOTATION(state, payload) {
    state.myQuotation = payload;
  },
  SET_MY_QUOTATION_LIST(state, payload) {
    state.myQuotationList = payload;
  },
  SET_MY_QUOTATION_ACTION_LOG_LIST(state, payload) {
    state.myQuotationActionLogList = payload;
  },
  SET_MY_QUOTATION_COMMODITY_LIST(state, payload) {
    state.myQuotationCommodityList = payload;
  },
  SET_MY_QUOTATION_SURCHARGE_LIST(state, payload) {
    state.myQuotationSurchargeList = payload;
  },
  SET_IS_EDITABLE_MY_QUOTATION_COMMODITY(state, payload) {
    state.isEditableMyQuotationCommodity = payload;
  },
  SET_MY_QUOTATION_COMMENT_LIST(state, payload) {
    state.myQuotationCommentList = payload;
  },
  SET_LIST_QUERY_PARAMS(state, payload) {
    state.myQuotationListQueryParams = {
      ...state.myQuotationListQueryParams,
      ...payload,
    };
  },
  SET_MY_QUOTATION_TOTAL(state, payload) {
    state.myQuotationTotal = payload;
  },
  SET_MY_QUOTATION_TOTAL_PAGE(state, payload) {
    state.myQuotationTotalPage = payload;
  },
};
const actions = {
  async createRequestQuotation({ state, commit }, payload) {
    const { requestQuotation } = state;
    const mappedCommodities = requestQuotation.commodities.map((item) => {
      return {
        id: item.id,
        commodity_code: item.commodityCode,
        name: item.name,
        weight: item.weight,
        temper: item.temper,
        size: item.size,
        prepaid: item.prepaid,
        price: item.price,
        total: item.total,
        currency_code: item.currencyCode,
        container_size_code: item.containerCode,
        coc_soc: item.cocSoc,
        empty_laden: item.emptyLaden,
        container_mixed_quantity: Number(item.containerMixedQuantity),
        container_new_quantity: Number(item.containerNewQuantity),
        quantity:
          Number(item.containerMixedQuantity) +
          Number(item.containerNewQuantity),
        tariff_id: item.tariffId,
        tariff_price: item.tariffPrice,
        user_tariff_id: item.userTariffId,
      };
    });
    const body = {
      vessel_schedule_codes: requestQuotation.vesselScheduleCodes,
      pol_term_code: requestQuotation.polTermCode,
      pod_term_code: requestQuotation.podTermCode,
      sender_name: requestQuotation.senderName,
      sender_email: requestQuotation.senderEmail,
      sender_phone: requestQuotation.senderPhone,
      sender_address: requestQuotation.senderAddress,
      sender_note: requestQuotation.senderNote,
      commodities: mappedCommodities,
    };

    const res = await QuotationApi.createRequestQuotation(body);
  },
  async calculateCommodityPrices({ state, commit, rootState }, payload) {
    const { polCode, podCode } = state.requestQuotation;
    const { polTermCode, podTermCode } = state.requestQuotation;
    const commodities = payload.map((item) => {
      return {
        id: nanoid(),
        name: item.name,
        commodity_code: item.commodityCode,
        container_size_code: item.containerCode,
        coc_soc: item.cocSoc,
        empty_laden: item.emptyLaden,
        container_mixed_quantity: item.containerMixedQuantity,
        container_new_quantity: item.containerNewQuantity,
        quantity:
          Number(item.containerMixedQuantity) +
          Number(item.containerNewQuantity),
        prepaid: item.prepaid,
        size: item.size,
        weight: item.weight,
        temper: '',
      };
    });
    const body = {
      pol_code: polCode,
      pod_code: podCode,
      pol_term_code: polTermCode,
      pod_term_code: podTermCode,
      commodities,
    };

    const res = await QuotationApi.calculateCommodityPrices(body);
    const mapped = res.data.data.commodities.map((item) => {
      const mappedItem = {
        id: item.id,
        commodityCode: item.commodity_code,
        name: item.name,
        weight: item.weight,
        temper: item.temper,
        size: item.size,
        prepaid: item.prepaid,
        price: item.price,
        total: item.total,
        currencyCode: item.currency_code ? item.currency_code : 'VND',
        containerCode: item.container_size_code,
        cocSoc: item.coc_soc,
        emptyLaden: item.empty_laden,
        containerMixedQuantity: item.container_mixed_quantity,
        containerNewQuantity: item.container_new_quantity,
        quantity: item.quantity,
        tariffId: item.tariff_id,
        tariffPrice: item.tariff_price,
        userTariffId: item.user_tariff_id,
      };
      return mappedItem;
    });
    commit('SET_REQUEST_QUOTATION', { commodities: mapped });
  },
  async fetchMyQuotationList({ commit }, payload) {
    const res = await QuotationApi.fetchMyQuotationList({
      search: state.myQuotationListQueryParams.search,
      page: state.myQuotationListQueryParams.page,
      size: state.myQuotationListQueryParams.size,
      voyage_code: state.myQuotationListQueryParams.voyageCode,
      vessel_code: state.myQuotationListQueryParams.vesselCode,
      status: state.myQuotationListQueryParams.status,
      pol_code: state.myQuotationListQueryParams.polCode,
      pod_code: state.myQuotationListQueryParams.podCode,
      pol_term_code: state.myQuotationListQueryParams.polTermCode,
      pod_term_code: state.myQuotationListQueryParams.podTermCode,
      from_created_at: state.myQuotationListQueryParams.fromCreatedAt,
      to_created_at: state.myQuotationListQueryParams.toCreatedAt,
      from_etd: state.myQuotationListQueryParams.fromEtd,
      to_etd: state.myQuotationListQueryParams.toEtd,
      from_eta: state.myQuotationListQueryParams.fromEta,
      to_eta: state.myQuotationListQueryParams.toEta,
    });
    const mapped = res.data.data.map((item) => {
      const mappedItem = {
        id: item.id,
        code: item.code,
        voyageCodes: item.quotation_vessel_schedules?.map(
          (qvs) => qvs?.vessel_schedule?.voyage_code
        ),
        etd: dayjs
          .tz(item.quotation_vessel_schedules[0]?.vessel_schedule?.etd)
          .toDate(),
        eta: dayjs
          .tz(
            item.quotation_vessel_schedules[
              item.quotation_vessel_schedules.length - 1
            ]?.vessel_schedule?.eta
          )
          .toDate(),
        polCode: item.pol?.code,
        polName: item.pol?.name,
        polCountryCode: item.pol.country_code,
        podCode: item.pod?.code,
        podName: item.pod?.name,
        podCountryCode: item.pod?.country_code,
        status: item.status,
        createdAt: item.created_at,
        ladenContainerCountByCodeSize:
          item.laden_container_count_by_code_size.map(({ code, quantity }) => ({
            code,
            quantity,
          })),
        emptyContainerCountByCodeSize:
          item.empty_container_count_by_code_size.map(({ code, quantity }) => ({
            code,
            quantity,
          })),
      };
      return mappedItem;
    });
    const size = state.myQuotationListQueryParams.size || 10;
    const page = res.data.total === 0 ? 1 : Math.ceil(res.data.total / size);
    commit('SET_MY_QUOTATION_LIST', mapped);
    commit('SET_MY_QUOTATION_TOTAL', res.data.total);
    commit('SET_MY_QUOTATION_TOTAL_PAGE', page);
  },
  async fetchMyQuotation({ commit }, payload) {
    const { id } = payload;
    const res = await QuotationApi.fetchMyQuotation({ id });
    const { data } = res.data;

    const path = [];
    data.quotation_vessel_schedules.forEach(({ vessel_schedule }, index) => {
      if (index === 0) {
        path.push(vessel_schedule.pol_code);
        path.push(vessel_schedule.pod_code);
      } else {
        path.push(vessel_schedule.pod_code);
      }
    });
    const mappedItem = {
      id: data.id,
      code: data.code,
      // voyageCode: data.vessel_schedule.voyage_code,
      // vesselName: data.vessel_schedule.vessel.name,
      // etd: data.vessel_schedule.etd,
      // eta: data.vessel_schedule.eta,
      polCode: data.pol_code,
      // polCountryCode: data.vessel_schedule.pol.country_code,
      // polTerminalName: data.vessel_schedule.pol_terminal.name,
      // polTerminalCode: data.vessel_schedule.pol_terminal.code,
      podCode: data.pod_code,
      // podCountryCode: data.vessel_schedule.pod.country_code,
      // podTerminalName: data.vessel_schedule.pod_terminal.name,
      // podTerminalCode: data.vessel_schedule.pod_terminal.code,
      vesselSchedulePath: path,
      vesselScheduleCodes: data.quotation_vessel_schedules.map(
        ({ vessel_schedule_code }) => vessel_schedule_code
      ),
      status: data.status,
      createdAt: data.created_at,
      senderName: data.sender_name,
      senderEmail: data.sender_email,
      senderPhone: data.sender_phone,
      senderAddress: data.sender_address,
      senderNote: data.sender_note,
      polTermCode: data.pol_term_code,
      podTermCode: data.pod_term_code,
      version: data.version,
    };
    commit('SET_MY_QUOTATION', mappedItem);
  },
  async fetchMyQuotationActionLogList({ commit }, payload) {
    const { id } = payload;
    const res = await QuotationApi.fetchMyQuotationActionLogList({ id });
    const mapped = res.data.data.map((item) => {
      const mappedItem = {
        id: item.id,
        type: item.action_type,
        createdAt: item.created_at,
      };
      return mappedItem;
    });
    commit('SET_MY_QUOTATION_ACTION_LOG_LIST', mapped);
  },
  async fetchMyQuotationCommodityList({ commit }, payload) {
    const { id } = payload;
    const res = await QuotationApi.fetchMyQuotationCommodityList({ id });
    const mapped = res.data.data.map((item) => {
      const mappedItem = {
        id: item.id,
        commodityCode: item.commodity_code,
        name: item.name,
        weight: item.weight,
        temper: item.temper,
        size: item.size,
        prepaid: item.prepaid,
        price: item.price,
        total: item.total_price,
        currencyCode: item.currency_code,
        containerCode: item.container_size_code,
        cocSoc: item.coc_soc,
        emptyLaden: item.empty_laden,
        containerMixedQuantity: item.container_mixed_quantity,
        containerNewQuantity: item.container_new_quantity,
        quantity: item.quantity,
        tariffId: item.tariff_id,
        tariffPrice: item.tariff_price,
        systemUserNote: item.system_user_note,
      };
      return mappedItem;
    });
    commit('SET_MY_QUOTATION_COMMODITY_LIST', mapped);
  },
  async fetchMyQuotationSurchargeList({ commit }, payload) {
    const { id } = payload;
    const res = await QuotationApi.fetchMyQuotationSurchargeList({ id });
    const mapped = res.data.data.map((item) => {
      const mappedItem = {
        id: item.id,
        type: item.type,
        price: item.price,
        currencyCode: item.currency_code,
        quantity: item.quantity,
        totalPrice: item.total_price,
        surchargeName: item.surcharge_name,
        surchargeItemName: item.surcharge_item_name,
        containerType: item.container_type,
      };
      return mappedItem;
    });
    commit('SET_MY_QUOTATION_SURCHARGE_LIST', mapped);
  },
  async calculateMyQuotationCommodityPrices({ state, commit }, payload) {
    const { polCode, podCode, polTermCode, podTermCode, version } =
      state.myQuotation;
    const commodities = payload.map((item) => {
      return {
        id: item.id ? item.id : nanoid(),
        name: item.name,
        commodity_code: item.commodityCode,
        container_size_code: item.containerCode,
        coc_soc: item.cocSoc,
        empty_laden: item.emptyLaden,
        container_mixed_quantity: item.containerMixedQuantity,
        container_new_quantity: item.containerNewQuantity,
        quantity:
          Number(item.containerMixedQuantity) +
          Number(item.containerNewQuantity),
        ...(version === 'v1' && {
          quantity: Number(item.quantity),
        }),
        prepaid: item.prepaid,
        size: item.size,
        weight: item.weight,
        temper: '',
        system_user_note: item.systemUserNote,
        is_deleted: item.isDeleted,
        is_temp: item.isTemp,
      };
    });
    console.log('[LOG] : commodities', commodities);
    const body = {
      pol_code: polCode,
      pod_code: podCode,
      pol_term_code: polTermCode,
      pod_term_code: podTermCode,
      commodities,
    };

    const res = await QuotationApi.calculateCommodityPrices(body);
    const mapped = res.data.data.commodities.map((item) => {
      const mappedItem = {
        id: item.id,
        commodityCode: item.commodity_code,
        name: item.name,
        weight: item.weight,
        temper: item.temper,
        size: item.size,
        prepaid: item.prepaid,
        price: item.price,
        total: item.total,
        currencyCode: item.currency_code ? item.currency_code : 'VND',
        containerCode: item.container_size_code,
        cocSoc: item.coc_soc,
        emptyLaden: item.empty_laden,
        containerMixedQuantity: item.container_mixed_quantity,
        containerNewQuantity: item.container_new_quantity,
        quantity: item.quantity,
        tariffId: item.tariff_id,
        tariffPrice: item.tariff_price,
        userTariffId: item.user_tariff_id,
        isDeleted: item.is_deleted,
        isTemp: item.is_temp,
      };
      return mappedItem;
    });
    commit('SET_MY_QUOTATION_COMMODITY_LIST', mapped);
  },
  async resendMyQuotation({ state, commit }, payload) {
    const { myQuotation, myQuotationCommodityList } = state;
    const { version } = myQuotation;
    const mapped = myQuotationCommodityList.map((item) => {
      const mappedItem = {
        id: item.id,
        name: item.name,
        commodity_code: item.commodityCode,
        container_size_code: item.containerCode,
        coc_soc: item.cocSoc,
        empty_laden: item.emptyLaden,
        container_mixed_quantity: Number(item.containerMixedQuantity),
        container_new_quantity: Number(item.containerNewQuantity),
        quantity:
          Number(item.containerMixedQuantity) +
          Number(item.containerNewQuantity),
        ...(version === 'v1' && {
          quantity: Number(item.quantity),
        }),
        prepaid: item.prepaid,
        price: item.price,
        currency_code: item.currencyCode,
        size: item.size,
        weight: item.weight,
        temper: '',
        system_user_note: item.systemUserNote,
        tariff_id: item.tariffId,
        tariff_price: item.tariffPrice,
        user_tariff_id: item.userTariffId,
        is_deleted: item.isDeleted,
        is_temp: item.isTemp,
      };
      if (item.isTemp) {
        delete mappedItem.id;
      }
      return mappedItem;
    });
    await QuotationApi.resendMyQuotation(
      { id: myQuotation.id },
      { commodities: mapped }
    );
  },
  async confirmMyQuotation({ state, commit }, payload) {
    const { myQuotation } = state;
    await QuotationApi.confirmMyQuotation({ id: myQuotation.id });
  },
  async fetchQuotationCommentList({ commit }, payload) {
    const { id } = payload;
    const res = await QuotationApi.fetchQuotationCommentList({ id });
    const data = res.data.data;
    const mapped = data.map((item) => {
      const mappedItem = {
        id: item.id,
        content: item.content,
        files: item.files.map((file) => {
          return {
            fileName: file.file_name,
            displayName: file.display_name,
            mimetype: file.mimetype,
            size: file.size,
            link: `${process.env.VUE_APP_VSL_API}/v1/upload/${file.file_name}`,
          };
        }),
        createdAt: dayjs().isBefore(dayjs(item.created_at))
          ? new Date()
          : item.created_at,
      };

      if (item.created_by_booking_user) {
        mappedItem.user = {
          avatar: item.created_by_booking_user.avatar
            ? `${process.env.VUE_APP_VSL_API}/v1/upload/${item.created_by_booking_user.avatar}`
            : DEFAULT_AVATAR,
          name: item.created_by_booking_user.name,
          username: item.created_by_booking_user.username,
        };
      }
      if (item.created_by_system_user) {
        mappedItem.user = {
          avatar: item.created_by_system_user.avatar
            ? `${process.env.VUE_APP_VSL_API}/v1/upload/${item.created_by_system_user.avatar}`
            : DEFAULT_AVATAR,
          name: item.created_by_system_user.name,
          username: item.created_by_system_user.username,
        };
      }

      return mappedItem;
    });
    commit('SET_MY_QUOTATION_COMMENT_LIST', mapped);
  },
  async createQuotationComment({ commit }, payload) {
    const { id, content, files } = payload;

    const res = await QuotationApi.createQuotationComment(
      { id },
      { content, files }
    );
  },
  clearQueryParamsList({ commit }, payload) {
    commit('SET_LIST_QUERY_PARAMS', {
      page: 0,
      voyageCode: undefined,
      vesselCode: undefined,
      status: undefined,
      polCode: undefined,
      podCode: undefined,
      polTermCode: undefined,
      podTermCode: undefined,
      fromCreatedAt: undefined,
      toCreatedAt: undefined,
      fromEtd: undefined,
      toEtd: undefined,
      fromEta: undefined,
      toEta: undefined,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
