if (process.env.NODE_ENV === 'production' && navigator.serviceWorker) {
  const { register } = require('register-service-worker');

  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        '[sw:vuetify] App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      );
    },

    registered(registration) {
      console.log('[sw:vuetify] Service worker has been registered.');

      let time = 1000 * 60 * 30;

      if (process.env.VUE_APP_ENV !== 'prod') {
        time = 1000 * 60 * 1;
      }

      setInterval(() => {
        console.log(
          '[sw:vuetify] Routinely check for app updates by testing for a new service worker',
          time
        );
        registration.update();
      }, time); // half hourly checks
    },

    cached() {
      console.log('[sw:vuetify] Content has been cached for offline use.');
    },

    updatefound() {
      console.log('[sw:vuetify] New content is downloading...');
    },

    updated(registration) {
      console.log('[sw:vuetify] New content is available; please refresh.');

      const event = new CustomEvent('swupdatefound', { detail: registration });

      document.dispatchEvent(event);
      registration.waiting.postMessage('sw:update');
    },

    offline() {
      console.log(
        '[sw:vuetify] No internet connection found. App is running in offline mode.'
      );
    },

    error(error) {
      console.error(
        '[sw:vuetify] Error during service worker registration:',
        error
      );
    },
  });

  let refreshing;

  navigator.serviceWorker.addEventListener('controllerchange', function () {
    if (refreshing) return;

    window.location.reload();

    refreshing = true;
  });
}
