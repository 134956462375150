var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a-card", {
    staticStyle: {
      margin: "20px 0",
      "box-shadow": "0 25px 25px -12px rgb(0 0 0 / 0.25)",
      "border-radius": "20px",
    },
    attrs: { width: "100%" },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [
            _c("div", { staticClass: "partners-title" }, [
              _c(
                "p",
                {
                  staticClass: "display-1 ma-0",
                  style: _vm.$vuetify.breakpoint.mobile
                    ? `text-align: center; font-size: 1.5rem!important; width: 100%`
                    : "",
                },
                [_vm._v(" " + _vm._s(_vm.$t("title-partners")) + " ")]
              ),
            ]),
            _c("RequestBookingHistoryPartnerItem"),
            _c("RequestBookingHistoryPartnerItem"),
            _c("RequestBookingHistoryPartnerItem"),
            _c("RequestBookingHistoryPartnerItem"),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }