import TermApi from '@/api/vsl/term.api';

const initialState = () => ({
  term: {
    code: 'null',
    name: '',
  },
  termList: [],
});

const state = initialState();
const getters = {};
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  SET_TERM_LIST(state, payload) {
    state.termList = payload;
  },
};
const actions = {
  async fetchTermList({ commit }, payload) {
    const res = await TermApi.getTermList();
    const mapped = res.data.data.map((item) => {
      const mappedItem = {
        code: item.code,
        name: item.name,
      };
      return mappedItem;
    });
    commit('SET_TERM_LIST', mapped);
  },
  clearTermList({ commit }, payload) {
    commit('SET_TERM_LIST', []);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
