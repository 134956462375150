<template>
  <v-card>
    <v-card-text>
      <v-row
        no-gutters
        align="center"
        class="mb-2"
      >
        <p class="overline">{{ $t('title-contact-list') }}</p>
        <v-spacer />
        <div
          :style="
            $vuetify.breakpoint.mobile ? 'width: 100%; text-align: right' : ''
          "
        >
          <v-btn
            color="primary"
            @click="dialog.create = true"
          >
            <v-icon left>mdi-plus</v-icon>
            {{ $t('button-contact-add') }}
          </v-btn>
        </div>
      </v-row>
      <UserSettingsContactList />
    </v-card-text>
    <v-dialog
      v-model="dialog.create"
      scrollable
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
      max-width="600px"
      transition="dialog-transition"
    >
      <UserSettingsContactCreate
        v-if="dialog.create"
        @close-dialog="dialog.create = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import UserSettingsContactCreate from './UserSettingsContactCreate.vue';
import UserSettingsContactList from './UserSettingsContactList.vue';
export default {
  components: { UserSettingsContactCreate, UserSettingsContactList },
  data: () => ({
    isLoading: false,
    dialog: {
      create: false,
    },
  }),
  computed: {
    ...mapState('userContact', ['contactList']),
  },
  async created() {},
  methods: {
    ...mapActions('userContact', ['fetchContactList']),
  },
};
</script>

<style></style>
