import UserApi from '@/api/base/user.api';
import { DEFAULT_AVATAR } from '@/constants/common.constant';

const initialState = () => ({
  myInfo: {
    id: null,
    username: null,
    name: '',
    avatar: null,
    avatarLink: null,
    phone: '',
    address: '',
    email: '',
    taxNo: '',
    website: '',
    companyRegistrationNo: '',
    merchantCodes: [],
  },
});

const state = initialState();
const getters = {};
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  SET_MY_INFO(state, payload) {
    state.myInfo = payload;
  },
};
const actions = {
  async getMyInfo({ commit }, payload) {
    const res = await UserApi.getMyInfo();
    const { data } = res.data;
    console.log('[LOG] : data', data);
    commit('SET_MY_INFO', {
      id: data.id,
      username: data.username,
      name: data.name,
      avatar: data.avatar,
      avatarLink: data.avatar
        ? `${process.env.VUE_APP_BASE_API}/v1/upload/${data.avatar}`
        : DEFAULT_AVATAR,
      phone: data.phone,
      email: data.email,
      taxNo: data.tax_no,
      website: data.website,
      companyRegistrationNo: data.company_registration_no,
      merchantCodes: data.merchant_codes,
    });
  },
  async updateRequireInfo(context, payload) {
    const { name, password } = payload;
    const res = await UserApi.updateRequireInfo({ name, password });
  },
  async updateMyInfo(context, payload) {
    const { name, address, taxNo, avatar, website, companyRegistrationNo } =
      payload;
    const res = await UserApi.updateMyInfo({
      name,
      address,
      tax_no: taxNo,
      avatar,
      website,
      company_registration_no: companyRegistrationNo,
    });
  },
  async changeMyPassword(context, payload) {
    const { newPassword, oldPassword } = payload;
    const res = await UserApi.changeMyPassword({
      new_password: newPassword,
      old_password: oldPassword,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
