<template>
  <main>
    <!-- Nav Bar Start -->
    <NavBarHome active="services" />

    <!-- Service Start -->
    <div class="vessel-schedule">
      <div class="container container-vessel">
        <!-- Header with Two Logos, Title, and Subtitle -->
        <div class="table-header">
          <!-- <div class="logo-container">
        <img
          src="../../../libhome/img/footer1.svg"
          alt="Left Logo"
          class="table-logo left-logo"
        />
        <p class="logo-text">9-Aug-24</p> 
      </div> -->

          <div class="title-container">
            <h2 class="table-title">VIMC LINES</h2>
            <!-- <p class="table-subtitle">
          Add: 01 Dao Duy Anh str., Dong Da dist., Hanoi, Vietnam
        </p> -->
          </div>

          <div class="export-button-container">
            <button
              @click="exportToExcel"
              class="export-button"
            >
              <i class="fas fa-file-excel"></i> Xuất Excel
            </button>
          </div>

          <!-- <div class="logo-container">
        <img
          src="https://scontent.xx.fbcdn.net/v/t1.15752-9/453627250_3750283265212930_3113325789707175834_n.png?_nc_cat=107&ccb=1-7&_nc_sid=0024fc&_nc_eui2=AeEwgoHCkWB-UwnOVxV1SvFapYYo2ajIl7ClhijZqMiXsG-pyudTU2rXv9a2pW5fHhuwnAh1F_sAnt_lbBiyw6dl&_nc_ohc=kV6ACuQLLuoQ7kNvgEuxLmB&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent.xx&oh=03_Q7cD1QFgtO7JpoCK3_nbl9QPzqCrq2qJy-hGbqhjFXUh_cNukQ&oe=66DD02D6"
          alt="Right Logo"
          class="table-logo right-logo"
        />
        <p class="logo-text">As member of VIMC</p> 
      </div> -->
        </div>

        <!-- Ant Design Table Start -->
        <div class="table-container">
          <a-table
            :columns="columns"
            :data-source="data"
            bordered
            :row-class-name="rowClassName"
            :pagination="false"
            :scroll="scrollSettings"
          />
        </div>
        <!-- Ant Design Table End -->
      </div>
    </div>

    <FooterHome />

    <!-- Back to top button -->
    <a
      href="/home"
      class="back-to-top"
      ><i class="fa fa-chevron-up"></i
    ></a>

    <NavBarLeftHome />
  </main>
</template>

<script>
import NavBarHome from '@/views/Home/PageChild/NavBarHome.vue';
import FooterHome from '@/views/Home/PageChild/FooterHome.vue';
import NavBarLeftHome from '@/views/Home/PageChild/NavBarLeftHome.vue';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
const data = [
  {
    id: 1,
    vessel: 'BIENDONG NAVIGATOR',
    voyage: 'NB2426S',
    hph: {
      vimcDv: {
        etd: '4-Aug',
      },
    },
    quinhon: {
      qnn: {
        etd: '',
      },
    },
    dan: {
      tienSa: {
        etd: '',
      },
    },
    vut: {
      tcit: {
        eta: '',
      },
      gml: {
        eta: '7-Aug',
      },
    },
    hcm: {
      tanThuan: {
        eta: '7-Aug',
      },
    },
    vessel1: 'BIENDONG NAVIGATOR',
    voyage1: 'NB2426N',
    vut1: {
      tcit1: {
        etd1: '',
      },
      gml1: {
        etd1: '7-Aug',
      },
    },
    hcm1: {
      tanThuan1: {
        etd1: '8-Aug',
      },
    },
    quinhon1: {
      qnn1: {
        eta1: '',
      },
    },
    dan1: {
      tienSa1: {
        eta1: '',
      },
    },
    hph1: {
      vimcDv1: {
        eta1: '11-Aug',
      },
    },
    service: 'CMS',
  },
  {
    id: 2,
    vessel: 'VIMC PIONEER',
    voyage: 'VPN2405S',
    hph: {
      vimcDv: {
        etd: '',
      },
    },
    quinhon: {
      qnn: {
        etd: '6-Aug',
      },
    },
    dan: {
      tienSa: {
        etd: '7-Aug',
      },
    },
    vut: {
      tcit: {
        eta: '9-Aug',
      },
      gml: {
        eta: '9-Aug',
      },
    },
    hcm: {
      tanThuan: {
        eta: '10-Aug',
      },
    },
    vessel1: 'VIMC PIONEER',
    voyage1: 'VPN2405N',
    vut1: {
      tcit1: {
        etd1: '9-Aug',
      },
      gml1: {
        etd1: '10-Aug',
      },
    },
    hcm1: {
      tanThuan1: {
        etd1: '11-Aug',
      },
    },
    quinhon1: {
      qnn1: {
        eta1: '12-Aug',
      },
    },
    dan1: {
      tienSa1: {
        eta1: '13-Aug',
      },
    },
    hph1: {
      vimcDv1: {
        eta1: '',
      },
    },
    service: 'MSS',
  },
  {
    id: 3,
    vessel: 'BIENDONG NAVIGATOR',
    voyage: 'NB2427S',
    hph: {
      vimcDv: {
        etd: '12-Aug',
      },
    },
    quinhon: {
      qnn: {
        etd: '',
      },
    },
    dan: {
      tienSa: {
        etd: '',
      },
    },
    vut: {
      tcit: {
        eta: '',
      },
      gml: {
        eta: '15-Aug',
      },
    },
    hcm: {
      tanThuan: {
        eta: '15-Aug',
      },
    },
    vessel1: 'BIENDONG NAVIGATOR',
    voyage1: 'NB2427N',
    vut1: {
      tcit1: {
        etd1: '',
      },
      gml1: {
        etd1: '15-Aug',
      },
    },
    hcm1: {
      tanThuan1: {
        etd1: '16-Aug',
      },
    },
    quinhon1: {
      qnn1: {
        eta1: '',
      },
    },
    dan1: {
      tienSa1: {
        eta1: '',
      },
    },
    hph1: {
      vimcDv1: {
        eta1: '19-Aug',
      },
    },
    service: 'CMS',
  },
  {
    id: 4,
    vessel: 'VIMC PIONEER',
    voyage: 'VPN2406S',
    hph: {
      vimcDv: {
        etd: '',
      },
    },
    quinhon: {
      qnn: {
        etd: '13-Aug',
      },
    },
    dan: {
      tienSa: {
        etd: '14-Aug',
      },
    },
    vut: {
      tcit: {
        eta: '16-Aug',
      },
      gml: {
        eta: '16-Aug',
      },
    },
    hcm: {
      tanThuan: {
        eta: '17-Aug',
      },
    },
    vessel1: 'VIMC PIONEER',
    voyage1: 'VPN2406N',
    vut1: {
      tcit1: {
        etd1: '16-Aug',
      },
      gml1: {
        etd1: '17-Aug',
      },
    },
    hcm1: {
      tanThuan1: {
        etd1: '18-Aug',
      },
    },
    quinhon1: {
      qnn1: {
        eta1: '19-Aug',
      },
    },
    dan1: {
      tienSa1: {
        eta1: '20-Aug',
      },
    },
    hph1: {
      vimcDv1: {
        eta1: '',
      },
    },
    service: 'MSS',
  },
  {
    id: 5,
    vessel: 'BIENDONG NAVIGATOR',
    voyage: 'NB2428S',
    hph: {
      vimcDv: {
        etd: '20-Aug',
      },
    },
    quinhon: {
      qnn: {
        etd: '',
      },
    },
    dan: {
      tienSa: {
        etd: '',
      },
    },
    vut: {
      tcit: {
        eta: '',
      },
      gml: {
        eta: '23-Aug',
      },
    },
    hcm: {
      tanThuan: {
        eta: '23-Aug',
      },
    },
    vessel1: 'BIENDONG NAVIGATOR',
    voyage1: 'NB2428N',
    vut1: {
      tcit1: {
        etd1: '',
      },
      gml1: {
        etd1: '23-Aug',
      },
    },
    hcm1: {
      tanThuan1: {
        etd1: '24-Aug',
      },
    },
    quinhon1: {
      qnn1: {
        eta1: '',
      },
    },
    dan1: {
      tienSa1: {
        eta1: '',
      },
    },
    hph1: {
      vimcDv1: {
        eta1: '27-Aug',
      },
    },
    service: 'CMS',
  },
  {
    id: 6,
    vessel: 'VIMC PIONEER',
    voyage: 'VPN2407S',
    hph: {
      vimcDv: {
        etd: '',
      },
    },
    quinhon: {
      qnn: {
        etd: '20-Aug',
      },
    },
    dan: {
      tienSa: {
        etd: '21-Aug',
      },
    },
    vut: {
      tcit: {
        eta: '23-Aug',
      },
      gml: {
        eta: '23-Aug',
      },
    },
    hcm: {
      tanThuan: {
        eta: '24-Aug',
      },
    },
    vessel1: 'VIMC PIONEER',
    voyage1: 'VPN2407N',
    vut1: {
      tcit1: {
        etd1: '23-Aug',
      },
      gml1: {
        etd1: '24-Aug',
      },
    },
    hcm1: {
      tanThuan1: {
        etd1: '25-Aug',
      },
    },
    quinhon1: {
      qnn1: {
        eta1: '26-Aug',
      },
    },
    dan1: {
      tienSa1: {
        eta1: '27-Aug',
      },
    },
    hph1: {
      vimcDv1: {
        eta1: '',
      },
    },
    service: 'MSS',
  },
  {
    id: 7,
    vessel: 'VIMC PIONEER',
    voyage: 'VPN2408S',
    hph: {
      vimcDv: {
        etd: '',
      },
    },
    quinhon: {
      qnn: {
        etd: '27-Aug',
      },
    },
    dan: {
      tienSa: {
        etd: '28-Aug',
      },
    },
    vut: {
      tcit: {
        eta: '30-Aug',
      },
      gml: {
        eta: '30-Aug',
      },
    },
    hcm: {
      tanThuan: {
        eta: '31-Aug',
      },
    },
    vessel1: 'VIMC PIONEER',
    voyage1: 'VPN2408N',
    vut1: {
      tcit1: {
        etd1: '30-Aug',
      },
      gml1: {
        etd1: '31-Aug',
      },
    },
    hcm1: {
      tanThuan1: {
        etd1: '1-Sep',
      },
    },
    quinhon1: {
      qnn1: {
        eta1: '2-Sep',
      },
    },
    dan1: {
      tienSa1: {
        eta1: '3-Sep',
      },
    },
    hph1: {
      vimcDv1: {
        eta1: '',
      },
    },
    service: 'MSS',
  },
  {
    id: 8,
    vessel: 'BIENDONG NAVIGATOR',
    voyage: 'NB2429S',
    hph: {
      vimcDv: {
        etd: '28-Aug',
      },
    },
    quinhon: {
      qnn: {
        etd: '',
      },
    },
    dan: {
      tienSa: {
        etd: '',
      },
    },
    vut: {
      tcit: {
        eta: '',
      },
      gml: {
        eta: '31-Aug',
      },
    },
    hcm: {
      tanThuan: {
        eta: '31-Aug',
      },
    },
    vessel1: 'BIENDONG NAVIGATOR',
    voyage1: 'NB2429N',
    vut1: {
      tcit1: {
        etd1: '',
      },
      gml1: {
        etd1: '31-Aug',
      },
    },
    hcm1: {
      tanThuan1: {
        etd1: '1-Sep',
      },
    },
    quinhon1: {
      qnn1: {
        eta1: '',
      },
    },
    dan1: {
      tienSa1: {
        eta1: '',
      },
    },
    hph1: {
      vimcDv1: {
        eta1: '4-Sep',
      },
    },
    service: 'CMS',
  },
  {
    id: 9,
    vessel: 'VIMC PIONEER',
    voyage: 'VPN2409S',
    hph: {
      vimcDv: {
        etd: '',
      },
    },
    quinhon: {
      qnn: {
        etd: '3-Sep',
      },
    },
    dan: {
      tienSa: {
        etd: '4-Sep',
      },
    },
    vut: {
      tcit: {
        eta: '6-Sep',
      },
      gml: {
        eta: '6-Sep',
      },
    },
    hcm: {
      tanThuan: {
        eta: '7-Sep',
      },
    },
    vessel1: 'VIMC PIONEER',
    voyage1: 'VPN2409N',
    vut1: {
      tcit1: {
        etd1: '6-Sep',
      },
      gml1: {
        etd1: '7-Aug',
      },
    },
    hcm1: {
      tanThuan1: {
        etd1: '8-Aug',
      },
    },
    quinhon1: {
      qnn1: {
        eta1: '9-Sep',
      },
    },
    dan1: {
      tienSa1: {
        eta1: '10-Sep',
      },
    },
    hph1: {
      vimcDv1: {
        eta1: '',
      },
    },
    service: 'MSS',
  },
  {
    id: 10,
    vessel: 'BIENDONG NAVIGATOR',
    voyage: 'NB2430S',
    hph: {
      vimcDv: {
        etd: '5-Sep',
      },
    },
    quinhon: {
      qnn: {
        etd: '',
      },
    },
    dan: {
      tienSa: {
        etd: '',
      },
    },
    vut: {
      tcit: {
        eta: '',
      },
      gml: {
        eta: '8-Sep',
      },
    },
    hcm: {
      tanThuan: {
        eta: '8-Sep',
      },
    },
    vessel1: 'BIENDONG NAVIGATOR',
    voyage1: 'NB2430N',
    vut1: {
      tcit1: {
        etd1: '',
      },
      gml1: {
        etd1: '8-Sep',
      },
    },
    hcm1: {
      tanThuan1: {
        etd1: '9-Sep',
      },
    },
    quinhon1: {
      qnn1: {
        eta1: '',
      },
    },
    dan1: {
      tienSa1: {
        eta1: '',
      },
    },
    hph1: {
      vimcDv1: {
        eta1: '12-Sep',
      },
    },
    service: 'CMS',
  },
  {
    id: 11,
    vessel: 'VIMC PIONEER',
    voyage: 'VPN2410S',
    hph: {
      vimcDv: {
        etd: '',
      },
    },
    quinhon: {
      qnn: {
        etd: '10-Sep',
      },
    },
    dan: {
      tienSa: {
        etd: '11-Sep',
      },
    },
    vut: {
      tcit: {
        eta: '13-Sep',
      },
      gml: {
        eta: '13-Sep',
      },
    },
    hcm: {
      tanThuan: {
        eta: '14-Sep',
      },
    },
    vessel1: 'VIMC PIONEER',
    voyage1: 'VPN2409N',
    vut1: {
      tcit1: {
        etd1: '13-Sep',
      },
      gml1: {
        etd1: '14-Sep',
      },
    },
    hcm1: {
      tanThuan1: {
        etd1: '15-Sep',
      },
    },
    quinhon1: {
      qnn1: {
        eta1: '16-Sep',
      },
    },
    dan1: {
      tienSa1: {
        eta1: '17-Sep',
      },
    },
    hph1: {
      vimcDv1: {
        eta1: '',
      },
    },
    service: 'MSS',
  },
  {
    id: 12,
    vessel: 'BIENDONG NAVIGATOR',
    voyage: 'NB2431S',
    hph: {
      vimcDv: {
        etd: '13-Sep',
      },
    },
    quinhon: {
      qnn: {
        etd: '',
      },
    },
    dan: {
      tienSa: {
        etd: '',
      },
    },
    vut: {
      tcit: {
        eta: '',
      },
      gml: {
        eta: '16-Sep',
      },
    },
    hcm: {
      tanThuan: {
        eta: '16-Sep',
      },
    },
    vessel1: 'BIENDONG NAVIGATOR',
    voyage1: 'NB2431N',
    vut1: {
      tcit1: {
        etd1: '',
      },
      gml1: {
        etd1: '16-Sep',
      },
    },
    hcm1: {
      tanThuan1: {
        etd1: '17-Sep',
      },
    },
    quinhon1: {
      qnn1: {
        eta1: '',
      },
    },
    dan1: {
      tienSa1: {
        eta1: '',
      },
    },
    hph1: {
      vimcDv1: {
        eta1: '20-Sep',
      },
    },
    service: 'CMS',
  },
  {
    id: 13,
    vessel: 'VIMC PIONEER',
    voyage: 'VPN2411S',
    hph: {
      vimcDv: {
        etd: '',
      },
    },
    quinhon: {
      qnn: {
        etd: '17-Sep',
      },
    },
    dan: {
      tienSa: {
        etd: '18-Sep',
      },
    },
    vut: {
      tcit: {
        eta: '20-Sep',
      },
      gml: {
        eta: '20-Sep',
      },
    },
    hcm: {
      tanThuan: {
        eta: '21-Sep',
      },
    },
    vessel1: 'VIMC PIONEER',
    voyage1: 'VPN2409N',
    vut1: {
      tcit1: {
        etd1: '20-Sep',
      },
      gml1: {
        etd1: '21-Sep',
      },
    },
    hcm1: {
      tanThuan1: {
        etd1: '22-Sep',
      },
    },
    quinhon1: {
      qnn1: {
        eta1: '23-Sep',
      },
    },
    dan1: {
      tienSa1: {
        eta1: '24-Sep',
      },
    },
    hph1: {
      vimcDv1: {
        eta1: '',
      },
    },
    service: 'MSS',
  },
  {
    id: 14,
    vessel: 'BIENDONG NAVIGATOR',
    voyage: 'NB2432S',
    hph: {
      vimcDv: {
        etd: '21-Sep',
      },
    },
    quinhon: {
      qnn: {
        etd: '',
      },
    },
    dan: {
      tienSa: {
        etd: '',
      },
    },
    vut: {
      tcit: {
        eta: '',
      },
      gml: {
        eta: '24-Sep',
      },
    },
    hcm: {
      tanThuan: {
        eta: '24-Sep',
      },
    },
    vessel1: 'BIENDONG NAVIGATOR',
    voyage1: 'NB2432N',
    vut1: {
      tcit1: {
        etd1: '',
      },
      gml1: {
        etd1: '24-Sep',
      },
    },
    hcm1: {
      tanThuan1: {
        etd1: '25-Sep',
      },
    },
    quinhon1: {
      qnn1: {
        eta1: '',
      },
    },
    dan1: {
      tienSa1: {
        eta1: '',
      },
    },
    hph1: {
      vimcDv1: {
        eta1: '28-Sep',
      },
    },
    service: 'CMS',
  },
  {
    id: 15,
    vessel: 'VIMC PIONEER',
    voyage: 'VPN2412S',
    hph: {
      vimcDv: {
        etd: '',
      },
    },
    quinhon: {
      qnn: {
        etd: '24-Sep',
      },
    },
    dan: {
      tienSa: {
        etd: '25-Sep',
      },
    },
    vut: {
      tcit: {
        eta: '27-Sep',
      },
      gml: {
        eta: '27-Sep',
      },
    },
    hcm: {
      tanThuan: {
        eta: '28-Sep',
      },
    },
    vessel1: 'VIMC PIONEER',
    voyage1: 'VPN2409N',
    vut1: {
      tcit1: {
        etd1: '27-Sep',
      },
      gml1: {
        etd1: '28-Sep',
      },
    },
    hcm1: {
      tanThuan1: {
        etd1: '29-Sep',
      },
    },
    quinhon1: {
      qnn1: {
        eta1: '30-Sep',
      },
    },
    dan1: {
      tienSa1: {
        eta1: '1-Oct',
      },
    },
    hph1: {
      vimcDv1: {
        eta1: '',
      },
    },
    service: 'MSS',
  },
  {
    id: 16,
    vessel: 'BIENDONG NAVIGATOR',
    voyage: 'NB2433S',
    hph: {
      vimcDv: {
        etd: '29-Sep',
      },
    },
    quinhon: {
      qnn: {
        etd: '',
      },
    },
    dan: {
      tienSa: {
        etd: '',
      },
    },
    vut: {
      tcit: {
        eta: '',
      },
      gml: {
        eta: '2-Oct',
      },
    },
    hcm: {
      tanThuan: {
        eta: '2-Oct',
      },
    },
    vessel1: 'BIENDONG NAVIGATOR',
    voyage1: 'NB2433N',
    vut1: {
      tcit1: {
        etd1: '',
      },
      gml1: {
        etd1: '2-Oct',
      },
    },
    hcm1: {
      tanThuan1: {
        etd1: '3-Oct',
      },
    },
    quinhon1: {
      qnn1: {
        eta1: '',
      },
    },
    dan1: {
      tienSa1: {
        eta1: '',
      },
    },
    hph1: {
      vimcDv1: {
        eta1: '6-Oct',
      },
    },
    service: 'CMS',
  },
  {
    id: 17,
    vessel: 'VIMC PIONEER',
    voyage: 'VPN2413S',
    hph: {
      vimcDv: {
        etd: '',
      },
    },
    quinhon: {
      qnn: {
        etd: '1-Oct',
      },
    },
    dan: {
      tienSa: {
        etd: '2-Oct',
      },
    },
    vut: {
      tcit: {
        eta: '4-Oct',
      },
      gml: {
        eta: '4-Oct',
      },
    },
    hcm: {
      tanThuan: {
        eta: '5-Oct',
      },
    },
    vessel1: 'VIMC PIONEER',
    voyage1: 'VPN2409N',
    vut1: {
      tcit1: {
        etd1: '4-Oct',
      },
      gml1: {
        etd1: '5-Oct',
      },
    },
    hcm1: {
      tanThuan1: {
        etd1: '6-Oct',
      },
    },
    quinhon1: {
      qnn1: {
        eta1: '7-Oct',
      },
    },
    dan1: {
      tienSa1: {
        eta1: '8-Oct',
      },
    },
    hph1: {
      vimcDv1: {
        eta1: '',
      },
    },
    service: 'MSS',
  },
  {
    id: 18,
    vessel: 'BIENDONG NAVIGATOR',
    voyage: 'NB2434S',
    hph: {
      vimcDv: {
        etd: '7-Oct',
      },
    },
    quinhon: {
      qnn: {
        etd: '',
      },
    },
    dan: {
      tienSa: {
        etd: '',
      },
    },
    vut: {
      tcit: {
        eta: '',
      },
      gml: {
        eta: '10-Oct',
      },
    },
    hcm: {
      tanThuan: {
        eta: '10-Oct',
      },
    },
    vessel1: 'BIENDONG NAVIGATOR',
    voyage1: 'NB2434N',
    vut1: {
      tcit1: {
        etd1: '',
      },
      gml1: {
        etd1: '10-Oct',
      },
    },
    hcm1: {
      tanThuan1: {
        etd1: '11-Oct',
      },
    },
    quinhon1: {
      qnn1: {
        eta1: '',
      },
    },
    dan1: {
      tienSa1: {
        eta1: '',
      },
    },
    hph1: {
      vimcDv1: {
        eta1: '14-Oct',
      },
    },
    service: 'CMS',
  },
];

const columns = [
  {
    title: 'VESSEL',
    dataIndex: 'vessel',
    key: 'vessel',
    width: 182,
    fixed: 'left',
    className: 'bold-column',
  },
  {
    title: 'VOYAGE',
    dataIndex: 'voyage',
    key: 'voyage',
    width: 104,
    fixed: 'left',
    className: 'bold-column',
  },
  {
    title: 'HPH',
    children: [
      {
        title: 'VIMC DV',
        children: [
          {
            title: 'ETD',
            dataIndex: ['hph', 'vimcDv', 'etd'],
            key: 'etd',
            width: 88,
          },
        ],
      },
    ],
  },
  {
    title: 'QUI NHON',
    children: [
      {
        title: 'QNN',
        children: [
          {
            title: 'ETD',
            dataIndex: ['quinhon', 'qnn', 'etd'],
            key: 'etd',
            width: 100,
          },
        ],
      },
    ],
  },
  {
    title: 'DAN',
    children: [
      {
        title: 'TIEN SA',
        children: [
          {
            title: 'ETD',
            dataIndex: ['dan', 'tienSa', 'etd'],
            key: 'etd',
            width: 83,
          },
        ],
      },
    ],
  },
  {
    title: 'VUT',
    children: [
      {
        title: 'TCIT',
        children: [
          {
            title: 'ETA',
            dataIndex: ['vut', 'tcit', 'eta'],
            key: 'eta1',
            width: 80,
          },
        ],
      },
      {
        title: 'GML',
        children: [
          {
            title: 'ETA',
            dataIndex: ['vut', 'gml', 'eta'],
            key: 'eta2',
            width: 80,
          },
        ],
      },
    ],
  },
  {
    title: 'HCM',
    children: [
      {
        title: 'TAN THUAN',
        children: [
          {
            title: 'ETA',
            dataIndex: ['hcm', 'tanThuan', 'eta'],
            key: 'eta3',
            width: 108,
          },
        ],
      },
    ],
  },
  {
    title: 'VESSEL',
    dataIndex: 'vessel1',
    key: 'vessel',
    width: 182,
    className: 'bold-column',
  },
  {
    title: 'VOYAGE',
    dataIndex: 'voyage1',
    key: 'voyage',
    width: 104,
    className: 'bold-column',
  },
  {
    title: 'VUT',
    children: [
      {
        title: 'TCIT',
        children: [
          {
            title: 'ETD',
            dataIndex: ['vut1', 'tcit1', 'etd1'],
            key: 'etd1',
            width: 80,
          },
        ],
      },
      {
        title: 'GML',
        children: [
          {
            title: 'ETD',
            dataIndex: ['vut1', 'gml1', 'etd1'],
            key: 'etd1',
            width: 80,
          },
        ],
      },
    ],
  },
  {
    title: 'HCM',
    children: [
      {
        title: 'TAN THUAN',
        children: [
          {
            title: 'ETD',
            dataIndex: ['hcm1', 'tanThuan1', 'etd1'],
            key: 'etd1',
            width: 108,
          },
        ],
      },
    ],
  },
  {
    title: 'QUI NHON',
    children: [
      {
        title: 'QNN',
        children: [
          {
            title: 'ETA',
            dataIndex: ['quinhon1', 'qnn1', 'eta1'],
            key: 'eta1',
            width: 100,
          },
        ],
      },
    ],
  },
  {
    title: 'DAN',
    children: [
      {
        title: 'TIEN SA',
        children: [
          {
            title: 'ETA',
            dataIndex: ['dan1', 'tienSa1', 'eta1'],
            key: 'eta1',
            width: 83,
          },
        ],
      },
    ],
  },
  {
    title: 'HPH',
    children: [
      {
        title: 'VIMC DV',
        children: [
          {
            title: 'ETA',
            dataIndex: ['hph1', 'vimcDv1', 'eta1'],
            key: 'eta1',
            width: 88,
          },
        ],
      },
    ],
  },
  {
    title: 'SERVICE',
    dataIndex: 'service',
    key: 'service',
    width: 88,
  },
];

export default {
  name: 'VesselSchedule',
  components: {
    NavBarLeftHome,
    FooterHome,
    NavBarHome,
  },
  data() {
    return {
      columns,
      data,
      scrollSettings: {}, // Mặc định không có thanh cuộn
    };
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize(); // Kiểm tra kích thước ban đầu khi trang được tải
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    rowClassName(record) {
      if (record.vessel === 'BIENDONG NAVIGATOR') {
        return 'biendong-row';
      } else if (record.vessel === 'VIMC PIONEER') {
        return 'vimc-row';
      }
      return '';
    },
    handleResize() {
      const screenWidth = window.innerWidth;
      // Khi màn hình nhỏ hơn 821px, bật thanh cuộn
      if (screenWidth < 1367) {
        this.scrollSettings = { x: 1000 }; // Đặt chiều ngang cuộn
      } else {
        this.scrollSettings = {}; // Không cuộn nếu màn hình lớn hơn
      }
    },

    exportToExcel() {
      // Tạo workbook và worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Data');

      // Dữ liệu
      const flattenedData = this.data.map((item) => ({
        Vessel: item.vessel,
        Voyage: item.voyage,
        'HPH VimcDv ETD': item.hph.vimcDv.etd || '',
        'Quinhon Qnn ETD': item.quinhon.qnn.etd || '',
        'Dan TienSa ETD': item.dan.tienSa.etd || '',
        'VUT TCIT ETA': item.vut.tcit.eta || '',
        'VUT GML ETA': item.vut.gml.eta || '',
        'HCM TanThuan ETA': item.hcm.tanThuan.eta || '',
        Vessel1: item.vessel1,
        Voyage1: item.voyage1,
        'VUT1 TCIT1 ETD1': item.vut1.tcit1.etd1 || '',
        'VUT1 GML1 ETD1': item.vut1.gml1.etd1 || '',
        'HCM1 TanThuan1 ETD1': item.hcm1.tanThuan1.etd1 || '',
        'Quinhon1 Qnn1 ETA1': item.quinhon1.qnn1.eta1 || '',
        'Dan1 TienSa1 ETA1': item.dan1.tienSa1.eta1 || '',
        'HPH1 VimcDv1 ETA1': item.hph1.vimcDv1.eta1 || '',
        Service: item.service,
      }));

      // Thêm tiêu đề cột
      worksheet.columns = Object.keys(flattenedData[0]).map((key) => ({
        header: key,
        key: key,
        width: 50, // Chiều rộng cột
      }));

      // Merge ô tiêu đề
      worksheet.mergeCells('A1:A3');
      worksheet.getCell('A1').value = 'VESSEL';
      worksheet.mergeCells('B1:B3');
      worksheet.getCell('B1').value = 'VOYAGE';
      worksheet.getCell('C1').value = 'HPH';
      worksheet.getCell('C2').value = 'VIMC DV';
      worksheet.getCell('C3').value = 'ETD';
      worksheet.getCell('D1').value = 'QUI NHON';
      worksheet.getCell('D2').value = 'QNN';
      worksheet.getCell('D3').value = 'ETD';
      worksheet.getCell('E1').value = 'DAN';
      worksheet.getCell('E2').value = 'TIEN SA';
      worksheet.getCell('E3').value = 'ETD';
      worksheet.mergeCells('F1:G1');
      worksheet.getCell('F1').value = 'VUT';
      worksheet.getCell('F2').value = 'TCIT';
      worksheet.getCell('F3').value = 'ETA';
      worksheet.getCell('G2').value = 'GML';
      worksheet.getCell('G3').value = 'ETA';
      worksheet.getCell('H1').value = 'HCM';
      worksheet.getCell('H2').value = 'TAN THUAN';
      worksheet.getCell('H3').value = 'ETA';
      worksheet.mergeCells('I1:I3');
      worksheet.getCell('I1').value = 'VESSEL';
      worksheet.mergeCells('J1:J3');
      worksheet.getCell('J1').value = 'VOYAGE';
      worksheet.mergeCells('K1:L1');
      worksheet.getCell('K1').value = 'VUT';
      worksheet.getCell('K2').value = 'TCIT';
      worksheet.getCell('K3').value = 'ETD';
      worksheet.getCell('L2').value = 'GML';
      worksheet.getCell('L3').value = 'ETD';
      worksheet.getCell('M1').value = 'HCM';
      worksheet.getCell('M2').value = 'TAN THUAN';
      worksheet.getCell('M3').value = 'ETD';
      worksheet.getCell('N1').value = 'QUI NHON';
      worksheet.getCell('N2').value = 'QNN';
      worksheet.getCell('N3').value = 'ETA';
      worksheet.getCell('O1').value = 'DAN';
      worksheet.getCell('O2').value = 'TIEN SA';
      worksheet.getCell('O3').value = 'ETA';
      worksheet.getCell('P1').value = 'HPH';
      worksheet.getCell('P2').value = 'VIMC DV';
      worksheet.getCell('P3').value = 'ETA';
      worksheet.mergeCells('Q1:Q3');
      worksheet.getCell('Q1').value = 'SERVICE';
      // Thêm dữ liệu
      worksheet.addRows(flattenedData);

      // Định dạng viền cho tất cả các ô
      worksheet.eachRow({ includeEmpty: true }, (row) => {
        row.eachCell({ includeEmpty: true }, (cell) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          // Bật wrap text cho tất cả các ô
          cell.alignment = { wrapText: true, vertical: 'middle' };
        });
      });

      for (let row = 1; row <= 3; row++) {
        for (let col = 1; col <= 17; col++) {
          const cell = worksheet.getCell(row, col);
          cell.font = { color: { argb: 'FFFFFFFF' }, bold: true }; // Chữ màu trắng và in đậm
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'e8724a' }, // Nền màu cam
          };
          cell.alignment = { horizontal: 'center', vertical: 'middle' }; // Căn giữa
        }
      }

      // Định dạng nền xen kẽ cho dữ liệu
      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        if (rowNumber > 1) {
          // Bỏ qua hàng tiêu đề
          const vesselValue = row.getCell(1).value; // Giả sử cột Vessel là cột đầu tiên
          let fillColor;
          let fontColor;

          if (vesselValue === 'BIENDONG NAVIGATOR') {
            fillColor = 'F2F2F2F2';
          } else if (vesselValue === 'VIMC PIONEER') {
            fillColor = 'FFFFFFFF';
            fontColor = 'FFFF0000'; // Màu chữ đỏ
          }

          if (fillColor) {
            row.eachCell({ includeEmpty: true }, (cell) => {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: fillColor },
              };
              if (fontColor) {
                cell.font = { color: { argb: fontColor } };
              }
            });
          }
        }
      });

      // Thiết lập chiều rộng cột
      worksheet.columns.forEach((column) => {
        // Chiều rộng của tiêu đề
        const headerLength = column.header
          ? column.header.toString().length
          : 0;
        // Chiều rộng tối đa của các dữ liệu trong cột
        const maxDataLength = column.values.reduce((maxLength, value) => {
          const cellLength = value ? value.toString().length : 0;
          return Math.max(maxLength, cellLength);
        }, 0);
        // Thiết lập chiều rộng cột
        column.width = Math.max(headerLength, maxDataLength) + 4; // Thêm không gian cho các tiêu đề cột và dữ liệu
      });

      // Xuất workbook thành file Excel
      workbook.xlsx
        .writeBuffer()
        .then((buffer) => {
          const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          saveAs(blob, 'VesselSchedule.xlsx');
        })
        .catch((error) => {
          console.error('Error saving Excel file:', error);
        });
    },
  },
};
</script>

<style>
.vessel-schedule {
  padding: 40px 0;
  margin-top: 100px;
}

.container-vessel {
  max-width: 1764px !important;
}

.ant-table-thead th {
  text-align: center;
}

.ant-table-bordered .ant-table-thead > tr > th {
  text-align: center;
  border-top-right-radius: 0;
  background: #e8724a;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif !important;
}

.table-header {
  align-items: center;
  padding: 15px 0 15px 15px;
}

.export-button-container {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end; /* Đẩy nút sang bên phải */
  margin-right: 30px;
}

.export-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #e8724a;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.export-button:hover {
  background-color: #f98569;
}
.table-title {
  flex: 1;
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  color: red;
  font-family: 'Montserrat', sans-serif !important;
  margin: 0;
}

.table-logo {
  padding-bottom: 10px;
  height: auto;
}

.left-logo {
  width: 180px;
  margin-right: auto;
}

.right-logo {
  width: 130px;
  margin-left: auto;
}

.logo-text {
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
  font-family: 'Montserrat', sans-serif !important;
}

.table-subtitle {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif !important;
  margin-top: 5px;
}

.vimc-row {
  background-color: #fff;
  color: red;
  font-size: 12px;
  text-align: center;
  font-family: 'Montserrat', sans-serif !important;
  /* Màu nền cho hàng có id chẵn */
}

.biendong-row {
  background-color: #f2f2f2;
  color: #000;
  font-size: 12px;
  text-align: center;
  font-family: 'Montserrat', sans-serif !important; /* Màu nền cho hàng có id lẻ */
}

.bold-column {
  font-weight: 700;
  text-align: left !important;
}

.table-container .ant-table-body {
  overflow-x: hidden; /* Mặc định không cuộn */
}

@media screen and (max-width: 768px) {
  .table-container .ant-table-body {
    overflow-x: auto; /* Hiển thị thanh cuộn trên tablet */
  }
}
</style>
