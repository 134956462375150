import AuthApi from '@/api/base/auth.api';
import { api, vslApi } from '@/api';

const initialState = () => ({
  authStatus: false,
  signupPhone: null,
});

const state = initialState();
const getters = {};
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  SET_AUTH_STATUS(state, payload) {
    state.authStatus = payload;
  },
  SET_SIGNUP_PHONE(state, payload) {
    state.signupPhone = payload;
  },
};
const actions = {
  async login({ commit, dispatch }, payload) {
    const { email, password } = payload;
    const res = await AuthApi.login({
      account: email.trim().toLowerCase(),
      password,
    });

    const { access_token } = res.data.data;
    localStorage.setItem('accessToken', access_token);
    api.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    await dispatch('user/getMyInfo', null, { root: true });
    commit('SET_AUTH_STATUS', true);
  },
  async signupWithSms({ commit, dispatch }, payload) {
    const { token } = payload;
    const res = await AuthApi.signupWithSms({ token });

    const { access_token } = res.data.data;
    localStorage.setItem('accessToken', access_token);
    api.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    console.log('[LOG] : access_token', access_token);

    // await dispatch("user/getMyInfo", null, { root: true });
    commit('SET_AUTH_STATUS', true);
  },
  async logout() {
    localStorage.removeItem('accessToken');
    delete api.defaults.headers.common.Authorization;
  },
  async checkPhoneValid({ commit }, payload) {
    commit('SET_SIGNUP_PHONE', payload.phone);
    await AuthApi.checkPhoneValid({ phone: payload.phone });
  },
  async signup({ commit, dispatch }, payload) {
    const {
      phone,
      name,
      address,
      password,
      emails,
      provinceCode,
      districtCode,
    } = payload;
    await AuthApi.signup({
      phone,
      name,
      address,
      password,
      emails,
      province_code: provinceCode,
      district_code: districtCode,
    });
    await dispatch('login', { email: phone, password });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
