<template>
  <div>
    <div class="mt-5 ml-4 mr-4" width="100%" align="center"
      style="gap: 5px; border-bottom: 1px solid rgb(220, 223, 230);" />
    <div v-for="(services, type) in groupedServices" :key="type">
      <div class="mt-3 ml-4 mr-4" style="background-color: #eaecf0;">
        <div class="pt-3 pb-3" style="display: flex; justify-content: start; align-items: center;">
          <h3 class="ml-3" style="font-size: 20px;font-weight: bold; margin-bottom: 0;">{{ getTypeName(type) }}</h3>
        </div>
      </div>
      <v-radio-group>
        <div v-for="service in services" :key="service.code" class="el-radio-group d-flex flex-column" role="radiogroup"
          aria-label="radio-group">
          <div class="col-12 mt-1 ml-3 d-block ">
            <v-row>
              <div class="col-9 text-name">{{ service.name }}</div>
              <span class="col-1 center-on-small-only">
                <button @click="openChat" aria-disabled="false" type="button" class="el-button is-plain px-2"
                  style="height: 30px; border: solid 1px #dcdfe6;">
                  <span class="">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2CAYAAACMRWrdAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQ4SURBVHgB3VrbcdtWED0LUIw94yRMBYb/MhmLoSuwVIGdCixVIKICSxVArMByBaEqkFyBaSmTyV/gCowPz8gmCax3AZAmXiJAkRaB8yEOru4FeLh793EugIaCsCFYDnd+hr9HmFpM5mOAO6lHe8T+xwDmaIqp+5/90MUasVZiT53JHhFeEPilXFqoBpeBS7Bxfm23hrgj7kxMLfOLMT0Cc18uO1gPQpIT9k9WteTKxDZEKAMheLYKwZWIdQdjIYRjbJBQCi44GFzZD07LLqhELAwINPlbFu3hHsDg4YQDu4z1ShP73bmx2mReoHpQWDfcMfv7y8gZKIE/nM89IfUe909KoT/we/1Ot01aajG9QYvaaqkftZ/KwpvyeP9f+9Eo75+3Etsi9yuCJ275LM8tC4nVgNQMrsE7z0Y2eYuDhXtsh8zX2H5SCsuXSJ0ezCW269wciCkPUBNo+uk6X/qpsSRq5IJpeOKST2YumbFY22i9Qv1IKToBxnOrJSwWW0vz1baF9rKYWy1hsZ2oVKorKcXcagliFEXCeoPoKPyYXWuTaBBfoAEIpJacW4yIX6EhMGC+/E4M6KEpCOUJQU/6rIAmn9AghBYTlag51orR0j8G/F7J1mwRkaokybxkR+3J/KHKcATS+UvTSnR/9mS+/vAWKqAVfVC13MV8cmX/dDy77EliD24rw5jfGmj3Z+VOPF8L1yJPkYrd3x8ttCNd5+uxRLjS6YjiRWeyqFRUlAVnH/rtw/T4U+dG0oWZly7cq377SXow3tf/I8dyBo+lDck2kLun44uyektl//PZf5s3/o/98DJ3AeM8b1itx6C8Nd6ooCtGwbPzUJkYwdh0yeVhDViBWP6+0MqlYEGui6uUJ1J43r2sQqGGzOcoiZgYlf+VZAN3B9PEl9WuQMqxNwUrOrunE2dxQEn9SuGYlbdAxKM3es/Fse5g8rpK8xsHD+k+yXBQARqKifkdEz2Wm+ghxDIXlfRAw+ihfFBufviMj/KM51VF2pDYLRGttghdsYUHIzQMIbE4cTaJnPs9KjKfoyng4MOcWIDgEg0BwxgmxJzu6ViFnNpX+iLo/JZM0A1wR61lMyqVVOB6YriWkua+QHKsq58JYmF0ZB6gpoisFbU6mVqxzlabWUuRIRa1EzXMadL8Ljamlav7LYW72NErMsS0qr6vtwJWRCgjpAdb6YGdepFS1ffwKueoNuuK1HqBmoAZh0WSRIaY9Ep72H54Surabp8VTUi4YtTe87YfI8meGv9VKPjESB0jbffBhHbUkd74aGk6SgUPFU45PSdScNl/N9Ebw++YMHv0Y98qkIooOLmu8JJYgpjPX09ESLGgeoTogQH8YcHmHImaexnAFK0ER9gcwhJPq6H0exzLcOcXMUO5OiKo0dTCerAyoRnuTGwRoSgE40A265+o3te5S7ykEr4Bb4Su6NB+F74AAAAASUVORK5CYII="
                      style="width: 13px;">
                  </span>
                </button>
              </span>
              <h5 class="col-1 text-name" style="font-size: 16px; font-weight: bold;">${{ service.price }}</h5>
              <div v-if="type !== '00'">
                <v-radio class="col-1 item txt-center" :value="service.code" :v-model="selectedServices[type]"
                  @change="updateSelectedService(type, service.code)">&nbsp;
                </v-radio>
              </div>
              <div v-else>
                <div class="col-1 pt-0 pb-0">
                  <v-checkbox class="pt-0 pb-0" :value="service.code" :v-model="checkedServices"
                    @change="updateCheckedService(type, service.code)">
                  </v-checkbox>
                </div>
              </div>
            </v-row>
          </div>
        </div>
      </v-radio-group>
      <ChatMessage :isPopupOpen="isPopupOpen" :closeChat="closeChat" />
    </div>
  </div>
</template>

<script>
import ChatMessage from './../ChatMessage.vue'
export default {
  components: { ChatMessage },
  props: {
  },
  data: () => ({
    isPopupOpen: false,
    panel: undefined,
    panelText: '',
    isViewDetail: false,
    item: {
      processType: "test"
    },
    portServiceList: [
      {
        "id": 5,
        "code": "DV000005",
        "name": "Đóng container tại cảng",
        "type": "00",
        "price": 150.0,
        "status": "0"
      },
      {
        "id": 4,
        "code": "DV000004",
        "name": "Rút container tại cảng",
        "type": "01",
        "price": 120.0,
        "status": "0"
      },
      {
        "id": 3,
        "code": "DV000003",
        "name": "Đóng container tại cảng",
        "type": "00",
        "price": 220.0,
        "status": "0"
      },
      {
        "id": 6,
        "code": "DV000006",
        "name": "Rút container tại cảng",
        "type": "01",
        "price": 230.0,
        "status": "0"
      },
      {
        "id": 7,
        "code": "DV000007",
        "name": "Đóng container tại cảng",
        "type": "00",
        "price": 123.0,
        "status": "0"
      },
      {
        "id": 8,
        "code": "DV000008",
        "name": "Rút container tại cảng",
        "type": "01",
        "price": 111.0,
        "status": "0"
      },
      {
        "id": 9,
        "code": "DV000009",
        "name": "Đóng container tại cảng",
        "type": "00",
        "price": 450.0,
        "status": "0"
      },
      {
        "id": 10,
        "code": "DV000010",
        "name": "Dịch vụ đóng cont tại Cảng",
        "type": "00",
        "price": 20.0,
        "status": "0"
      },
      {
        "id": 11,
        "code": "DV000011",
        "name": "Rút cont tại cảng",
        "type": "01",
        "price": 25.0,
        "status": "0"
      },
      {
        "id": 12,
        "code": "DV000012",
        "name": "Đóng cont tại cảng",
        "type": "00",
        "price": 20.0,
        "status": "0"
      },
      {
        "id": 13,
        "code": "DV000013",
        "name": "Rút cont tại cảng",
        "type": "01",
        "price": 15.0,
        "status": "0"
      },
      {
        "id": 14,
        "code": "DV000014",
        "name": "Dịch vụ đóng cont tại Cảng",
        "type": "00",
        "price": 120.0,
        "status": "0"
      },
      {
        "id": 15,
        "code": "DV000015",
        "name": "Dịch vụ Rút cont tại cảng",
        "type": "01",
        "price": 110.0,
        "status": "0"
      },
      {
        "id": 5,
        "code": "AP",
        "name": "AP Logistic",
        "type": "T",
        "price": 175.0
      },
      {
        "id": 7,
        "code": "CMA",
        "name": "CMA CGM",
        "type": "C",
        "price": 250.0
      },
      {
        "id": 6,
        "code": "HAL",
        "name": "HAIAN LINES",
        "type": "C",
        "price": 100.0
      },
      {
        "id": 10,
        "code": "CQ",
        "name": "Hãng tàu Hải An",
        "type": "C",
        "price": 180.0
      },
      {
        "id": 13,
        "code": "QN",
        "name": "Hãng tàu A",
        "type": "C",
        "price": 70.0
      },
      {
        "id": 14,
        "code": "HT001",
        "name": "Hãng tàu Maersk",
        "type": "C",
        "price": 290.0
      },
      {
        "id": 15,
        "code": "MSC",
        "name": "Hãng tàu MSC",
        "type": "C",
        "price": 80.0
      },
      {
        "id": 16,
        "code": "CCT",
        "name": "AP Logistic",
        "type": "L",
        "price": 200.0
      },
      {
        "id": 17,
        "code": "HT003",
        "name": "Hãng tàu EVERGREEN",
        "type": "C",
        "price": 120.0
      },
      {
        "id": 18,
        "code": "ONE ",
        "name": "Hãng tàu ONE ",
        "type": "C",
        "price": 270.0
      },
      {
        "id": 19,
        "code": "HMM",
        "name": "Hãng tàu HMM ",
        "type": "C",
        "price": 95.0
      },
      {
        "id": 20,
        "code": "VT001",
        "name": "Công ty TNHH Việt Logistics",
        "type": "T",
        "price": 180.0
      },
      {
        "id": 21,
        "code": "VT002",
        "name": "Vận Tải Thành Hưng ",
        "type": "T",
        "price": 70.0
      },
      {
        "id": 22,
        "code": "VT003",
        "name": "Công Ty Cổ Phần Quốc Tế Delta",
        "type": "T",
        "price": 240.0
      },
      {
        "id": 12,
        "code": "SGP",
        "name": "Công ty TNHH Việt Logistics",
        "type": "L",
        "price": 300.0
      },
      {
        "id": 11,
        "code": "QNP",
        "name": "Công ty TNHH Việt Logistics",
        "type": "L",
        "price": 150.0
      },
    ],
    // Khai báo một đối tượng để nhóm các dịch vụ theo type
    groupedServices: {},
    selectedServices: {},
    checkedServices: [],
    totalPrice: 0,
    total: 0,
    isChecked: true,
  }),
  computed: {

  },
  watch: {
    panel(value) {
      if (value === undefined) {
        this.panelText = this.$t('button-view-detail');
      } else {
        this.panelText = this.$t('button-hide-detail');
      }
    },
  },
  async created() {
    this.panelText = this.$t('button-view-detail');
    // Gọi hàm để nhóm các dịch vụ
    this.groupServicesByType();
    this.markFirstItemAsDefault();
  },
  methods: {

    updateCheckedService(type, code) {
      if (this.checkedServices.indexOf(code) === -1) {
        this.checkedServices.push(code);
        this.calculateTotalPrice(code);
      } else {
        this.splitTotalPrice(code);
        const index = this.checkedServices.indexOf(code);
        if (index !== -1) {
          this.checkedServices.splice(index, 1);
        }
      }
      console.log("this.checkedServices", this.checkedServices);
    },

    updateSelectedService(type, code) {
      if (!this.selectedServices[type]) {
        this.$set(this.selectedServices, type, []);
      }
      if (this.selectedServices[type].indexOf(code) === -1) {
        this.splitTotalPrice(this.selectedServices[type][0])
        this.$set(this.selectedServices, type, []);
        this.selectedServices[type].push(code);
      }
      console.log("this.selectedServices[type]", this.selectedServices[type]);
      console.log("this.selectedServices", this.selectedServices);
      this.calculateTotalPrice(this.selectedServices[type][0])
    },
    // Kiểm tra xem một dịch vụ có được chọn hay không
    isServiceSelected(type, code) {
      console.log("this.selectedServices[type].includes(code)", this.selectedServices[type] && this.selectedServices[type].includes(code))
      if (this.selectedServices[type] && this.selectedServices[type].includes(code)) {
        console.log("code")
        return code;
      } else {
        return "";
      }
    },
    isServiceChecked(type, code) {
      console.log("this.checkedServices.includes(code)", this.checkedServices && this.checkedServices.includes(code))
      if (this.checkedServices && this.checkedServices.includes(code)) {
        return code;
      } else {
        return "";
      }
    },

    calculateTotalPrice(codeS) {
      // Tính tổng giá trị của selectedServices
      if (codeS) {
        const selectedService = this.portServiceList.find(item => item.code === codeS);
        if (selectedService) {
          this.total += selectedService.price;
        }
      }
      this.totalPrice = this.total;
      console.log("this.totalPrice" + this.totalPrice);
    },
    splitTotalPrice(codeS) {
      // Tính tổng giá trị của selectedServices
      if (codeS) {
        const selectedService = this.portServiceList.find(item => item.code === codeS);
        if (selectedService) {
          this.total -= selectedService.price;
        }
      }
      this.totalPrice = this.total;
      console.log("this.totalPrice" + this.totalPrice);
    },

    // markFirstItemAsDefault() {
    //   for (const type in this.groupedServices) {
    //     const servicesOfType = this.groupedServices[type];
    //     if (servicesOfType.length > 0) {
    //       const firstService = servicesOfType[0];
    //       if (type !== '00') {
    //         // For radio buttons
    //         this.$set(this.selectedServices, type, []);
    //         this.$set(this.selectedServices[type], 0, firstService.code);
    //         this.updateSelectedService(type, firstService.code);
    //       } else {
    //         // For checkboxes
    //         this.checkedServices.push(firstService.code);
    //         this.updateCheckedService(type, firstService.code);
    //       }
    //     }
    //   }
    // },
    markFirstItemAsDefault() {
      for (const type in this.groupedServices) {
        const servicesOfType = this.groupedServices[type];
        if (servicesOfType.length > 0) {
          const firstService = servicesOfType[0];
          if (type !== '00') {
            // For radio buttons
            this.$set(this.selectedServices, type, [firstService.code]);
            this.updateSelectedService(type, firstService.code);
          } else {
            // For checkboxes
            this.checkedServices.push(firstService.code);
            this.updateCheckedService(type, firstService.code);
          }
        }
      }
    },




    formatVesselSlot(value) {
      if (value === true) {
        return this.$t('text-slot-available');
      }
      if (value === false) {
        return this.$t('text-slot-not-available');
      }
    },
    groupServicesByType() {
      // Lặp qua danh sách các dịch vụ
      for (const service of this.portServiceList) {
        // Nếu chưa có mảng dịch vụ theo type, tạo mới
        if (!this.groupedServices[service.type]) {
          this.$set(this.groupedServices, service.type, []);
        }
        // Thêm dịch vụ vào mảng tương ứng với type
        this.groupedServices[service.type].push(service);
      }
    },
    getTypeName(type) {
      switch (type) {
        case 'C':
          return 'Vận chuyển đường biển';
        case 'T':
          return 'Dịch vụ Trucking lấy hàng từ kho tới Cảng';
        case 'L':
          return 'Dịch vụ giao hàng từ Cảng đến khách hàng';
        case '00':
          return 'Dịch vụ cảng';
        case '01':
          return 'Dịch vụ cảng';
        default:
          return type;
      }
    },
    openChat() {
      this.isPopupOpen = true
      console.log("check 1")
    },
    closeChat() {
      this.isPopupOpen = false
    },
  },
};
</script>

<style></style>