<template>
  <v-card flat>
    <v-card-text>
      <v-form
        @submit="onSignup()"
        onSubmit="return false;"
      >
        <v-alert
          outlined
          type="error"
          class="mb-8"
        >
          {{ $t('text-change-password-security') }}
        </v-alert>
        <p class="overline">{{ $t('change-password') }}</p>
        <v-row no-gutters>
          <v-col
            :cols="$vuetify.breakpoint.mobile ? 12 : 4"
            class="pr-2"
          >
            <div class="caption">{{ $t('old-password') }}</div>
            <v-text-field
              v-model.trim="$v.oldPassword.$model"
              :label="$t('label-old-password')"
              prepend-inner-icon="mdi-lock"
              type="password"
              outlined
              solo
              dense
              autocomplete
              :error-messages="oldPasswordErrors"
              :counter="32"
              @input="$v.oldPassword.$touch()"
              @blur="$v.oldPassword.$touch()"
            />
          </v-col>
          <v-col
            :cols="$vuetify.breakpoint.mobile ? 12 : 4"
            class="px-2"
          >
            <div class="caption">{{ $t('new-password') }}</div>

            <v-text-field
              v-model.trim="$v.newPassword.$model"
              :label="$t('label-new-password')"
              prepend-inner-icon="mdi-lock"
              type="password"
              outlined
              solo
              dense
              autocomplete="new-password"
              :error-messages="newPasswordErrors"
              :counter="32"
              @input="$v.newPassword.$touch()"
              @blur="$v.newPassword.$touch()"
            />
          </v-col>
          <v-col
            :cols="$vuetify.breakpoint.mobile ? 12 : 4"
            class="pl-2"
          >
            <div class="caption">{{ $t('confirm-new-password') }}</div>

            <v-text-field
              v-model.trim="$v.confirmNewPassword.$model"
              :label="$t('label-confirm-new-password')"
              prepend-inner-icon="mdi-lock"
              type="password"
              outlined
              solo
              dense
              autocomplete="new-password"
              :error-messages="confirmNewPasswordErrors"
              :counter="32"
              @input="$v.confirmNewPassword.$touch()"
              @blur="$v.confirmNewPassword.$touch()"
            />
          </v-col>
        </v-row>
        <v-row
          no-gutters
          class="mt-4"
        >
          <v-spacer />
          <v-btn
            color="primary"
            class="px-16"
            :loading="isLoading"
            :disabled="isLoading || $v.$invalid || isChangedPassword"
            @click="onChangePassword"
            >{{ $t('button-update') }}</v-btn
          >
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  sameAs,
} from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
export default {
  data: () => ({
    isLoading: false,
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    isChangedPassword: false,
  }),
  validations: {
    oldPassword: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(32),
    },
    newPassword: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(32),
    },
    confirmNewPassword: {
      required,
      sameAsPassword: sameAs('newPassword'),
    },
  },
  computed: {
    oldPasswordErrors() {
      const errors = [];
      const field = this.$v.oldPassword;
      if (!field.$dirty) return errors;
      !field.minLength &&
        errors.push(this.$t('validation-error-old-password-min-length'));
      !field.maxLength &&
        errors.push(this.$t('validation-error-old-password-max-length'));
      !field.required &&
        errors.push(this.$t('validation-error-old-password-required'));
      return errors;
    },
    newPasswordErrors() {
      const errors = [];
      const field = this.$v.newPassword;
      if (!field.$dirty) return errors;
      !field.minLength &&
        errors.push(this.$t('validation-error-new-password-min-length'));
      !field.maxLength &&
        errors.push(this.$t('validation-error-new-password-max-length'));
      !field.required &&
        errors.push(this.$t('validation-error-new-password-required'));
      return errors;
    },
    confirmNewPasswordErrors() {
      const errors = [];
      const field = this.$v.confirmNewPassword;
      if (!field.$dirty) return errors;
      !field.sameAsPassword &&
        errors.push(this.$t('validation-error-confirm-password-same'));
      !field.required &&
        errors.push(this.$t('validation-error-confirm-password-required'));
      return errors;
    },
  },
  methods: {
    ...mapActions('user', ['changeMyPassword']),
    async onChangePassword() {
      try {
        this.isLoading = true;
        const form = {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        };
        await this.changeMyPassword(form);
        this.$message.success(this.$t('text-change-password-success'));
        this.isChangedPassword = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
