<template>
    <div>
        <a-modal v-model="modalOpen" title="Popup Chat" width="750px" :footer="null" @cancel="closeChat"
            :mask-closable="false" :mask="false">
            <template #default>
                <div style="display: flex;">

                    <!-- Danh sách bạn bè -->
                    <div class="list">
                        <a-menu mode="inline" :open-keys="openKeys" style="width: 200px">
                            <a-sub-menu key="sub1">
                                <span slot="title"> <span>Navigation One</span></span>
                                <a-menu-item key="1">
                                    Option 1
                                </a-menu-item>
                                <a-menu-item key="2">
                                    Option 2
                                </a-menu-item>
                                <a-menu-item key="3">
                                    Option 3
                                </a-menu-item>
                                <a-menu-item key="4">
                                    Option 4
                                </a-menu-item>
                            </a-sub-menu>

                            <a-sub-menu key="sub1">
                                <span slot="title"><span>Navigation Two</span></span>
                                <a-menu-item key="5">
                                    Option 5
                                </a-menu-item>
                                <a-menu-item key="6">
                                    Option 6
                                </a-menu-item>

                            </a-sub-menu>
                            <a-sub-menu key="sub1">
                                <span slot="title"><span>Navigation Two</span></span>
                                <a-menu-item key="5">
                                    Option 5
                                </a-menu-item>
                                <a-menu-item key="6">
                                    Option 6
                                </a-menu-item>

                            </a-sub-menu>
                        </a-menu>
                    </div>

                    <div style="width: 100%;">
                        <div class="chat-content">
                            <!-- Nội dung chat -->
                            <div>
                                <div v-for="message in messages" :key="message.createdAt"
                                    style="display: inline-block;width: 100%; margin:5px 0;">
                                    <div
                                        :class="{ 'align-left': message.userCreate === 'user2', 'align-right': message.userCreate === 'user1' }">
                                        <div style="color: rgb(255, 252, 252);
                                            background-color: rgb(62, 165, 224); margin:0px 4px  ;
                                            padding:5px; border-radius:10px; ">
                                            {{ message.content }}
                                            <div style="text-align: right;">{{ message.createdAt }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="input-container" style="display: flex; align-items:center;margin-top:3px;">
                            <a-input style="margin-right:10px;" placeholder="Nhập tin nhắn" v-model="message" />
                            <a-button ghost
                                style="border: none; display: flex; align-items: center; text-align: center;"
                                @click="sendMessage">
                                <span class="mdi mdi-send" style="font-size: 30px;color:dodgerblue"></span>
                            </a-button>
                        </div>
                    </div>
                </div>


            </template>
        </a-modal>
    </div>
</template>

<script>
import { Button, Modal, Input, Menu, Icon } from 'ant-design-vue';

export default {
    name: 'ChatMessage',

    components: {
        'a-button': Button,
        'a-modal': Modal,
        'a-input': Input,
        'a-menu': Menu,
        'a-menu-item': Menu.Item,
        'a-sub-menu': Menu.SubMenu,
        'a-icon': Icon,
    },
    props: {
        isPopupOpen: {
            type: Boolean,
            required: true
        },
        closeChat: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            modalOpen: false,
            message: '',
            rootSubmenuKeys: ['sub1', 'sub2', 'sub4'],
            openKeys: ['sub1'],
            messages: [
                { userCreate: "user1", content: "test1", createdAt: "15-03-2024 16:46:43" },
                { userCreate: "user2", content: "test2", createdAt: "15-03-2024 16:48:43" },
                { userCreate: "user1", content: "test3", createdAt: "15-03-2024 16:46:43" },
                { userCreate: "user2", content: "test4", createdAt: "15-03-2024 16:48:43" },
                { userCreate: "user1", content: "test5", createdAt: "15-03-2024 16:46:43" },
                { userCreate: "user2", content: "test6", createdAt: "15-03-2024 16:48:43" },
                { userCreate: "user1", content: "test7", createdAt: "15-03-2024 16:46:43" },
                { userCreate: "user2", content: "test8", createdAt: "15-03-2024 16:48:43" },
                { userCreate: "user1", content: "test16", createdAt: "15-03-2024 16:46:43" },
                { userCreate: "user2", content: "test26", createdAt: "15-03-2024 16:48:43" },
                { userCreate: "user1", content: "test17", createdAt: "15-03-2024 16:46:43" },
                { userCreate: "user2", content: "test28", createdAt: "15-03-2024 16:48:43" },
                { userCreate: "user1", content: "test17", createdAt: "15-03-2024 16:46:43" },
                { userCreate: "user2", content: "test28", createdAt: "15-03-2024 16:48:43" },
                { userCreate: "user1", content: "test17 test17 test17 test17 test17 test17 test17 test17 test17 test17 test17 test17", createdAt: "15-03-2024 16:46:43" },
                { userCreate: "user2", content: "test28", createdAt: "15-03-2024 16:48:43" },
            ],
        };
    },
    watch: {
        isPopupOpen(newVal) {
            this.modalOpen = newVal;
        }
    },
    methods: {
        togglePopup() {
            this.modalOpen = !this.modalOpen;
        },
        sendMessage() {
            // Xử lý logic gửi tin nhắn
            console.log('Đã gửi tin nhắn:', this.message);
            this.message = ''; // Xóa nội dung tin nhắn sau khi gửi
        },
        onOpenChange(openKeys) {
            // const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
            // if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            //     this.openKeys = openKeys;
            // } else {
            //     this.openKeys = latestOpenKey ? [latestOpenKey] : [];
            // }
        },
    }
};

</script>
<style>
.ant-modal-root .ant-modal-wrap .ant-modal {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: 'tnum';
    width: auto;
    margin: 0 auto;
    pointer-events: none;
    position: fixed !important;
    top: auto !important;
    padding-bottom: 10px !important;
    right: 0 !important;
    bottom: 0 !important;
}

.input-container {
    display: flex;
    background-color: #f1f1f1;
    padding: 10px;
}

.chat-content {
    height: 90%;
}

.align-left {
    text-align: left;
    max-width: 50%;
}

.align-right {
    float: right;
    max-width: 70%;
}

.chat-content {
    overflow-y: auto;
    max-height: 450px;
    /* Điều chỉnh kích thước tùy ý */
}

.list {
    overflow-y: auto;
    max-height: 500px;
    /* Điều chỉnh kích thước tùy ý */
}
</style>