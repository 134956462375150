<template>
  <v-card
    class="elevation-0 py-6"
    :class="{
      'elevation-2 px-4': !$vuetify.breakpoint.mobile,
    }"
  >
    <v-toolbar flat>
      <v-toolbar-title class="overline mx-auto">
        {{ $t('login') }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="py-0">
      <v-form>
        <v-text-field
          label="Tài khoản/Email/SĐT"
          prepend-inner-icon="mdi-account-circle"
          ref="emailBox"
          type="text"
          outlined
          autofocus
          :disabled="isLoading"
          v-model.trim="$v.email.$model"
          :error-messages="emailErrors"
          @input="$v.email.$touch()"
          @blur="$v.email.$touch()"
        />
        <v-text-field
          label="Mật khẩu"
          prepend-inner-icon="mdi-lock"
          ref="passwordBox"
          name="password"
          type="password"
          outlined
          :disabled="isLoading"
          :error-messages="passwordErrors"
          v-model.trim="$v.password.$model"
          @input="$v.password.$touch()"
          @blur="$v.password.$touch()"
        />
        <v-btn
          width="100%"
          x-large
          color="primary"
          depressed
          :loading="isLoading"
          :disabled="isLoading || $v.$invalid"
          @click="onLogin()"
          >{{ $t('login') }}
        </v-btn>

        <div class="pt-2">
          Chưa có tài khoản?
          <span @click="$emit('change-tab', 'Signup')"
            ><router-link to="#">Đăng ký ngay</router-link></span
          >
        </div>
        <!-- <router-link class="pt-4" to="/auth/forgot-password"
            >Quên mật khẩu?</router-link
          > -->

        <v-row
          no-gutters
          class="align-center"
        >
          <v-divider />
          <p class="my-2 px-2">Hoặc đăng nhập với</p>
          <v-divider />
        </v-row>

        <div
          id="customBtn"
          ref="signinBtn"
        >
          <v-btn
            width="100%"
            x-large
            depressed
            id="customBtn"
            ref="signinBtn"
            :loading="isLoading"
            :disabled="isLoading"
            @click="isLoading = true"
          >
            <v-icon left> mdi-google </v-icon>
            Google
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  data: () => ({
    isLoading: false,
    email: '',
    password: '',
    auth2: null,
  }),
  validations: {
    email: {
      required,
      minLength: minLength(3),
    },
    password: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(32),
    },
  },
  computed: {
    ...mapState('ui', ['dialogAuth']),
    emailErrors() {
      const errors = [];
      const field = this.$v.email;
      if (!field.$dirty) return errors;
      !field.minLength && errors.push('Tài khoản không hợp lệ');
      !field.required && errors.push('Tài khoản bắt buộc');
      return errors;
    },
    passwordErrors() {
      const errors = [];
      const field = this.$v.password;
      if (!field.$dirty) return errors;
      !field.minLength && errors.push('Mật khẩu phải có tối thiểu 3 kí tự');
      !field.maxLength && errors.push('Mật khẩu chứa tối đa 32 kí tự');
      !field.required && errors.push('Mật khẩu bắt buộc');
      return errors;
    },
    dialog: {
      get() {
        return this.dialogAuth;
      },
      set(value) {
        this.TOGGLE_DIALOG_AUTH(value);
      },
    },
  },
  mounted() {
    this.$gapi.load('auth2', () => {
      this.auth2 = this.$gapi.auth2.init({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        cookiepolicy: 'single_host_origin',
      });
      this.attachSignin(this.$refs.signinBtn);
    });
  },
  methods: {
    ...mapMutations('ui', ['TOGGLE_DIALOG_AUTH']),
    ...mapActions('auth', ['login', 'loginWithGoogle']),
    async onLogin() {
      try {
        this.isLoading = true;

        const form = {
          email: this.email,
          password: this.password,
        };
        await this.login(form);
      } catch (error) {
        const snackbarOption = {
          message: '',
          color: 'error',
        };
        snackbarOption.message = 'Thông tin đăng nhập không chính xác!';
        this.$store.dispatch('ui/showSnackbar', snackbarOption);
      } finally {
        this.isLoading = false;
      }
    },
    attachSignin(element) {
      this.auth2.attachClickHandler(
        element,
        {},
        async (googleUser) => {
          console.log('googleUser', googleUser.getAuthResponse());
          await this.loginWithGoogle({
            token: googleUser.getAuthResponse().id_token,
          });
          this.isLoading = false;
        },
        (error) => {
          console.log('[ERROR] Google auth ', error);
          this.isLoading = false;
        }
      );
    },
  },
};
</script>
