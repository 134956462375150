<template>
  <main>
    <NavBarHome active="home" />
    <div class="fixed-form">
      <div
        class="form-booking-tracking"
        v-if="showForm"
      >
        <v-row>
          <v-col :cols="$vuetify.breakpoint.mobile ? 7 : 9"></v-col>
          <v-col :cols="$vuetify.breakpoint.mobile ? 5 : 3">
            <v-card
              class="py-1 px-2"
              rounded="lg"
              style="width: 450px; margin-left: 10px"
            >
              <v-icon
                @click="showForm = false"
                class="float-right"
                style="cursor: pointer"
                >mdi-close-circle</v-icon
              >
              <v-tabs
                v-model="tab"
                class="mb-4 tab"
                height="36px"
              >
                <v-tab :ripple="false">Booking</v-tab>
                <v-tab :ripple="false">{{ $t('title-tracking') }}</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item
                  key="0"
                  eager
                >
                  <div class="form">
                    <h6 class="">Request a booking</h6>
                    <form class="form-input">
                      <v-row no-gutters>
                        <v-col
                          :cols="12"
                          class="mb-5"
                        >
                          <v-row
                            no-gutters
                            align="end"
                          >
                            <v-col
                              :cols="
                                $vuetify.breakpoint.lg ||
                                $vuetify.breakpoint.mobile ||
                                $vuetify.breakpoint.mobile
                                  ? 12
                                  : 5
                              "
                            >
                              <div class="caption">{{ $t('from') }}</div>
                              <PortPicker
                                :value="selectedPolCode"
                                @change="onSelectPOL"
                                solo
                                :label="$t('label-pol')"
                                clearable
                                hide-details
                                dense
                                style="border: 1px solid #ccc"
                              />
                            </v-col>
                            <v-col
                              cols="2"
                              class="d-flex align-center justify-center mb-2"
                            >
                              <v-icon
                                v-if="
                                  !$vuetify.breakpoint.lg &&
                                  !$vuetify.breakpoint.mobile
                                "
                                color="primary"
                                class=""
                              >
                                mdi-swap-horizontal-circle
                              </v-icon>
                            </v-col>
                            <v-col
                              :cols="
                                $vuetify.breakpoint.lg ||
                                $vuetify.breakpoint.mobile
                                  ? 12
                                  : 5
                              "
                            >
                              <div class="caption">{{ $t('to') }}</div>
                              <PortPicker
                                :value="selectedPodCode"
                                @change="onSelectPOD"
                                solo
                                :label="$t('label-pod')"
                                clearable
                                hide-details
                                dense
                                style="border: 1px solid #ccc"
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col :cols="12">
                          <v-row
                            no-gutters
                            align="end"
                          >
                            <v-dialog
                              ref="dialog"
                              v-model="modal"
                              persistent
                              width="670px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <div style="width: 100%">
                                  <div class="caption">{{ $t('etd-eta') }}</div>
                                  <v-text-field
                                    :value="dateRangeText"
                                    label="ETD - ETA"
                                    hide-details
                                    prepend-inner-icon="mdi-calendar-month"
                                    readonly
                                    solo
                                    dense
                                    style="border: 1px solid #ccc"
                                    v-bind="attrs"
                                    v-on="on"
                                  />
                                </div>
                              </template>
                              <v-container class="custom-container">
                                <v-row>
                                  <v-col cols="6">
                                    <v-date-picker
                                      v-model="dates[0]"
                                      :allowedDates="allowedDates"
                                      @change="onChangeStartDate"
                                      full-width
                                    />
                                  </v-col>
                                  <v-col cols="6">
                                    <v-date-picker
                                      v-model="dates[1]"
                                      :allowedDates="allowedDates"
                                      @change="onChangeEndDate"
                                      full-width
                                    />
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12">
                                    <v-btn
                                      block
                                      color="primary"
                                      @click="onSelectEtdEta"
                                    >
                                      {{ $t('button-select') }}
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-dialog>
                          </v-row>
                        </v-col>
                      </v-row>
                      <div class="submit-form">
                        <input
                          type="button"
                          value="request a booking"
                          @click="searchSchedule"
                        />
                      </div>
                    </form>
                  </div>
                </v-tab-item>
                <v-tab-item
                  key="1"
                  eager
                >
                  <div class="form">
                    <h6 class="pb-2">Request a tracking</h6>
                    <form class="form-input">
                      <v-row
                        align="center"
                        class="tracking-input"
                      >
                        <v-text-field
                          v-model="trackingNo"
                          clearable
                          :placeholder="$t('label-tracking-search')"
                          type="text"
                          prepend-inner-icon="mdi-google-nearby"
                          solo
                          hide-details
                          item-text="name"
                          item-value="code"
                          :loading="isLoading"
                          :readonly="isLoading"
                          @click:clear="onClearSearch"
                        />
                      </v-row>
                      <div class="submit-form">
                        <input
                          type="button"
                          value="request a tracking"
                          @click="onTrackingContainer"
                        />
                      </div>
                    </form>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <!-- 
      <v-icon
        v-if="showBackToTop"
        @click="scrollToTop"
        style="
          cursor: pointer;
          position: fixed;
          bottom: 40px;
          right: 40px;
          font-size: 40px;
          z-index: 900;
          color: #fff;
          border: #e8734e 4px solid;
          border-radius: 50%;
          background-color: #e8734e;
        "
        v-bind="attrs"
        v-on="on"
      >
        mdi-arrow-up
      </v-icon> -->
      <!-- <v-tooltip bottom> -->
      <!-- <template v-slot:activator="{ on, attrs }"> -->
      <v-icon
        v-if="!showForm"
        @click="showForm = true"
        style="
          cursor: pointer;
          position: fixed;
          bottom: 40px;
          right: 40px;
          font-size: 40px;
          z-index: 1000;
          color: #fff;
          border: #e8734e 4px solid;
          border-radius: 50%;
          background-color: #e8734e;
        "
        v-bind="attrs"
        v-on="on"
      >
        mdi-open-in-app
      </v-icon>
      <!-- </template> -->
      <!-- <span>Open Form</span> -->
      <!-- </v-tooltip> -->
    </div>

    <ContentHome />
    <FooterHome />
    <!-- Back to top button -->
    <a
      href="/home"
      class="back-to-top"
      ><i class="fa fa-chevron-up"></i
    ></a>

    <NavBarLeftHome />
  </main>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import PortPicker from '@/components/shared/local/vsl/PortPicker.vue';
import dayjs from 'dayjs';
import NavBarHome from '@/views/Home/PageChild/NavBarHome.vue';
import FooterHome from '@/views/Home/PageChild/FooterHome.vue';
import NavBarLeftHome from '@/views/Home/PageChild/NavBarLeftHome.vue';
import ContentHome from '@/views/Home/PageChild/ContentHome.vue';

export default {
  components: {
    ContentHome,
    NavBarLeftHome,
    FooterHome,
    NavBarHome,
    PortPicker,
  },
  data: () => ({
    showForm: true,
    showBackToTop: false,
    tab: 0,
    trackingNo: null,
    selectedEtdEta: [],
    selectedPolCode: null,
    selectedPodCode: null,
    dates: [dayjs().format('YYYY-MM-DD')],
    modal: false,
    isLoading: false,
  }),
  async created() {
    const { search, vessel_code, pol_code, pod_code, from_etd, to_etd } =
      this.$route.query;

    // Gán giá trị từ query params
    this.typedSearch = search;
    this.selectedVesselCode = vessel_code || null;
    this.selectedPolCode = pol_code || null;
    this.selectedPodCode = pod_code || null;

    // Nếu có giá trị from_etd và to_etd, gán vào dates
    if (from_etd && to_etd) {
      this.dates = [from_etd, to_etd];
    } else {
      // Nếu không có, đặt mặc định
      this.dates = [
        dayjs().format('YYYY-MM-DD'),
        dayjs().add(1, 'month').format('YYYY-MM-DD'),
      ];
    }
  },
  watch: {
    '$route.query': {
      handler(newQuery) {
        const { from_etd, to_etd } = newQuery;
        if (from_etd && to_etd) {
          this.dates = [from_etd, to_etd];
        }
      },
      immediate: true,
    },
  },
  destroyed() {
    this.RESET();
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions('vsl/vesselSchedule', ['searchVesselSchedule']),
    ...mapMutations('vsl/vesselSchedule', [
      'SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS',
      'SET_VESSEL_SCHEDULE_LIST_LOADING',
    ]),
    allowedDates(date) {
      return dayjs(date).isAfter(dayjs().subtract(1, 'day'));
    },
    async searchSchedule() {
      // try {
      //   this.isSearch = true;
      //   this.isLoading = true;

      //   // Kiểm tra điều kiện đầu vào
      //   if (this.tab === 0) {
      //     if (!this.selectedPolCode || !this.selectedPodCode) {
      //       this.$message.error(this.$t('validation-message-pol-pod-required'));
      //       return;
      //     }
      //     if (this.selectedPolCode === this.selectedPodCode) {
      //       this.$message.error(this.$t('validation-message-pol-pod-not-same'));
      //       return;
      //     }

      //     // Thực hiện tìm kiếm lịch trình tàu
      //     const result = await this.searchVesselSchedule({
      //       searchType: 'portSearching',
      //     });
      //     console.log('Search result:', result); // Kiểm tra kết quả trả về từ API

      //     // Chuyển hướng đến trang Booking cùng với dữ liệu cần thiết

      //     // Cuộn lên đầu trang sau khi chuyển hướng thành công
      //     window.scrollTo(0, 0);
      //   }
      // } catch (error) {
      //   console.error('Error during searchSchedule:', error); // Log lỗi nếu có vấn đề xảy ra trong quá trình tìm kiếm
      // } finally {
      //   this.isLoading = false; // Đảm bảo isLoading được đặt về false khi kết thúc
      // }
      await this.$router
        .push({
          path: '/Booking',
          query: {
            pol_code: this.selectedPolCode,
            pod_code: this.selectedPodCode,
            from_etd:
              this.selectedEtdEta && this.selectedEtdEta[0]
                ? this.selectedEtdEta[0]
                : undefined,
            to_etd:
              this.selectedEtdEta && this.selectedEtdEta[1]
                ? this.selectedEtdEta[1]
                : undefined,
          },
        })
        .catch((err) => {
          console.error('Router error:', err); // Log lỗi nếu việc chuyển hướng không thành công
        });
    },
    async onSelectPOL(item) {
      this.selectedPolCode = item?.code;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({ polCode: item?.code });
    },
    async onSelectPOD(item) {
      this.selectedPodCode = item?.code;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({ podCode: item?.code });
    },

    onChangeStartDate(value) {
      const startDate = dayjs(value);
      const endDate = dayjs(this.dates[1]);

      if (endDate.isBefore(startDate)) {
        this.dates[1] = startDate.format('YYYY-MM-DD');
      } else {
        this.dates[0] = value;
      }

      this.selectedEtdEta = this.dates;
      this.updateQueryParams(); // Cập nhật lại query params khi có thay đổi
    },

    onChangeEndDate(value) {
      const startDate = dayjs(this.dates[0]);
      const endDate = dayjs(value);

      if (endDate.isBefore(startDate)) {
        this.dates[1] = startDate.format('YYYY-MM-DD');
      } else {
        this.dates[1] = value;
      }

      this.selectedEtdEta = this.dates;
      this.updateQueryParams(); // Cập nhật lại query params khi có thay đổi
    },
    updateQueryParams() {
      // Cập nhật lại query params khi giá trị ngày thay đổi
      this.$router.push({
        query: {
          ...this.$route.query,
          from_etd: this.dates[0],
          to_etd: this.dates[1],
        },
      });
    },
    async onSelectEtdEta() {
      this.modal = false;
      if (this.dates.length > 1) {
        if (dayjs(this.dates[0]).isAfter(dayjs(this.dates[1]))) {
          this.dates = [this.dates[1], this.dates[0]];
        }
      }
      console.log(this.dates);
      this.selectedEtdEta = this.dates;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({
        etdEta: this.selectedEtdEta,
      });
    },

    //tracking
    ...mapMutations('vsl/containerTracking', ['RESET']),
    ...mapActions('vsl/containerTracking', ['fetchContainerTrackingInfo']),
    async onTrackingContainer() {
      // if (!this.trackingNo || this.trackingNo.length === 0) {
      //   await this.$router.push('').catch((e) => e);
      //   return;
      // }
      try {
        this.isLoading = true;
        await this.$router
          .push({
            path: '/Tracking',
            query: {
              tracking_no: this.trackingNo,
            },
          })
          .catch((e) => e);

        // Gọi hàm fetchContainerTrackingInfo sau khi chuyển hướng
        await this.$nextTick(); // Đảm bảo rằng việc chuyển hướng đã hoàn tất
        // await this.fetchContainerTrackingInfo({
        //   containerNo: this.trackingNo,
        // });

        this.render = false;
        await new Promise((resolve) => setTimeout(resolve, 100));
        this.render = true;

        if (this.$vuetify.breakpoint.mobile) {
          this.dialog.result = true;
        }
      } finally {
        this.isLoading = false;
      }
    },
    async onClearSearch() {
      await this.$router.push('').catch((e) => e);
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleScroll() {
      this.showBackToTop = window.scrollY > 100;
    },
  },
  computed: {
    ...mapState('vsl/vesselSchedule', {
      queryParams: 'vesselScheduleListQueryParams',
      vesselScheduleListLoading: 'vesselScheduleListLoading',
    }),
    ...mapState('vpl/port', { list: 'portList' }),
    dateRangeText() {
      const formated = this.selectedEtdEta.map((date) => {
        return dayjs(date).format('DD.MM.YYYY');
      });
      return formated.join(' - ');
    },
    //tracking
    ...mapState('vsl/containerTracking', ['containerMovements']),
  },
};

('use strict');

window.addEventListener('scroll', function () {
  const navbar = document.getElementById('navbarCr');
  if (navbar) {
    if (window.scrollY > 0) {
      navbar.style.marginTop = '-5px';
    } else {
      navbar.style.marginTop = '5px';
    }
  }
});

document.addEventListener('DOMContentLoaded', function () {
  const openBtn = document.querySelector('.nav-column-open-btn');
  const closeBtn = document.querySelector('.nav-column-close-btn');
  const overlay = document.querySelector('.overlay');
  const navbarColumn = document.querySelector('.navbar-column');
  if (openBtn) {
    openBtn.addEventListener('click', function () {
      navbarColumn.classList.add('active');
      overlay.classList.add('active');
    });

    closeBtn.addEventListener('click', function () {
      navbarColumn?.classList.remove('active');
      overlay?.classList.remove('active');
    });

    overlay.addEventListener('click', function () {
      navbarColumn.classList.remove('active');
      overlay.classList.remove('active');
    });
  }
});

/**
 * header
 */
// window.addEventListener('scroll', function() {
//   const navbar = document.getElementsByClassName('headerN');
//   if (window.scrollY > 0) {
//     navbar.classList.add('scrolled');
//   } else {
//     navbar.classList.remove('scrolled');
//   }
// });
</script>
<style>
/*
Removes white gap between slides
*/
.fixed-form {
  position: fixed; /* Giữ form cố định khi cuộn */
  top: 0; /* Cố định form ở phía trên màn hình */
  left: 0;
  width: 100%; /* Đảm bảo form chiếm toàn bộ chiều rộng */
  z-index: 999;
  background-color: white; /* Đảm bảo form có nền màu để không bị các phần tử khác lấn vào */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Thêm bóng để tạo hiệu ứng nổi */
}

.form-input {
  z-index: 1000;
}

.form-input .v-text-field {
  border: 1px solid #ccc;
}

.tracking-input {
  margin: 0 -12px 0 0;
}

.submit-form input {
  font-size: 16px;
}

.submit-form input:hover {
  background: #e6734f !important;
  border-bottom-right-radius: 10px;
  color: #ffffff;
}

.tab .v-tab {
  color: #e6734f;
  font-size: 16px;
}

.tab .v-tabs-slider {
  background: #e6734f !important;
}

.carousel {
  position: relative;
  width: 100%;
  min-height: 400px;
  background: #ffffff;
  margin-bottom: 45px;
}

.carousel-item .img-fluid {
  width: 100%;
  height: 850px;
}

/*
anchors are inline so you need ot make them block to go full width
*/
.carousel-item a {
  display: block;
  width: 100%;
}
/* // */
.transx_content_slide_wrapper {
  text-align: left;
  letter-spacing: 0;
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 1.7;
  font-weight: 400;
  color: #696e76;
  hyphens: manual;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;
  justify-content: center;
}
.transx_up_heading_overlay {
  hyphens: manual;
  -webkit-tap-highlight-color: transparent;
  text-align: center;
  box-sizing: border-box;
  line-height: 0.85;
  font-weight: 700;
  color: transparent;
  user-select: none;
  position: absolute;
  z-index: 1;
  display: initial;
  font-family: Inter, 'sans-serif';
  font-size: 200px;
  letter-spacing: -0.06em;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.7);
  top: -54px;
  left: 50%;
  transform: translateX(-50%);
}
.transx_content_slider_title {
  text-align: center;
  letter-spacing: -0.03em;
  word-wrap: break-word;
  margin: 0 0 0.5rem;
  font-family: Inter, sans-serif;
  font-weight: 700;
  text-transform: none;
  font-style: normal;
  box-sizing: border-box;
  color: #fff;
  margin-bottom: 20px;
  position: relative;
  z-index: 5;
  font-size: 60px;
  line-height: 60px;
}
.no-legend legend {
  display: block;
  width: 0% !important;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
</style>
