<template>
  <v-card
    class="elevation-0 py-6"
    :class="{
      'elevation-2 px-4': !$vuetify.breakpoint.mobile,
    }"
  >
    <v-toolbar flat>
      <v-toolbar-title class="overline mx-auto">
        Tạo tài khoản
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="py-0">
      <v-form>
        <v-text-field
          v-model.trim="$v.name.$model"
          label="Họ tên"
          prepend-inner-icon="mdi-account"
          type="text"
          outlined
          rounded
          autofocus
          :error-messages="nameErrors"
          :counter="32"
          @input="$v.name.$touch()"
          @blur="$v.name.$touch()"
        />
        <v-text-field
          v-model.trim="$v.phone.$model"
          label="Số điện thoại"
          prepend-inner-icon="mdi-cellphone"
          type="tel"
          outlined
          rounded
          :error-messages="phoneErrors"
          :counter="12"
          @input="$v.name.$touch()"
          @blur="$v.name.$touch()"
        />
        <v-text-field
          v-model.trim="$v.email.$model"
          label="Email"
          prepend-inner-icon="mdi-email"
          type="text"
          outlined
          rounded
          :error-messages="emailErrors"
          @input="$v.name.$touch()"
          @blur="$v.name.$touch()"
        />
        <v-text-field
          v-model="$v.address.$model"
          label="Địa chỉ"
          prepend-inner-icon="mdi-email"
          type="text"
          outlined
          rounded
          :counter="256"
          :error-messages="addressErrors"
          @input="$v.address.$touch()"
          @blur="$v.address.$touch()"
        />
        <v-text-field
          v-model.trim="$v.password.$model"
          label="Mật khẩu"
          prepend-inner-icon="mdi-lock"
          type="password"
          rounded
          outlined
          autocomplete="new-password"
          :error-messages="passwordErrors"
          :counter="32"
          @input="$v.password.$touch()"
          @blur="$v.password.$touch()"
        />
        <v-text-field
          v-model.trim="$v.confirmPassword.$model"
          label="Nhập lại mật khẩu"
          prepend-inner-icon="mdi-lock"
          type="password"
          rounded
          outlined
          autocomplete="new-password"
          :error-messages="confirmPasswordErrors"
          :counter="32"
          @input="$v.confirmPassword.$touch()"
          @blur="$v.confirmPassword.$touch()"
        />
      </v-form>
    </v-card-text>
    <v-card-actions class="d-flex flex-column px-4">
      <v-btn
        width="100%"
        x-large
        color="primary"
        depressed
        rounded
        :loading="isLoading"
        :disabled="isLoading || $v.$invalid"
        @click="onSignup"
        >Tạo tài khoản</v-btn
      >
      <v-btn
        width="100%"
        x-large
        depressed
        rounded
        class="mt-4"
        @click="$emit('change-tab', 'Login')"
        :disabled="isLoading"
      >
        Quay lại
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  helpers,
  required,
  minLength,
  maxLength,
  email,
  sameAs,
} from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

const validPhone = helpers.regex(
  'validPhone',
  /((09|03|07|08|05)+([0-9]{8})\b)/g
);

export default {
  data: () => ({
    isLoading: false,
    name: '',
    phone: '',
    email: '',
    address: '',
    password: '',
    confirmPassword: '',
  }),
  validations: {
    name: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(32),
    },
    address: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(256),
    },
    email: {
      required,
      email,
    },
    phone: {
      required,
      validPhone,
    },
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(32),
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs('password'),
    },
  },
  computed: {
    nameErrors() {
      const errors = [];
      const field = this.$v.name;
      if (!field.$dirty) return errors;
      !field.minLength && errors.push('Họ tên phải có tối thiểu 6 kí tự');
      !field.maxLength && errors.push('Họ tên chứa tối đa 32 kí tự');
      !field.required && errors.push('Họ tên bắt buộc');
      return errors;
    },
    addressErrors() {
      const errors = [];
      const field = this.$v.address;
      if (!field.$dirty) return errors;
      !field.minLength && errors.push('Địa chỉ phải có tối thiểu 2 kí tự');
      !field.maxLength && errors.push('Địa chỉ chứa tối đa 32 kí tự');
      !field.required && errors.push('Địa chỉ bắt buộc');
      return errors;
    },
    phoneErrors() {
      const errors = [];
      const field = this.$v.phone;
      if (!field.$dirty) return errors;
      !field.validPhone && errors.push('Số điện thoại không hợp lệ');
      !field.required && errors.push('Số điện thoại bắt buộc');
      return errors;
    },
    emailErrors() {
      const errors = [];
      const field = this.$v.email;
      if (!field.$dirty) return errors;
      !field.email && errors.push('Email không hợp lệ');
      !field.required && errors.push('Email bắt buộc');
      return errors;
    },
    passwordErrors() {
      const errors = [];
      const field = this.$v.password;
      if (!field.$dirty) return errors;
      !field.minLength && errors.push('Mật khẩu phải có tối thiểu 6 kí tự');
      !field.maxLength && errors.push('Mật khẩu chứa tối đa 32 kí tự');
      !field.required && errors.push('Mật khẩu bắt buộc');
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      const field = this.$v.confirmPassword;
      if (!field.$dirty) return errors;
      !field.sameAsPassword && errors.push('Mật khẩu không trùng khớp');
      !field.required && errors.push('Nhập lại mật khẩu bắt buộc');
      return errors;
    },
  },
  methods: {
    ...mapActions('auth', ['signup']),
    ...mapActions('ui', ['showSnackbar']),
    async onSignup() {
      const snackbarOption = {
        message: '',
        color: 'error',
      };
      try {
        this.isLoading = true;

        const form = {
          name: this.name,
          address: this.address,
          email: this.email,
          password: this.password,
          phone: this.phone,
        };

        await this.signup(form);
        snackbarOption.color = 'success';
        snackbarOption.message = 'Tạo tài khoản thành công';
      } catch (error) {
        if (error?.response?.data?.message) {
          snackbarOption.message = error?.response?.data?.message;
        } else {
          snackbarOption.message = 'Có lỗi xảy ra!';

          this.$sentry.captureException(error);
        }
      } finally {
        this.showSnackbar(snackbarOption);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
