import CommodityApi from '@/api/vsl/commodity.api';

const initialState = () => ({
  commodity: {
    code: 'null',
    name: '',
    categoryName: '',
  },
  commodityList: [],
});

const state = initialState();
const getters = {};
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  SET_COMMODITY_LIST(state, payload) {
    state.commodityList = payload;
  },
};
const actions = {
  async fetchCommodityList({ commit }, payload) {
    const res = await CommodityApi.getCommodityList();
    const mapped = res.data.data.map((item) => {
      const mappedItem = {
        code: item.code,
        name: item.name,
        categoryName: item.categoryName,
      };
      return mappedItem;
    });
    commit('SET_COMMODITY_LIST', mapped);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
