<template>
  <v-row>
    <v-col
      cols="12"
      :class="{
        'px-1': $vuetify.breakpoint.mobile,
      }"
    >
      <div>
        <div
          class="px-2"
          :class="{
            'mt-14': !$vuetify.breakpoint.mobile,
            'mt-16': $vuetify.breakpoint.mobile,
          }"
        >
          <p
            class="mt-4 text-uppercase primary--text"
            :class="{
              'text-h3': !$vuetify.breakpoint.mobile,
              'text-h4': $vuetify.breakpoint.mobile,
            }"
            style="font-weight: 600"
          >
            Link people <br />
            Link the world
          </p>
          <v-btn
            class="px-0"
            style="
              font-size: 0.7rem;
              font-weight: 600;
              color: rgba(0, 0, 0, 0.7) !important;
            "
            disabled
            text
            :ripple="false"
            rounded
            small
            >SHIP TO AND FROM ANYWHERE IN THE WORLD</v-btn
          >
        </div>
        <div
          style="margin-top: 96px"
          :class="{
            'mt-12': $vuetify.breakpoint.mobile,
          }"
        >
          <v-row no-gutters>
            <!-- <v-btn text small to="/app" target="_blank">
              Request a quote
              <v-icon right small>mdi-arrow-top-right</v-icon>
            </v-btn> -->
            <v-spacer />
            <div class="">
              <span class="">by</span>
              <span class="ml-1 font-weight-bold">VIMC</span>
            </div>
          </v-row>
          <v-row>
            <v-col cols="5">
              <v-row
                no-gutters
                class="my-2"
                align="center"
                justify="center"
              >
                <v-row
                  no-gutters
                  align="center"
                >
                  <v-card
                    rounded="lg"
                    width="100%"
                    style="overflow: hidden"
                  >
                    <v-tabs
                      v-model="tab"
                      :active-class="searchMenuTabs[tab].color"
                      height="40px"
                      grow
                    >
                      <v-tabs-slider
                        :color="`${searchMenuTabs[tab].color}`"
                      ></v-tabs-slider>
                      <v-tab
                        v-for="item in searchMenuTabs"
                        :key="item.key"
                        :active-class="`${item.color} ${item.colorModifier} ${item.color}--text`"
                        :ripple="false"
                      >
                        <v-icon
                          left
                          v-text="item.icon"
                          :color="item.color"
                        >
                        </v-icon>
                        {{ item.title }}
                      </v-tab>
                    </v-tabs>
                  </v-card>
                </v-row>
              </v-row>
            </v-col>
            <v-col cols="7"> </v-col>
          </v-row>
        </div>

        <v-tabs-items v-model="tab">
          <v-tab-item key="0">
            <ContainerTrackingSearch />
          </v-tab-item>
          <v-tab-item key="1">
            <VesselScheduleSearch request-open-in-new-tab />
          </v-tab-item>
          <!-- <v-tab-item key="2">
            <DebitSearch />
          </v-tab-item> -->
        </v-tabs-items>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapState } from 'vuex';
import VesselScheduleSearch from '@/components/shared/local/vsl/VesselScheduleSearch';
import ContainerTrackingSearch from '@/components/shared/local/vsl/ContainerTrackingSearch';

export default {
  components: {
    VesselScheduleSearch,
    ContainerTrackingSearch,
  },
  data: () => ({
    tab: 0,
    searchMenuTabs: [
      {
        key: 0,
        title: 'Tracking',
        icon: 'mdi-cube-scan',
        color: 'brown',
        colorModifier: 'lighten-5',
        colorModifierActive: 'lighten-3',
      },
      {
        key: 1,
        title: 'Booking',
        icon: 'mdi-focus-field',
        color: 'light-blue',
        colorModifier: 'lighten-5',
        colorModifierActive: 'lighten-3',
      },
      // {
      //   key: 2,
      //   title: 'Debit',
      //   icon: 'mdi-file-document-multiple-outline',
      //   color: 'green',
      //   colorModifier: 'lighten-5',
      //   colorModifierActive: 'lighten-3',
      // },
    ],
  }),
  computed: {},
  created() {
    const { tracking_no, debit_search_no, pol_code, pod_code } =
      this.$route.query;
    if (tracking_no) {
      this.tab = 0;
    }
    if (pol_code || pod_code) {
      this.tab = 1;
    }
    // if (debit_search_no){
    //   this.tab = 2;
    // }
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .v-btn::before {
    background-color: transparent;
  }
  .v-tabs-items {
    background-color: transparent !important;
  }
}
</style>
