var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "gmap-map",
    {
      staticStyle: { width: "100%", height: "500px" },
      attrs: { center: _vm.center, zoom: 5 },
    },
    [
      _vm._l(_vm.places, function (place, index) {
        return _c("gmap-marker", {
          key: "marker-" + index,
          attrs: {
            position: { lat: place.latitude, lng: place.longitude },
            title: place.title,
            clickable: true,
            draggable: true,
          },
          on: {
            click: function ($event) {
              _vm.center = { lat: place.latitude, lng: place.longitude }
            },
          },
        })
      }),
      _c("gmap-polyline", {
        attrs: { path: _vm.path, options: _vm.polylineOptions },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }