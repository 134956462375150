<template>
  <a-card
    width="100%"
    style="
      margin: 20px 0;
      box-shadow: 0 25px 25px -12px rgb(0 0 0 / 0.25);
      border-radius: 20px;
    "
  >
    <template #title>
      <div style="display: flex; justify-content: space-between">
        <div style="display: flex; align-items: center">
          <span>
            <span
              class="mdi mdi-record-circle"
              style="color: blue"
            ></span>
            Hà Nội
          </span>
          <hr style="width: 20px; margin: 0 5px" />
          <span>
            <span
              class="mdi mdi-map-marker"
              style="color: red"
            ></span>
            Hồ Chí Minh
          </span>
        </div>
        <div>
            <span style="margin-right: 10px">20/3/2024</span>
          <v-btn
            style="
              color: #aec76d;
              background-color: #f0f9eb;
              text-transform: none;
            "
          >
            Done
          </v-btn>
        </div>
      </div>
    </template>
    <template>
      <div style="display: flex">
        <div style="width: 20%">
          <div>#OD00051</div>
          <div style="font-weight: 900">40HC</div>
        </div>
        <div style="width: 70%">
          <div>
            <span> Commodity </span
            ><span style="margin-left: 5px; font-weight: 900">Thực phẩm</span>
          </div>
          <div>
            <span>Delivery date</span
            ><span style="margin-left: 5px; font-weight: 900">18/03/2024</span>
          </div>
        </div>
        <div
          style="
            width: 10%;
            display: flex;
            align-items: center;
            justify-content: end;
            color: #2e90fa;
            font-size: 23px;
            font-weight: 600;
          "
        >
          $100
        </div>
      </div>
    </template>
  </a-card>
</template>

<script>
export default {};
</script>

<style></style>
