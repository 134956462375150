<template>
  <div
    style="
      padding: 30px;
      margin-top: 20px;
      display: flex;
      justify-content: center;
    "
  >
    <template>
      <div style="width: 100%">
        <div>
          <a-button @click="goBack"
            ><span class="mdi mdi-arrow-left"></span><span>Back</span></a-button
          >
        </div>
        <RequestBookingHistoryListItem />
        <RequestBookingHistoryPartner />
        <RequestBookingHistoryProcess />
      </div>
    </template>
  </div>
</template>

<script>
import RequestBookingHistoryListItem from '../RequestBookingHistoryListItem.vue';
import RequestBookingHistoryPartner from './RequestBookingHistoryPartner/index.vue';
import RequestBookingHistoryProcess from './RequestBookingHistoryProcess.vue';
export default {
  name: 'RequestBookingHistoryDetail',
  components: {
    RequestBookingHistoryListItem,
    RequestBookingHistoryPartner,
    RequestBookingHistoryProcess,
  },
  data: () => ({}),
  computed: {},
  methods: {
    goBack() {
      this.$router.push(`/app/shipping/account/request-booking-history`);
    },
  },
};
</script>

<style>
/* CSS styles */
</style>
