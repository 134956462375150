<template>
  <swiper
    class="swiper"
    :options="swiperOption"
  >
    <swiper-slide class="slide-1">
      <v-container>
        <v-row
          no-gutters
          style="padding-top: 140px"
        >
          <v-col
            :cols="$vuetify.breakpoint.mobile ? 8 : 6"
            class="white--text"
          >
            <div
              class="font-weight-medium"
              :style="`font-size: ${
                $vuetify.breakpoint.mobile ? '16px' : '36px'
              };`"
            >
              VIMC PROVIDES FREE SHIPPING SERVICES SUPPORTING THE CONTROL OF
              COVID-19
            </div>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          class="mt-4"
        >
          <v-col
            :cols="$vuetify.breakpoint.mobile ? 11 : 8"
            class="white--text"
          >
            <div
              :style="`font-size: ${
                $vuetify.breakpoint.mobile ? '12px' : '14px'
              }; opacity: 0.65;`"
            >
              Responding to the Government's call, the Prime Minister on the
              activities of sharing difficulties, supporting localities,
              organizations, businesses and people in epidemic prevention and
              control Covid-19. With the strength of a large-scale corporation,
              having a fleet Sea transportation, seaports and logistics systems
              stretching over nationwide, Vietnam Maritime Corporation (VIMC)
              will provide providing free shipping services to support room
              work, fight against Covid-19.
              <br />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </swiper-slide>
    <swiper-slide>
      <swiper-slide class="slide-2">
        <v-container>
          <v-row
            no-gutters
            style="padding-top: 140px"
          >
            <v-col
              :cols="$vuetify.breakpoint.mobile ? 8 : 6"
              class="white--text"
            >
              <div
                class="font-weight-medium"
                :style="`font-size: ${
                  $vuetify.breakpoint.mobile ? '16px' : '36px'
                };`"
              >
                VIMC PROVIDES FREE SHIPPING SERVICES SUPPORTING THE CONTROL OF
                COVID-19
              </div>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            class="mt-4"
          >
            <v-col
              :cols="$vuetify.breakpoint.mobile ? 11 : 8"
              class="white--text"
            >
              <div
                :style="`font-size: ${
                  $vuetify.breakpoint.mobile ? '12px' : '14px'
                }; opacity: 0.65;`"
              >
                Responding to the Government's call, the Prime Minister on the
                activities of sharing difficulties, supporting localities,
                organizations, businesses and people in epidemic prevention and
                control Covid-19. With the strength of a large-scale
                corporation, having a fleet Sea transportation, seaports and
                logistics systems stretching over nationwide, Vietnam Maritime
                Corporation (VIMC) will provide providing free shipping services
                to support room work, fight against Covid-19.
                <br />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </swiper-slide>
    </swiper-slide>

    <div
      class="swiper-pagination"
      slot="pagination"
    ></div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
  name: 'swiper-example-loop',
  title: 'Loop mode / Infinite loop',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        centeredSlides: true,
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        speed: 800,
        grabCursor: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.swiper {
  height: 360px;

  .swiper-slide {
    background-position: center !important;
    background-size: cover !important;

    &.slide-1 {
      background: linear-gradient(
          58.82deg,
          #000000 0.17%,
          rgba(0, 38, 96, 0.58) 58.33%,
          rgba(196, 196, 196, 0) 105.92%
        ),
        url('/assets/img/sea_1440.jpg');
    }
    &.slide-2 {
      background: linear-gradient(
          58.82deg,
          #000000 0.17%,
          rgba(0, 38, 96, 0.58) 58.33%,
          rgba(196, 196, 196, 0) 105.92%
        ),
        url('/assets/img/44.jpg');
    }
  }
}
</style>
