import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import billLadingApi from '@/api/vsl/bill-lading.api';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Ho_Chi_Minh');

const initialState = () => ({
  myBillLadingList: [],
  myBillLadingListQueryParams: {
    search: null,
    page: null,
    size: null,
  },
  myBillLadingListTotal: 0,
  myBillLadingListTotalPage: 0,
  myBillLadingDetail: {
    id: null,
    no: null,
    searchingType: null,
    availableFiles: [],
  },
});

const state = initialState();
const getters = {};
const mutations = {
  RESET(state, payload) {
    const initStates = initialState();
    if (!payload) {
      Object.keys(initStates).map((key) => {
        state[key] = initStates[key];
      });
    } else {
      const statesToReset = payload;
      statesToReset.map((key) => {
        state[key] = initStates[key];
      });
    }
  },
  SET_MY_BILL_LADING_LIST(state, payload) {
    state.myBillLadingList = payload;
  },
  SET_MY_BILL_LADING_LIST_QUERY_PARAMS(state, payload) {
    state.myBillLadingListQueryParams = {
      ...state.myBillLadingListQueryParams,
      ...payload,
    };
  },
  SET_MY_BILL_LADING_LIST_TOTAL(state, payload) {
    state.myBillLadingListTotal = payload;
  },
  SET_MY_BILL_LADING_LIST_TOTAL_PAGE(state, payload) {
    state.myBillLadingListTotalPage = payload;
  },
};
const actions = {
  async fetchMyBillLadingList({ state, commit }, payload) {
    const res =
      await billLadingApi.fetchMyBillLadingList({
        search: state.myBillLadingListQueryParams.search,
        page: state.myBillLadingListQueryParams.page,
        size: state.myBillLadingListQueryParams.size,
      });
    const mapped = res.data.data.map((item) => {
      const mappedItem = {
        id: item.id,
        no: item.no,
        bookingNo: item.booking_no,
        issueDate: item.issue_date && dayjs.tz(item.issue_date).toDate(),
        shipperCode: item.shipper_code,
        consigneeCode: item.consignee_code,

      };
      return mappedItem;
    });
    const size = state.myBillLadingListQueryParams.size || 10;
    const page = res.data.total === 0 ? 1 : Math.ceil(res.data.total / size);
    commit('SET_MY_BILL_LADING_LIST', mapped);
    commit('SET_MY_BILL_LADING_LIST_TOTAL', res.data.total);
    commit('SET_MY_BILL_LADING_LIST_TOTAL_PAGE', page);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
