<template>
  <v-card flat>
    <v-card-text
      :class="{
        'ma-0 pa-0': !$vuetify.breakpoint.mobile,
      }"
    >
      <v-row
        no-gutters
        :class="{
          'flex-column': $vuetify.breakpoint.mobile,
        }"
      >
        <v-col
          :cols="$vuetify.breakpoint.mobile ? 12 : 3"
          class="d-flex flex-column align-center mt-4"
        >
          <v-avatar size="196">
            <img :src="avatarLink" />
          </v-avatar>
          <div
            class="mb-2 mt-1"
            v-if="username"
          >
            {{ `@${username}` }}
          </div>
          <FilePicker
            accept="image/*"
            text
            :button-text="this.$t('button-change-avatar')"
            :icon="null"
            width="100%"
            :multiple="false"
            @change="onChangeAvatar"
          />
        </v-col>
        <v-col
          :cols="$vuetify.breakpoint.mobile ? 12 : 9"
          :class="{
            'pl-8': !$vuetify.breakpoint.mobile,
          }"
        >
          <v-form
            @submit="onSignup()"
            onSubmit="return false;"
          >
            <p class="overline">{{ $t('title-account-info') }}</p>
            <div>
              <div class="caption">{{ $t('name') }}</div>
              <v-text-field
                v-model="$v.name.$model"
                :label="$t('label-name')"
                prepend-inner-icon="mdi-account"
                type="text"
                solo
                outlined
                dense
                :disabled="isLoading"
                :error-messages="nameErrors"
                :counter="100"
                @input="$v.name.$touch()"
                @blur="$v.name.$touch()"
              />
            </div>
            <div>
              <div class="caption">{{ $t('tax-no') }}</div>
              <v-text-field
                v-model.trim="$v.taxNo.$model"
                :label="$t('label-tax-no')"
                prepend-inner-icon="mdi-briefcase"
                type="text"
                solo
                outlined
                dense
                :counter="25"
              />
            </div>
            <div>
              <div class="caption">{{ $t('company-registration-no') }}</div>
              <v-text-field
                v-model.trim="$v.companyRegistrationNo.$model"
                :label="$t('label-company-registration-no')"
                prepend-inner-icon="mdi-file-sign"
                type="text"
                solo
                outlined
                dense
                :counter="25"
              />
            </div>
            <div>
              <div class="caption">Website</div>
              <v-text-field
                v-model.trim="$v.website.$model"
                :label="$t('label-website')"
                prepend-inner-icon="mdi-web"
                type="text"
                solo
                outlined
                dense
                :counter="100"
                :error-messages="websiteErrors"
                @input="$v.website.$touch()"
                @blur="$v.website.$touch()"
              />
            </div>
            <v-row
              no-gutters
              class="mt-4"
            >
              <v-spacer></v-spacer>
              <!-- <v-btn class="mr-2 px-8" text @click="closeDialog">{{
                $t('button-discard-change')
              }}</v-btn> -->
              <v-btn
                class="px-8"
                :class="{
                  'w-100': $vuetify.breakpoint.mobile,
                }"
                color="primary"
                :loading="isLoading"
                @click="onSaveAccountInfo"
                >{{ $t('button-save-change') }}</v-btn
              >
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  sameAs,
  email,
  url,
  helpers,
} from 'vuelidate/lib/validators';
import { mapActions, mapMutations, mapState } from 'vuex';
import AddressLocationPicker from '../AddressLocationPicker.vue';
import FilePicker from '../FilePicker.vue';

const validWebiste = helpers.regex(
  'validWebiste',
  /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
);

export default {
  components: { FilePicker, AddressLocationPicker },
  data: () => ({
    isLoading: false,
    id: null,
    username: null,
    name: '',
    avatar: null,
    avatarLink: null,
    phone: '',
    address: '',
    email: '',
    taxNo: '',
    toUploadAvatarFormdata: null,

    website: '',
    companyRegistrationNo: '',
  }),
  validations: {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(100),
    },
    // address: {
    //   required,
    //   minLength: minLength(2),
    //   maxLength: maxLength(200),
    // },
    email: {
      required,
      email,
    },
    taxNo: {},
    website: { validWebiste },
    companyRegistrationNo: {},
  },
  computed: {
    ...mapState('user', ['myInfo']),
    ...mapState('upload', ['uploadedFileList']),
    nameErrors() {
      const errors = [];
      const field = this.$v.name;
      if (!field.$dirty) return errors;
      !field.minLength &&
        errors.push(this.$t('validation-error-name-min-length'));
      !field.maxLength &&
        errors.push(this.$t('validation-error-name-max-length'));
      !field.required && errors.push(this.$t('validation-error-name-required'));
      return errors;
    },
    emailErrors() {
      const errors = [];
      const field = this.$v.email;
      if (!field.$dirty) return errors;
      !field.email && errors.push(this.$t('validation-error-email-valid'));
      !field.required &&
        errors.push(this.$t('validation-error-email-required'));
      return errors;
    },
    websiteErrors() {
      const errors = [];
      const field = this.$v.website;
      if (!field.$dirty) return errors;
      !field.validWebiste &&
        errors.push(this.$t('validation-error-website-valid'));
      return errors;
    },
  },
  created() {
    this.id = this.myInfo.id;
    this.username = this.myInfo.username;
    this.name = this.myInfo.name;
    this.avatar = this.myInfo.avatar;
    this.avatarLink = this.myInfo.avatarLink;
    this.phone = this.myInfo.phone;
    this.address = this.myInfo.address;
    this.taxNo = this.myInfo.taxNo;
    this.website = this.myInfo.website;
    this.companyRegistrationNo = this.myInfo.companyRegistrationNo;
  },
  methods: {
    ...mapMutations('upload', {
      resetUpload: 'RESET',
    }),
    ...mapActions('upload', ['uploadFile']),
    ...mapActions('user', ['getMyInfo', 'updateMyInfo']),
    ...mapMutations('ui', ['TOGGLE_DIALOG_USER_SETTINGS']),
    onChangeAvatar(event) {
      if (event.length > 0) {
        this.toUploadAvatarFormdata = event[0].formData;
        this.avatarLink = URL.createObjectURL(event[0].file);
      }
    },

    async onSaveAccountInfo() {
      try {
        this.isLoading = true;
        if (this.toUploadAvatarFormdata) {
          await this.uploadFile({
            formDataList: [this.toUploadAvatarFormdata],
          });
        }

        const form = {
          name: this.name,
          address: this.address,
          taxNo: this.taxNo,
          avatar:
            this.uploadedFileList.length > 0
              ? this.uploadedFileList[0].fileName
              : undefined,

          website: this.website,
          companyRegistrationNo: this.companyRegistrationNo,
        };
        await this.updateMyInfo(form);
        await this.getMyInfo();
        this.$message.success(this.$t('text-account-info-update-success'));
        // this.closeDialog();
      } finally {
        this.resetUpload();
        this.isLoading = false;
      }
    },
    closeDialog() {
      this.TOGGLE_DIALOG_USER_SETTINGS({
        status: false,
      });
    },
  },
};
</script>

<style></style>
