<template>
  <v-snackbar
    v-model="snackbarStatus"
    :timeout="snackbar.timeout"
    :color="snackbar.color"
    transition="scroll-y-reverse-transition"
    top
    rounded="lg"
  >
    <v-row>
      <v-col
        cols="11"
        class="d-flex align-center"
      >
        <span v-html="snackbar.message"></span>
      </v-col>
      <v-col
        cols="1"
        class="d-flex justify-end align-center"
      >
        <v-btn
          icon
          x-small
          @click="snackbarStatus = false"
        >
          <v-icon small> mdi-close </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  computed: {
    snackbar() {
      return this.$store.state.ui.snackbar;
    },
    snackbarStatus: {
      get() {
        return this.$store.state.ui.snackbar.status;
      },
      set(value) {
        return this.TOGGLE_SNACKBAR(value);
      },
    },
  },
  methods: {
    ...mapMutations('ui', ['TOGGLE_SNACKBAR']),
  },
};
</script>

<style></style>
