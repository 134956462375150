import PortApi from '@/api/vpl/port.api';

const initialState = () => ({
  portList: [],
});

const state = initialState();
const getters = {};
const mutations = {
  RESET(state, payload) {
    const initStates = initialState();
    if (!payload) {
      Object.keys(initStates).map((key) => {
        state[key] = initStates[key];
      });
    } else {
      const statesToReset = payload;
      statesToReset.map((key) => {
        state[key] = initStates[key];
      });
    }
  },
  SET_PORT_LIST(state, payload) {
    state.portList = payload;
  },
};
const actions = {
  async fetchPortList({ commit }, payload) {
    const res = await PortApi.fetchPortList();
    const mapped = res.data.data.map(({ code, name }) => ({
      code,
      name,
    }));
    commit('SET_PORT_LIST', mapped);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
