import { render, staticRenderFns } from "./Quote.vue?vue&type=template&id=59a8ee84&scoped=true&"
import script from "./Quote.vue?vue&type=script&lang=js&"
export * from "./Quote.vue?vue&type=script&lang=js&"
import style0 from "./Quote.vue?vue&type=style&index=0&id=59a8ee84&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59a8ee84",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("F:\\Git\\booking-fe\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('59a8ee84')) {
      api.createRecord('59a8ee84', component.options)
    } else {
      api.reload('59a8ee84', component.options)
    }
    module.hot.accept("./Quote.vue?vue&type=template&id=59a8ee84&scoped=true&", function () {
      api.rerender('59a8ee84', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Home/PageChild/Quote.vue"
export default component.exports