<template>
  <VesselScheduleSearchResultList />
</template>

<script>
import VesselScheduleSearchResultList from './VesselScheduleSearchResultList.vue';
export default {
  components: { VesselScheduleSearchResultList },
};
</script>

<style></style>
