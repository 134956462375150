<template>
  <div>
    <div
      id="carousel-2"
      class="carousel slide carousel-fade"
      data-ride="carousel"
      data-interval="6000"
    >
      <ol
        class="carousel-indicators"
        id="home"
      >
        <li
          data-target="#carousel-2"
          data-slide-to="0"
          class="active"
        ></li>
        <li
          data-target="#carousel-2"
          data-slide-to="1"
        ></li>
        <li
          data-target="#carousel-2"
          data-slide-to="2"
        ></li>
      </ol>
      <div
        class="carousel-inner"
        role="listbox"
      >
        <div class="carousel-item active">
          <img
            src="../../../libhome/img/anhnen1.jpg"
            alt="responsive image"
            class="d-block img-fluid"
          />

          <div
            class="carousel-caption justify-content-center align-items-center"
          >
            <div class="transx_content_slide_wrapper">
              <div class="transx_content_container">
                <div class="transx_content_wrapper_1">
                  <div
                    class="transx_up_heading_overlay transx_overlay_align_center"
                  >
                    VIMC&nbsp;LINES
                  </div>
                  <h2 class="transx_content_slider_title">
                    {{ this.$i18n.locale === 'en' ? 'Home' : 'Trang chủ' }}
                  </h2>
                </div>
              </div>
            </div>
            <div>
              <p></p>
              <!-- <span class="btn btn-sm btn-outline-secondary"></span> -->
            </div>
          </div>
        </div>
        <!-- /.carousel-item -->

        <div class="carousel-item">
          <img
            src="../../../libhome/img/anhnenhome.jpg"
            alt="responsive image"
            class="d-block img-fluid"
          />

          <div
            class="carousel-caption justify-content-center align-items-center"
          >
            <div class="transx_content_slide_wrapper">
              <div class="transx_content_container">
                <div class="transx_content_wrapper_1">
                  <div
                    class="transx_up_heading_overlay transx_overlay_align_center"
                  >
                    VIMC&nbsp;LINES
                  </div>
                  <h2 class="transx_content_slider_title">
                    {{ this.$i18n.locale === 'en' ? 'Home' : 'Trang chủ' }}
                  </h2>
                </div>
              </div>
            </div>
            <div>
              <p></p>
              <!-- <span class="btn btn-sm btn-outline-secondary"></span> -->
            </div>
          </div>
        </div>
        <!-- /.carousel-item -->
        <div class="carousel-item">
          <img
            src="../../../libhome/img/tòa nhà ocean park - trụ sở tổng công ty hàng hải việt nam(vimc).jpg"
            alt="responsive image"
            class="d-block img-fluid"
          />

          <div
            class="carousel-caption justify-content-center align-items-center"
          >
            <div class="transx_content_slide_wrapper">
              <div class="transx_content_container">
                <div class="transx_content_wrapper_1">
                  <div
                    class="transx_up_heading_overlay transx_overlay_align_center"
                  >
                    VIMC&nbsp;LINES
                  </div>
                  <h2 class="transx_content_slider_title">
                    {{ this.$i18n.locale === 'en' ? 'Home' : 'Trang chủ' }}
                  </h2>
                </div>
              </div>
            </div>
            <div>
              <p></p>
              <!-- <span class="btn btn-sm btn-secondary"></span> -->
            </div>
          </div>
        </div>
        <!-- /.carousel-item -->
      </div>
      <!-- /.carousel-inner -->
      <!--      <a class="carousel-control-prev" href="#carousel-2" role="button" data-slide="prev">-->
      <!--        <span class="carousel-control-prev-icon" aria-hidden="true"></span>-->
      <!--        <span class="sr-only">Previous</span>-->
      <!--      </a>-->
      <!--      <a class="carousel-control-next" href="#carousel-2" role="button" data-slide="next">-->
      <!--        <span class="carousel-control-next-icon" aria-hidden="true"></span>-->
      <!--        <span class="sr-only">Next</span>-->
      <!--      </a>-->
    </div>

    <div class="container">
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-4a6ec8d5 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="4a6ec8d5"
        data-element_type="section"
      >
        <div class="row">
          <div
            class="col-4 elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-19c4fdd2"
            data-id="19c4fdd2"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="elementor-element elementor-element-4e86b3b8 elementor-widget elementor-widget-transx_heading"
                data-id="4e86b3b8"
                data-element_type="widget"
                data-widget_type="transx_heading.default"
              >
                <div class="elementor-widget-container">
                  <div class="transx_heading_widget-home">
                    <div
                      class="transx_up_heading"
                      id="services"
                      style="padding: 0 0 10px 0; margin: 0"
                    >
                      <span>{{ $t('services') }}</span>
                    </div>
                    <h2 class="transx_heading">
                      {{ $t('transportationServices') }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-8 elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-11762ac5"
            data-id="11762ac5"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="elementor-element elementor-element-bc7fb4b elementor-widget elementor-widget-text-editor"
                data-id="bc7fb4b"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div
                  class="elementor-widget-container"
                  style="padding: 0 110px"
                >
                  <p>
                    <strong>{{ $t('homeServicesTitlePara') }}</strong>
                  </p>
                  <p>
                    {{ $t('homeServicesContentPara') }}
                  </p>
                </div>
              </div>
              <div
                class="elementor-element elementor-element-8b3e402 elementor-widget elementor-widget-transx_button"
                data-id="8b3e402"
                data-element_type="widget"
                data-widget_type="transx_button.default"
              >
                <!-- <div class="elementor-widget-container">
                  <div class="transx_button_widget">
                    <div class="transx_button_container">
                      <div
                        class="transx_button_service transx_button_service--primary"
                        href="/"
                      >
                        <a href="/services">{{ $t('allServices') }}</a>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-4dad42f8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="4dad42f8"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-extended">
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="service-item">
                <div
                  class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-79384b5b"
                  data-id="79384b5b"
                  data-element_type="column"
                >
                  <div
                    class="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      class="elementor-element elementor-element-4eaaa3c elementor-widget elementor-widget-transx_promo_box"
                      data-id="4eaaa3c"
                      data-element_type="widget"
                      data-widget_type="transx_promo_box.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="transx_promo_box_widget">
                          <div class="transx_promo_box_item transx_view_type_1">
                            <div class="transx_promo_box_image">
                              <img
                                class="orange-icon"
                                src="../../../libhome/img/icon_new_2.png"
                                alt="Icon"
                                style="
                                  width: 200px;
                                  height: 168px;
                                  object-fit: contain;
                                  margin-left: 50px;
                                "
                              />
                            </div>
                            <div class="transx_promo_box_content">
                              <h6 class="transx_promo_box_title">
                                <span class="transx_promo_box_count">01</span>
                                <p class="title-service-count">
                                  {{ $t('lines') }}
                                </p>
                              </h6>
                              <p class="transx_promo_box_description">
                                {{ $t('logisticsService') }}&nbsp;&nbsp;&nbsp;<a
                                  href="/"
                                  target="_blank"
                                >
                                  <svg class="icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      id="next-arrow"
                                    >
                                      <path
                                        d="M22.707,12.707a1,1,0,0,0,0-1.414l-5-5a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-3.293,3.293a1,1,0,0,0,1.414,1.414Z"
                                      ></path>
                                    </svg>
                                  </svg>
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service-item">
                <div
                  class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-121bc60c"
                  data-id="121bc60c"
                  data-element_type="column"
                >
                  <div
                    class="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      class="elementor-element elementor-element-671c5cbb elementor-widget elementor-widget-transx_promo_box"
                      data-id="671c5cbb"
                      data-element_type="widget"
                      data-widget_type="transx_promo_box.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="transx_promo_box_widget">
                          <div class="transx_promo_box_item transx_view_type_1">
                            <div class="transx_promo_box_image">
                              <img
                                class="orange-icon"
                                src="../../../libhome/img/serviceimg3.svg"
                                alt="Icon"
                                style="
                                  width: 200px;
                                  height: 168px;
                                  object-fit: contain;
                                  margin-left: 50px;
                                "
                              />
                            </div>
                            <div class="transx_promo_box_content">
                              <h6 class="transx_promo_box_title">
                                <span class="transx_promo_box_count">02</span>
                                <p class="title-service-count">
                                  {{
                                    this.$i18n.locale == 'en'
                                      ? 'Terminal'
                                      : 'Terminal'
                                  }}
                                </p>
                              </h6>
                              <p class="transx_promo_box_description">
                                {{
                                  $t('cargoHandlingTerminal')
                                }}&nbsp;&nbsp;&nbsp;<a
                                  href="https://www.vimcdinhvu.com.vn/"
                                  target="_blank"
                                >
                                  <svg class="icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      id="next-arrow"
                                    >
                                      <path
                                        d="M22.707,12.707a1,1,0,0,0,0-1.414l-5-5a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-3.293,3.293a1,1,0,0,0,1.414,1.414Z"
                                      ></path>
                                    </svg>
                                  </svg>
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service-item">
                <div
                  class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-4f3022a"
                  data-id="4f3022a"
                  data-element_type="column"
                >
                  <div
                    class="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      class="elementor-element elementor-element-26fea086 elementor-widget elementor-widget-transx_promo_box"
                      data-id="26fea086"
                      data-element_type="widget"
                      data-widget_type="transx_promo_box.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="transx_promo_box_widget">
                          <div class="transx_promo_box_item transx_view_type_1">
                            <div class="transx_promo_box_image">
                              <img
                                class="orange-icon"
                                src="../../../libhome/img/terminal1.png"
                                alt="Icon"
                                style="
                                  width: 200px;
                                  height: 168px;
                                  object-fit: contain;
                                  margin-left: 50px;
                                "
                              />
                            </div>
                            <div class="transx_promo_box_content">
                              <h6 class="transx_promo_box_title">
                                <span class="transx_promo_box_count">03</span>
                                <p class="title-service-count">
                                  {{ $t('depot') }}
                                </p>
                              </h6>
                              <p class="transx_promo_box_description">
                                {{ $t('warehouse') }}&nbsp;&nbsp;&nbsp;<a
                                  href="https://vimadeco.com.vn/"
                                  target="_blank"
                                >
                                  <svg class="icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      id="next-arrow"
                                    >
                                      <path
                                        d="M22.707,12.707a1,1,0,0,0,0-1.414l-5-5a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-3.293,3.293a1,1,0,0,0,1.414,1.414Z"
                                      ></path>
                                    </svg>
                                  </svg>
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="about-new">
      <div class="container-nav">
        <div
          class="row about-content-new transx_sidebar_none transx_bg_color_default"
        >
          <div class="col-lg-12 col-xl-12">
            <div class="transx_content_wrapper">
              <div
                data-elementor-type="wp-page"
                data-elementor-id="42"
                class="elementor elementor-42"
                data-elementor-settings="[]"
              >
                <div class="elementor-section-wrap">
                  <section
                    class="elementor-section elementor-top-section elementor-element elementor-element-223bd736 elementor-hidden-tablet elementor-hidden-phone elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="223bd736"
                    data-element_type="section"
                  >
                    <v-row class="d-sm-column">
                      <v-col
                        :cols="$vuetify.breakpoint.mobile ? 12 : 5"
                        class="col-12 col-md-5 elementor-column-img-about elementor-col-33 elementor-top-column elementor-element elementor-element-7b9387f9"
                        data-id="7b9387f9"
                        data-element_type="column"
                      >
                      </v-col>
                      <v-col
                        :cols="$vuetify.breakpoint.mobile ? 12 : 7"
                        class="col-12 col-md-7 elementor-column-content-about elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-5075a951"
                        data-id="5075a951"
                        data-element_type="column"
                        style="padding: 15px 105px 15px 35px"
                      >
                        <div class="about-new-background-overlay"></div>
                        <div
                          class="elementor-widget-wrap elementor-element-populated"
                        >
                          <div
                            class="elementor-element elementor-element-72dc12f8 elementor-widget elementor-widget-transx_heading"
                            data-id="72dc12f8"
                            data-element_type="widget"
                            data-widget_type="transx_heading.default"
                          >
                            <div class="mt-12">
                              <div
                                class="about-new-section-header text-left"
                                style="left: 0"
                                id="about-us"
                              >
                                <p>{{ $t('aboutUs') }}</p>
                                <h2>
                                  {{ $t('aboutUsTitlePara') }}
                                </h2>
                              </div>
                              <div class="about-content-text-new">
                                <p style="text-align: justify">
                                  {{ $t('aboutUsContentPara') }}
                                </p>
                                <ul>
                                  <li>
                                    <!-- <i class="far fa-check-circle"></i> -->
                                    {{ $t('aboutNameVn') }}
                                  </li>
                                  <li>
                                    <!-- <i class="far fa-check-circle"></i> -->
                                    {{ $t('aboutNameEn') }}
                                  </li>
                                  <li>
                                    <!-- <i class="far fa-check-circle"></i> -->
                                    {{ $t('aboutNameInt') }}
                                  </li>
                                  <li>
                                    <!-- <i class="far fa-check-circle"></i> -->
                                    {{ $t('aboutAddress') }}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </section>
                  <section
                    class="elementor-top-section elementor-element elementor-element-3903e669 elementor-hidden-desktop elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="3903e669"
                    data-element_type="section"
                  ></section>
                </div>
              </div>
            </div>
            <div class="transx_content_paging_wrapper"></div>
          </div>
        </div>
      </div>
      <div class="content__card">
        <strong class="content__card--ques">{{ $t('helper') }}</strong>
        <div class="card-contact">
          <div class="card-contact--link">
            <a href="/contact">
              <span>{{ $t('contactUs') }}</span>
              <div class="icon">
                <svg
                  class="arrow"
                  viewBox="0 0 150 78.6"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 31.5h150v12.7H0V31.5zM112.8-.1l30.9 31.5-8.8 9L104 8.9l8.8-9zm18.1 51l-18.4 18.8 8.9 9 18.4-18.8-8.9-9z"
                  ></path>
                </svg>
              </div>
            </a>
          </div>
          <img
            src="../../../libhome/img/email_img.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <!--Quote--->
    <div>
      <div class="main_quote">
        <div class="main_quote__content">
          <div class="content">
            <div class="content__details">
              <p
                class=""
                id="quote"
                style="
                  display: inline-block;
                  text-align: center;
                  margin-bottom: 10px;
                  padding-bottom: 5px;
                  position: relative;
                  font-size: 16px;
                  font-weight: 700;
                  letter-spacing: 4px;
                  text-transform: uppercase;
                  color: #fff;
                  padding-top: 15px;
                  width: 105px;
                  border-bottom: 2px solid #fff;
                  font-family: Montserrat, sans-serif;
                "
              >
                {{ $t('quote') }}
              </p>
              <div clquoteass="content__details--title">
                <h2
                  style="
                    color: rgba(0, 0, 0, 0.85);
                    font-size: 45px;
                    font-weight: 700;
                    font-family: Montserrat, sans-serif;
                  "
                >
                  {{ $t('quoteHomeTitleContaier') }}
                </h2>
              </div>
              <p
                class="content__details--des"
                style="
                  color: #000;
                  font-size: 16px;
                  font-weight: 500;
                  text-align: justify;
                  height: 180px;
                  font-family: Montserrat, sans-serif;
                "
              >
                {{ $t('quoteHomePara') }}
              </p>
            </div>
          </div>
          <!--        form-->
        </div>
      </div>
    </div>
    <!-- Service Start -->
    <!-- <div class="service">
      <div
        class="section-header text-center"
        id="all-services"
      >
        <p>{{ $t('allServicesCaptital') }}</p>
        <h2>{{ $t('trustService') }}</h2>
      </div>
      <div
        class=""
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 110px;
        "
      >
        <div
          class=""
          style="display: flex; gap: 50px"
        >
          <div
            class=""
            style="width: 100%; height: auto"
          >
            <div class="service-item">
              <i class="flaticon-car-wash-1"></i>
              <h3>{{ $t('fastDelivery') }}</h3>
              <p style="text-align: justify">
                {{ $t('fastDeliPara') }}
              </p>
            </div>
          </div>
          <div
            class=""
            style="width: 100%; height: auto"
          >
            <div class="service-item">
              <i class="flaticon-car-wash"></i>
              <h3>{{ $t('highReliability') }}</h3>
              <p style="text-align: justify">
                {{ $t('highReliPara') }}
              </p>
            </div>
          </div>
          <div
            class=""
            style="width: 100%; height: auto"
          >
            <div class="service-item">
              <i class="flaticon-vacuum-cleaner"></i>
              <h3>{{ $t('greatOffer') }}</h3>
              <p style="text-align: justify">
                {{ $t('greatOfferPara') }}
              </p>
            </div>
          </div>
          <div
            class=""
            style="width: 100%; height: auto"
          >
            <div class="service-item">
              <i class="flaticon-seat"></i>
              <h3>{{ $t('support24') }}</h3>
              <p style="text-align: justify">
                {{ $t('suportPara') }}
              </p>
            </div>
          </div>
          <div
            class=""
            style="width: 100%; height: auto"
          >
            <div class="service-item">
              <i class="flaticon-car-service"></i>
              <h3>{{ $t('bestService') }}</h3>
              <p style="text-align: justify">
                {{ $t('bestServicePara') }}
              </p>
            </div>
          </div> -->
          <!--  <div class="" style="">
            <div class="service-item">
              <i class="flaticon-car-service-2"></i>
              <h3>Project Cargo</h3>
              <p>
                Our aim is to optimize and improve your supply chain so that we
                can give you the best service.
              </p>
            </div>
          </div>
          <div class="col-lg-2 col-md-6">
            <div class="service-item">
              <i class="flaticon-car-wash"></i>
              <h3>Oil Changing</h3>
              <p>
                Our aim is to optimize and improve your supply chain so that we
                can give you the best service.
              </p>
            </div>
          </div>
           <div class="col-lg-3 col-md-6">
            <div class="service-item">
              <i class="flaticon-brush-1"></i>
              <h3>Brake Reparing</h3>
              <p>
                Our aim is to optimize and improve your supply chain so that we
                can give you the best service.
              </p>
            </div>
          </div> -->
        <!-- </div>
      </div>
    </div> -->
    <!-- Service End -->

    <!-- Facts Start -->
    <!-- <div
      class="facts"
      data-parallax="scroll"
      data-image-src="img/facts.jpg"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div class="facts-item">
              <i class="fa fa-map-marker-alt"></i>
              <div class="facts-text">
                <h3 data-toggle="counter-up">25</h3>
                <p>Service Points</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="facts-item">
              <i class="fa fa-user"></i>
              <div class="facts-text">
                <h3 data-toggle="counter-up">350</h3>
                <p>Engineers & Workers</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="facts-item">
              <i class="fa fa-users"></i>
              <div class="facts-text">
                <h3 data-toggle="counter-up">1500</h3>
                <p>Happy Clients</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="facts-item">
              <i class="fa fa-check"></i>
              <div class="facts-text">
                <h3 data-toggle="counter-up">5000</h3>
                <p>Projects Completed</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div
      class=""
      style="
        text-align: center;
        width: 100vw;
        padding: 15px 200px;
        background-color: #f0f0f0;
      "
    >
      <div class="elementor-widget-wrap elementor-element-populated">
        <div
          class="elementor-element elementor-element-3e10245d elementor-widget elementor-widget-transx_heading"
          data-id="3e10245d"
          data-element_type="widget"
          data-widget_type="transx_heading.default"
        >
          <div class="elementor-widget-container">
            <div class="transx_heading_widget">
              <div
                class="transx_up_heading"
                id="achivement"
              >
                <p>{{ $t('achivement') }}</p>
              </div>
              <h2 class="transx_heading">{{ $t('highWorkAchievements') }}</h2>
            </div>
          </div>
        </div>
        <section
          class="elementor-section elementor-inner-section elementor-element elementor-element-18a82c3a elementor-hidden-phone elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="18a82c3a"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-no">
            <v-row>
              <v-col class="col-12">
                <v-row>
                  <v-col class="col-4 col-left">
                    <div
                      class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-21e7f9a4"
                      data-id="21e7f9a4"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-53b415f5 transx_counter_align_left elementor-widget elementor-widget-counter"
                          data-id="53b415f5"
                          data-element_type="widget"
                          data-widget_type="counter.default"
                        >
                          <div
                            class="elementor-widget-container"
                            style="
                              display: flex;
                              align-items: center;
                              justify-content: center;
                            "
                          >
                            <div class="elementor-counter">
                              <div class="elementor-counter-number-wrapper">
                                <span
                                  class="elementor-counter-number-prefix"
                                ></span>
                                <span
                                  class="elementor-counter-number"
                                  data-duration="2000"
                                  data-to-value="1.2"
                                  data-from-value="0"
                                  data-delimiter=","
                                  style="color: #e8734e"
                                >
                                  <span
                                    style="
                                      font-size: 60px;
                                      display: flex;
                                      align-items: end;
                                      gap: 15px;
                                      padding-bottom: 10px;
                                      white-space: nowrap;
                                      overflow: hidden;
                                    "
                                  >
                                    <span style="font-size: 60px">1.015</span>
                                    {{ $t('billion') }}
                                  </span></span
                                >
                              </div>
                              <div class="elementor-counter-title">
                                {{ $t('charterCap') }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col class="col-4 col-center">
                    <div
                      class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6725ae06"
                      data-id="6725ae06"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-41151222 transx_counter_align_left transx_figure_corner_no elementor-widget elementor-widget-counter"
                          data-id="41151222"
                          data-element_type="widget"
                          data-widget_type="counter.default"
                        >
                          <div
                            class="elementor-widget-container"
                            style="
                              display: flex;
                              align-items: center;
                              justify-content: center;
                            "
                          >
                            <div class="elementor-counter">
                              <div class="elementor-counter-number-wrapper">
                                <span
                                  class="elementor-counter-number-prefix"
                                ></span>
                                <span
                                  class="elementor-counter-number"
                                  data-duration="2000"
                                  data-to-value="1.2"
                                  data-from-value="0"
                                  data-delimiter=","
                                  style="color: #e8734e"
                                >
                                  <span
                                    style="
                                      font-size: 60px;
                                      display: flex;
                                      align-items: end;
                                      gap: 50px;
                                      white-space: nowrap;
                                      overflow: hidden;
                                      position: relative;
                                      padding-bottom: 10px;
                                    "
                                  >
                                    <span
                                      style="
                                        font-size: 60px;
                                        position: relative;
                                      "
                                    >
                                      30
                                      <span
                                        style="
                                          font-size: 60px;
                                          position: absolute;
                                          transform: translateY(-20%);
                                        "
                                      >
                                        +
                                      </span>
                                    </span>
                                    {{ $t('partner') }}
                                  </span>
                                </span>
                              </div>
                              <div class="elementor-counter-title">
                                {{ $t('DomesAndInternational') }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col class="col-4 col-right">
                    <div
                      class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-3bce258a"
                      data-id="3bce258a"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-71249b1c transx_counter_align_left transx_figure_corner_no elementor-widget elementor-widget-counter"
                          data-id="71249b1c"
                          data-element_type="widget"
                          data-widget_type="counter.default"
                        >
                          <div
                            class="elementor-widget-container"
                            style="
                              display: flex;
                              align-items: center;
                              justify-content: center;
                            "
                          >
                            <div class="elementor-counter">
                              <div class="elementor-counter-number-wrapper">
                                <span
                                  class="elementor-counter-number-prefix"
                                ></span>
                                <span
                                  class="elementor-counter-number"
                                  data-duration="2000"
                                  data-to-value="1.2"
                                  data-from-value="0"
                                  data-delimiter=","
                                  style="color: #e8734e"
                                >
                                  <span
                                    style="
                                      font-size: 60px;
                                      display: flex;
                                      align-items: end;
                                      gap: 50px;
                                      white-space: nowrap;
                                      overflow: hidden;
                                      position: relative;
                                      padding-bottom: 10px;
                                    "
                                  >
                                    <span
                                      style="
                                        font-size: 60px;
                                        position: relative;
                                      "
                                    >
                                      300
                                      <span
                                        style="
                                          font-size: 60px;
                                          position: absolute;
                                          transform: translateY(-20%);
                                        "
                                      >
                                        +
                                      </span>
                                    </span>
                                    {{ $t('customers') }}
                                  </span>
                                </span>
                              </div>
                              <div class="elementor-counter-title">
                                {{ $t('DomesAndInternational') }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </section>
      </div>
    </div> -->
    <!-- Facts End -->

    <!-- Price Start -->
    <!-- <div class="price">
      <div class="container">
        <div
          class="section-header text-center"
          id="pricing"
        >
          <p>Pricing</p>
          <h2>The right price for you business</h2>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="price-item">
              <div class="price-header">
                <h3>Starter Pack</h3>
                <h2><span>$</span><strong>25</strong><span>.99</span></h2>
              </div>
              <div class="price-body">
                <ul>
                  <li><i class="far fa-check-circle"></i>1 warehouse</li>
                  <li>
                    <i class="far fa-check-circle"></i>Custom business rules
                  </li>
                  <li>
                    <i class="far fa-check-circle"></i>Real-time rate shopping
                  </li>
                  <li>
                    <i class="far fa-times-circle"></i>100 freight shipments
                  </li>
                </ul>
              </div>
              <div
                class="price-footer"
                style="display: flex; justify-content: center"
              >
                <div class="btn-basic">
                  <input
                    type="button"
                    value="Book Now"
                    href=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="price-item featured-item">
              <div class="price-header">
                <h3>Regular Pack</h3>
                <h2><span>$</span><strong>35</strong><span>.99</span></h2>
              </div>
              <div class="price-body">
                <ul>
                  <li><i class="far fa-check-circle"></i>1 warehouse</li>
                  <li>
                    <i class="far fa-check-circle"></i>Custom business rules
                  </li>
                  <li>
                    <i class="far fa-check-circle"></i>Real-time rate shopping
                  </li>
                  <li>
                    <i class="far fa-times-circle"></i>100 freight shipments
                  </li>
                </ul>
              </div>
              <div
                class="price-footer"
                style="display: flex; justify-content: center"
              >
                <div class="btn-basic">
                  <input
                    type="button"
                    value="Book Now"
                    href=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="price-item">
              <div class="price-header">
                <h3>Premium Pack</h3>
                <h2><span>$</span><strong>49</strong><span>.99</span></h2>
              </div>
              <div class="price-body">
                <ul>
                  <li><i class="far fa-check-circle"></i>1 warehouse</li>
                  <li>
                    <i class="far fa-check-circle"></i>Custom business rules
                  </li>
                  <li>
                    <i class="far fa-check-circle"></i>Real-time rate shopping
                  </li>
                  <li>
                    <i class="far fa-times-circle"></i>100 freight shipments
                  </li>
                </ul>
              </div>
              <div
                class="price-footer"
                style="display: flex; justify-content: center"
              >
                <div class="btn-basic">
                  <input
                    type="button"
                    value="Book Now"
                    href=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- Price End -->
    <!-- <div
      class="timeline-1"
      style="background-color: #ffffff"
    >
      <div class="container container-time">
        <div class="transx_timeline_heading_container">
          <div
            class="transx_up_heading"
            id="history"
          >
            <p>{{ $t('history') }}</p>
          </div>
          <h2 class="transx_heading_history">{{ $t('historyTitle') }}</h2>
        </div>
      </div>
      <div class="container1">
        <swiper
          :options="swiperOptions"
          ref="mySwiper"
        >
          <swiper-slide
            v-for="(item, index) in loopedItems"
            :key="index"
          >
            <div class="timeline-wrapper">
              <div class="middle-line"></div>
              <div class="box box-bottom">
                <div class="box-content-top2">
                  <p class="transx_timeline_date">
                    {{ item.year }}
                    <span style="font-size: 22px">year</span>
                  </p>
                </div>
                <div class="date"></div>
                <div class="box-content">
                  <p>{{ item.content }}</p>
                </div>
              </div>
            </div>
          </swiper-slide> -->
          <!-- Add Pagination if needed -->
          <!-- <div
            class="swiper-pagination"
            slot="pagination"
          ></div> -->
          <!-- Add Navigation Arrows -->
        <!-- </swiper>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div> -->

    <div class="team">
      <!-- <div class="container container-team">
        <div
          class="section-header section-header-team text-center"
          id="our-team"
        >
          <p>{{ $t('meetOurTeam') }}</p>
          <h2>{{ $t('ourEngineer') }}</h2>
        </div>
        <div
          class=""
          style="display: flex; justify-content: center"
        >
          <div class="">
            <div class="team-item">
              <div class="team-img">
                <img
                  src="../../../libhome/img/team-1.jpg"
                  alt="Team Image"
                />
              </div>
              <div class="team-text">
                <h2 :style="computedFontSize">{{ $t('CEO') }}</h2>
                <p>{{ $t('engineer') }}</p>
                <div class="team-social">
                  <a
                    class="btn"
                    href="mailto:vimclines@vimc-lines.com"
                    ><img
                      src="../../../libhome/img/gmail.svg"
                      alt=""
                      width="20px"
                  /></a>
                  <a
                    class="btn"
                    href="https://wa.me/84335299516"
                    target="_blank"
                    ><img
                      src="../../../libhome/img/whatsapp.svg"
                      alt=""
                      width="20px"
                  /></a>
                  <a
                    class="btn"
                    href="skype:suoinho_mk?chat"
                    target="_blank"
                    ><img
                      src="../../../libhome/img/skype.svg"
                      alt=""
                      width="20px"
                  /></a>
                  <a
                    class="btn"
                    href="https://zalo.me/0974040006"
                    target="_blank"
                    ><img
                      src="../../../libhome/img/zalo.svg"
                      alt=""
                      width="20px"
                  /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-3 col-md-6"
            style="margin-left: -22px; margin-right: 60px"
          >
            <div class="team-item">
              <div class="team-img">
                <img
                  src="../../../libhome/img/team-1.jpg"
                  alt="Team Image"
                />
              </div>
              <div class="team-text">
                <h2 :style="computedFontSize">{{ $t('CEO') }}</h2>
                <p>{{ $t('engineer') }}</p>
                <div class="team-social">
                  <a
                    class="btn"
                    href="mailto:vimclines@vimc-lines.com"
                    ><img
                      src="../../../libhome/img/gmail.svg"
                      alt=""
                      width="20px"
                  /></a>
                  <a
                    class="btn"
                    href="https://wa.me/84335299516"
                    target="_blank"
                    ><img
                      src="../../../libhome/img/whatsapp.svg"
                      alt=""
                      width="20px"
                  /></a>
                  <a
                    class="btn"
                    href="skype:suoinho_mk?chat"
                    target="_blank"
                    ><img
                      src="../../../libhome/img/skype.svg"
                      alt=""
                      width="20px"
                  /></a>
                  <a
                    class="btn"
                    href="https://zalo.me/0974040006"
                    target="_blank"
                    ><img
                      src="../../../libhome/img/zalo.svg"
                      alt=""
                      width="20px"
                  /></a>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-3 col-md-6"
            style="margin-left: -35px; margin-right: 56px"
          >
            <div class="team-item">
              <div class="team-img">
                <img
                  src="../../../libhome/img/team-2.jpg"
                  alt="Team Image"
                />
              </div>
              <div class="team-text">
                <h2 :style="computedFontSize">{{ $t('CFO') }}</h2>
                <p>{{ $t('engineer') }}</p>
                <div class="team-social">
                  <a
                    class="btn"
                    href="mailto:vimclines@vimc-lines.com"
                    ><img
                      src="../../../libhome/img/gmail.svg"
                      alt=""
                      width="20px"
                  /></a>
                  <a
                    class="btn"
                    href="https://wa.me/84335299516"
                    target="_blank"
                    ><img
                      src="../../../libhome/img/whatsapp.svg"
                      alt=""
                      width="20px"
                  /></a>
                  <a
                    class="btn"
                    href="skype:suoinho_mk?chat"
                    target="_blank"
                    ><img
                      src="../../../libhome/img/skype.svg"
                      alt=""
                      width="20px"
                  /></a>
                  <a
                    class="btn"
                    href="https://zalo.me/0974040006"
                    target="_blank"
                    ><img
                      src="../../../libhome/img/zalo.svg"
                      alt=""
                      width="20px"
                  /></a>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-3 col-md-6"
            style="margin-left: -35px; margin-right: 20px"
          >
            <div class="team-item">
              <div class="team-img">
                <img
                  src="../../../libhome/img/team-3.jpg"
                  alt="Team Image"
                />
              </div>
              <div class="team-text">
                <h2 :style="computedFontSize">
                  {{ $t('CHRO') }}
                </h2>
                <p>{{ $t('worker') }}</p>
                <div class="team-social">
                  <a
                    class="btn"
                    href="mailto:vimclines@vimc-lines.com"
                    ><img
                      src="../../../libhome/img/gmail.svg"
                      alt=""
                      width="20px"
                  /></a>
                  <a
                    class="btn"
                    href="https://wa.me/84335299516"
                    target="_blank"
                    ><img
                      src="../../../libhome/img/whatsapp.svg"
                      alt=""
                      width="20px"
                  /></a>
                  <a
                    class="btn"
                    href="skype:suoinho_mk?chat"
                    target="_blank"
                    ><img
                      src="../../../libhome/img/skype.svg"
                      alt=""
                      width="20px"
                  /></a>
                  <a
                    class="btn"
                    href="https://zalo.me/0974040006"
                    target="_blank"
                    ><img
                      src="../../../libhome/img/zalo.svg"
                      alt=""
                      width="20px"
                  /></a>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-3 col-md-6"
            style="margin-left: 5px; margin-right: -123px"
          >
            <div class="team-item">
              <div class="team-img">
                <img
                  src="../../../libhome/img/team-4.jpg"
                  alt="Team Image"
                />
              </div>
              <div class="team-text">
                <h2 :style="computedFontSize">{{ $t('CTO') }}</h2>
                <p>{{ $t('worker') }}</p>
                <div class="team-social">
                  <a
                    class="btn"
                    href="mailto:vimclines@vimc-lines.com"
                    ><img
                      src="../../../libhome/img/gmail.svg"
                      alt=""
                      width="20px"
                  /></a>
                  <a
                    class="btn"
                    href="https://wa.me/84335299516"
                    target="_blank"
                    ><img
                      src="../../../libhome/img/whatsapp.svg"
                      alt=""
                      width="20px"
                  /></a>
                  <a
                    class="btn"
                    href="skype:suoinho_mk?chat"
                    target="_blank"
                    ><img
                      src="../../../libhome/img/skype.svg"
                      alt=""
                      width="20px"
                  /></a>
                  <a
                    class="btn"
                    href="https://zalo.me/0974040006"
                    target="_blank"
                    ><img
                      src="../../../libhome/img/zalo.svg"
                      alt=""
                      width="20px"
                  /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- Location Start -->
    <!-- Location End -->
  </div>
</template>
<script>
import Swal from 'sweetalert2';
import { useToast } from 'vue-toastification';

export default {
  props: {
    active: {
      type: String,
    },
  },
  data() {
    return {
      name: '',
      email: '',
      message: '',
      isLoading: false,
      swiperOptions: {
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          320: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        },
      },
      timelineItems: [
        {
          year: 2024,
          content:
            'Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
        },
        {
          year: 2023,
          content:
            'Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
        },
        {
          year: 2022,
          content:
            'Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
        },
        {
          year: 2021,
          content:
            'Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
        },
        {
          year: 2020,
          content:
            'Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
        },
        {
          year: 2019,
          content:
            'Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
        },
        {
          year: 2018,
          content:
            'Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
        },
      ],
    };
  },

  computed: {
    computedFontSize() {
      return {
        fontSize: this.$i18n.locale === 'en' ? '20px' : '21px',
      };
    },
    loopedItems() {
      return [
        {
          year: 2024,
          content:
            'Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
        },
        {
          year: 2023,
          content:
            'Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
        },
        {
          year: 2022,
          content:
            'Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
        },
        {
          year: 2021,
          content:
            'Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
        },
        {
          year: 2020,
          content:
            'Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
        },
        {
          year: 2019,
          content:
            'Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
        },
        {
          year: 2018,
          content:
            'Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod Lorem2 ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
        },
      ];
    },
  },
  methods: {
    sendEmail() {
      this.isLoading = true; // Bắt đầu gửi email
      this.isButtonDisabled = true;

      const data = {
        from: this.email,
        name: this.name,
        text: this.message,
      };

      fetch('https://sendmail-tl91.onrender.com/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.text())
        .then((data) => {
          this.$toast.success(
            `${
              this.$i18n.locale === 'en'
                ? 'Email sent successfully'
                : 'Email đã được gửi'
            }`
          );
          // Clear form fields after successful submission
          this.name = '';
          this.email = '';
          this.message = '';
        })
        .catch((error) => {
          this.$toast.error(
            `${
              this.$i18n.locale === 'en'
                ? 'Error when sending email'
                : 'Có lỗi khi gửi email'
            }`
          );
        })
        .finally(() => {
          this.isLoading = false; // Kết thúc gửi email
        });
    },
  },
};
</script>

<style>
.col-xl-12 {
  margin-left: 12px;
}

.col-left {
  padding-right: 230px !important;
}

.col-center {
  padding-right: 55px !important;
}
.col-right {
  padding-left: 142px !important;
}

.col-md-6 {
  padding: 0 20px !important;
}

.col-lg-7 {
  padding: 12px 0 !important;
}

.elementor-counter-title {
  padding-left: 10px;
}

.section-header-call {
  margin: 0 !important;
}

.section-header-team {
  margin: 0 auto 45px auto !important;
}

.container-call,
.container-team {
  max-width: 1230px;
}

.container-time {
  padding: 10px 0 !important;
  margin-left: 350px;
}

.transx_promo_box_content {
  padding-left: 50px;
}

.transx_up_heading_overlay {
  hyphens: manual;
  -webkit-tap-highlight-color: transparent;
  text-align: center;
  box-sizing: border-box;
  line-height: 0.85;
  font-weight: 700;
  color: transparent;
  user-select: none;
  position: absolute;
  z-index: 1;
  display: initial;
  font-family: Inter, 'sans-serif';
  font-size: 200px;
  letter-spacing: -0.06em;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.7);
  top: -54px;
  left: 50%;
  transform: translateX(-50%);
}

.transx_heading_widget h2 {
  margin: 0;
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 40px;
  text-transform: capitalize;
  color: #000;
  font-family: 'Montserrat', sans-serif !important;
}

.transx_heading_widget-home {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  width: 400px;
  font-family: 'Inter', sans-serif;
}

.transx_heading_widget-home h2 {
  font-weight: 700;
  text-align: left;
  font-size: 45px;
  font-family: 'Inter', sans-serif;
}

.transx_heading_widget-home .transx_up_heading {
  font-weight: 700;
  text-align: left;
  font-size: 13px;
  font-family: 'Inter', sans-serif;
}

.transx_up_heading {
  font-size: 12px;
  line-height: 1.6;
  letter-spacing: 0.15em;
  font-weight: 700;
  text-transform: uppercase;
  color: #a9aeb3;
  position: relative;
  z-index: 5;
}

.transx_heading_history {
  margin: 0;
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: capitalize;
  color: #000;
  font-family: 'Montserrat', sans-serif !important;
}

.transx_content_slide_wrapper {
  text-align: left;
  letter-spacing: 0;
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 1.7;
  font-weight: 400;
  color: #696e76;
  hyphens: manual;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;
  justify-content: center;
}

.transx_content_slider_title {
  text-align: center;
  letter-spacing: -0.03em;
  word-wrap: break-word;
  margin: 0 0 0.5rem;
  font-family: Inter, sans-serif;
  font-weight: 700;
  text-transform: none;
  font-style: normal;
  box-sizing: border-box;
  color: #fff;
  margin-bottom: 20px;
  position: relative;
  z-index: 5;
  font-size: 60px;
  line-height: 60px;
}

.elementor-background-overlay {
  background-image: url('../../../libhome/img/contacts-map.png');
  background-position: 50% 24px;
  background-repeat: no-repeat;
  opacity: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.transx_icon_box_item.transx_view_type_3 .transx_icon_container {
  width: 60px;
  height: 60px;
  font-size: 18px;
  line-height: 55px;
  text-align: center;
  border-radius: 50%;
  margin-right: 19px;
  border: 1px solid #005bac;
}
</style>
