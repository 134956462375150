<template>
  <div class="">
    <p
      class="text-h3 mt-4"
      style="font-weight: 600"
    >
      Popular routes
    </p>
    <div class="mt-4">
      <p>
        <span class="info--text">Import</span> to <b>Vietnam</b>
        <flag
          iso="vn"
          class="mx-2"
        />
      </p>
      <v-row no-gutters>
        <v-col
          cols="6"
          class="pr-2"
        >
          <PopularRoutesItem
            class="mb-2"
            container="FCL"
            location="Bueno Aires"
            country="ar"
            price="1375"
            currency="USD"
          />
          <PopularRoutesItem
            class="mb-2"
            container="FCL"
            location="Kaohsiung"
            country="tw"
            price="608"
            currency="USD"
          />
        </v-col>
        <v-col
          cols="6"
          class="pl-2"
        >
          <PopularRoutesItem
            class="mb-2"
            container="FCL"
            location="Jebel Ali"
            country="ae"
            price="668"
            currency="USD"
          />
          <PopularRoutesItem
            class="mb-2"
            container="FCL"
            location="Los Angeles International Airport"
            country="us"
            price="1100"
            currency="USD"
          />
        </v-col>
      </v-row>
    </div>
    <div class="mt-4">
      <p>
        <span class="success--text">Export</span> from <b>Vietnam</b>
        <flag
          iso="vn"
          class="mx-2"
        />
      </p>
      <v-row no-gutters>
        <v-col
          cols="6"
          class="pr-2"
        >
          <PopularRoutesItem
            class="mb-2"
            container="FCL"
            location="Los Angeles"
            country="us"
            price="14315"
            currency="USD"
          />
          <PopularRoutesItem
            class="mb-2"
            container="FCL"
            location="Gemlik"
            country="tr"
            price="8184"
            currency="USD"
          />
        </v-col>
        <v-col
          cols="6"
          class="pl-2"
        >
          <PopularRoutesItem
            class="mb-2"
            container="FCL"
            location="Antwerpen"
            country="be"
            price="7306"
            currency="USD"
          />
          <PopularRoutesItem
            class="mb-2"
            container="FCL"
            location="Kuantan"
            country="my"
            price="561"
            currency="USD"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import PopularRoutesItem from './PopularRoutesItem.vue';
export default {
  components: { PopularRoutesItem },
};
</script>

<style scoped>
.flag-icon {
  width: 26px !important;
  line-height: 20px !important;
}
</style>
